export const brandList = [
  { label: "All Business Sectors", code: null },
  { label: "Cars", code: "ACT_360500" },
  {
    label: "Institutional/Public Interest/Non-Profit Org.",
    code: "ACT_720000",
  },
  { label: "Restaurants & Fast Food", code: "ACT_393000" },
  { label: "TV/Radio Programs & Stations", code: "ACT_451500" },
  { label: "Beers, Ciders, Lagers", code: "ACT_180520" },
  { label: "Charities, Foundations, Volunteers", code: "ACT_721500" },
  {
    label: "Banking & Financial Services, Investments, Stock Brokers",
    code: "ACT_511000",
  },
  { label: "Food", code: "ACT_120000" },
  { label: "Department Stores, Supermarkets", code: "ACT_390500" },
  { label: "Newspapers, Magazines, Books", code: "ACT_450500" },
  { label: "Others", code: "ACT_990000" },
  {
    label: "Airlines, Airports, Railways, Bus Lines, Ferries & Cruises",
    code: "ACT_480500",
  },
  { label: "Automotive", code: "ACT_360000" },
  { label: "Telecommunications Services", code: "ACT_336500" },
  { label: "Insurance", code: "ACT_512000" },
  {
    label: "Disease Awareness, Support Groups & Associations",
    code: "ACT_721520",
  },
  { label: "Apparel, Fashion & Footwear", code: "ACT_030000" },
  { label: "Soft Drinks, Tonics", code: "ACT_181515" },
  { label: "Media, Publishing & Production", code: "ACT_450000" },
  { label: "Drugstores, Specialty Stores", code: "ACT_391500" },
  { label: "Consumer Electronics & Audio-Visual", code: "ACT_240000" },
  { label: "Banking, Finance, Law & Insurance", code: "ACT_510000" },
  { label: "Public Safety, Health & Hygiene", code: "ACT_720500" },
  { label: "Health & Pharmaceutical Products", code: "ACT_100000" },
  { label: "Spirits, Fortified Wines", code: "ACT_180515" },
  { label: "Human Rights", code: "ACT_721510" },
  { label: "Environmental & Ecological Issues", code: "ACT_723030" },
  { label: "Culture, Leisure & Sport", code: "ACT_420000" },
  { label: "Personal Wireless Communication", code: "ACT_336510" },
  { label: "Mobile Telephones & tablets", code: "ACT_243000" },
  { label: "Retail, Distribution & Restaurants", code: "ACT_390000" },
  { label: "Sportswear", code: "ACT_031500" },
  { label: "Clothing", code: "ACT_030500" },
  { label: "Video Games/Consoles", code: "ACT_241500" },
  { label: "Cleaning Products, Soaps, Detergents", code: "ACT_300500" },
  { label: "Internet Sites (Excluding ISPs) / Apps.", code: "ACT_750000" },
  { label: "Destinations (Countries & Places)", code: "ACT_481200" },
  { label: "Transport, Travel and Tourism", code: "ACT_480000" },
  { label: "Alcoholic", code: "ACT_180500" },
  { label: "Road Safety", code: "ACT_720520" },
  { label: "Anti-Drug/Alcohol/Tobacco Message", code: "ACT_720510" },
  { label: "Home Appliances, Furniture & Gardening", code: "ACT_270000" },
  { label: "Advertising & Communication", code: "ACT_602500" },
  { label: "Hygiene & Personal Care Products", code: "ACT_090000" },
  { label: "Hotels, Resorts", code: "ACT_481500" },
  { label: "Lottery & Gambling", code: "ACT_423000" },
  { label: "Chocolate, Bars, Candies, Chewing-Gum", code: "ACT_152500" },
  { label: "Environmental  & Animal Issues", code: "ACT_723000" },
  { label: "Government & Other Authorities", code: "ACT_722000" },
  { label: "Sports Teams, Sports Events", code: "ACT_426500" },
  {
    label: "Coffee, Tea, Chocolate Drinks, Breakfast Drinks",
    code: "ACT_181505",
  },
  { label: "Business Services", code: "ACT_600000" },
  { label: "Racial/Ethnic/Minority/Disability Awareness", code: "ACT_721000" },
  {
    label: "Four Wheel Drive, SUV(Sports Utility Vehicle)",
    code: "ACT_360530",
  },
  { label: "Online Retail, E-store", code: "ACT_755500" },
  { label: "Cosmetics, Beauty Products & Perfumes", code: "ACT_060000" },
  { label: "Museums & Monuments", code: "ACT_420500" },
  { label: "Credit Cards", code: "ACT_511500" },
  { label: "Toys & Games", code: "ACT_422500" },
  { label: "Trade Shows, Events & Festivals", code: "ACT_423500" },
  {
    label: "Hospitals, Healthcare facilities & Medical Services",
    code: "ACT_335500",
  },
  { label: "Household Products & Maintenance", code: "ACT_300000" },
  { label: "Shoes", code: "ACT_031000" },
  { label: "Non-Alcoholic", code: "ACT_181500" },
  { label: "Advertising Agencies", code: "ACT_602505" },
  { label: "Sneakers / Athletic Footwear", code: "ACT_031025" },
  { label: "Corporate Communication", code: "ACT_603000" },
  { label: "Apparel & Accessories Stores", code: "ACT_391700" },
  { label: "Colleges, Universities & Education Centers", code: "ACT_335000" },
  { label: "Other Online Services", code: "ACT_757000" },
  { label: "Anti-Domestic Violence, Sexual Abuse", code: "ACT_720530" },
  { label: "Real Estate", code: "ACT_540000" },
  { label: "Consumer & Public Services", code: "ACT_330000" },
  { label: "Pet Food, Pet Care Products & Services", code: "ACT_302000" },
  {
    label: "TV, Hi-Fi, Video, Personal Stereo & Accessories",
    code: "ACT_240500",
  },
  { label: "Postal, Courier & Shipping Services", code: "ACT_334000" },
  { label: "Energy & Water Authorities, Utilities", code: "ACT_336000" },
  { label: "Animal Rights/Safety/Adoption", code: "ACT_723010" },
  { label: "Cinema Production", code: "ACT_451550" },
  { label: "Automotive Accessories & Services", code: "ACT_361500" },
  { label: "Compact & Small Cars", code: "ACT_360505" },
  { label: "Mineral, Sparkling & Bottled Waters", code: "ACT_181525" },
  { label: "Computers, PDAs & Calculators", code: "ACT_242000" },
  {
    label: "OTC Medicines (Pain Relievers, Cold & Flu, ...)",
    code: "ACT_101000",
  },
  { label: "IT Solutions & Professional Networks", code: "ACT_601500" },
  { label: "Records, CDs, DVDs & Videos", code: "ACT_451000" },
  { label: "Cameras & Video Cameras", code: "ACT_241000" },
  { label: "Leisure Parks, Zoos & Fairs", code: "ACT_424000" },
  { label: "Beverages", code: "ACT_180000" },
  { label: "Computer Software & Multimedia Productions", code: "ACT_452000" },
  { label: "Wines, Champagnes", code: "ACT_180525" },
  { label: "Leisure & Sport Equipment", code: "ACT_424500" },
  { label: "Chocolate", code: "ACT_152505" },
  { label: "Fruit Juices & Drinks", code: "ACT_181520" },
  { label: "Deodorants", code: "ACT_090515" },
  { label: "Internet Service Providers", code: "ACT_334500" },
  { label: "Tires, Accessories, Oil, Petrol, Gasoline", code: "ACT_361505" },
  { label: "Online Leisure &  Entertainment Services", code: "ACT_754500" },
  { label: "Biscuits, Bread, Crispbread", code: "ACT_126500" },
  { label: "Candies", code: "ACT_152515" },
  { label: "Jewelry", code: "ACT_032500" },
  { label: "Stationery & Office Supplies", code: "ACT_573000" },
  { label: "Potato Chips, Crisps & Crackers", code: "ACT_153505" },
  { label: "Breakfast Cereals", code: "ACT_155000" },
  { label: "Perfumes", code: "ACT_061000" },
  { label: "Milk & Milk Based", code: "ACT_181510" },
  {
    label: "Paints, Wood Protectors, Paint Brushes, Adhesives",
    code: "ACT_301000",
  },
  { label: "Public Transport, Mass Transit", code: "ACT_482500" },
  { label: "Lingerie, Underwear, Pajamas", code: "ACT_030525" },
  { label: "Industry", code: "ACT_630000" },
  { label: "Cigarettes & Rolling Tobacco", code: "ACT_210505" },
  { label: "Women's Press", code: "ACT_450510" },
  { label: "State Education", code: "ACT_722020" },
  { label: "Industrial Equipment & Machinery", code: "ACT_631700" },
  { label: "Children's Clothing", code: "ACT_030515" },
  { label: "Search Engines", code: "ACT_756500" },
  {
    label: "Creams, Lotions, Milks, Tonics, Makeup Removers",
    code: "ACT_091505",
  },
  { label: "Political/Religious/Trade Organizations", code: "ACT_722500" },
  { label: "Vitamins & Diet Complements", code: "ACT_100500" },
  { label: "Mayonnaise, Ketchup, Mustards", code: "ACT_125505" },
  { label: "Yogurt", code: "ACT_154505" },
  { label: "Fresh Meat, Sausages", code: "ACT_120505" },
  {
    label: "Four Wheel Drive, SUV(Sports Utility Vehicle)",
    code: "ACT_360530",
  },
  { label: "Online Recruitment Services", code: "ACT_752500" },
  { label: "IT & Electrical Components", code: "ACT_633000" },
  { label: "Shaving", code: "ACT_092000" },
  { label: "Cocktail Snacks", code: "ACT_153500" },
  {
    label: "Gardening Instruments & Products, Garden Furniture",
    code: "ACT_271500",
  },
  {
    label: "Batteries (Except Car Batteries), Light Bulbs",
    code: "ACT_302500",
  },
  { label: "Yellow Pages & Directories", code: "ACT_453000" },
  { label: "Car & Utility Rentals", code: "ACT_333000" },
  { label: "Online Gambling", code: "ACT_755000" },
  { label: "Construction & Civil Engineering", code: "ACT_631900" },
  { label: "Fresh Vegetables, Fruits", code: "ACT_120520" },
  { label: "Seasonings", code: "ACT_125500" },
  { label: "Toilet Paper, Tissues", code: "ACT_094500" },
  { label: "Car Dealers", code: "ACT_362000" },
  { label: "Sport Cars", code: "ACT_360525" },
  { label: "Preservation of Monuments, Parks & Wildlife", code: "ACT_723020" },
  { label: "Manufacturing", code: "ACT_632000" },
  { label: "Feminine Hygiene", code: "ACT_094000" },
  { label: "Packaging & Design", code: "ACT_602525" },
  { label: "Frozen food", code: "ACT_121000" },
  { label: "Medical Equipment", code: "ACT_103000" },
  { label: "Online Press, Media & Information", code: "ACT_754000" },
  { label: "Political Party/Candidate", code: "ACT_722520" },
  {
    label: "Accounting & Audit Companies, Organiz. Consultants",
    code: "ACT_510500",
  },
  { label: "Temp Agencies, Recruiting Companies", code: "ACT_602000" },
  { label: "Outdoor Sports & Athletics", code: "ACT_031510" },
  { label: "Coupes & Convertibles", code: "ACT_360535" },
  { label: "Online Auction", code: "ACT_751500" },
  { label: "Toothpaste", code: "ACT_093005" },
  { label: "Women's Clothing", code: "ACT_030505" },
  { label: "Fragrances, Perfumes & Toilet Waters", code: "ACT_061005" },
  { label: "Bath, Shower Gels", code: "ACT_090510" },
  { label: "Agriculture, Livestock, Fishing, Forest", code: "ACT_640000" },
  { label: "Jam, Honey, Peanut Butter, Sugar", code: "ACT_153000" },
  { label: "Office Equipment", code: "ACT_570000" },
  { label: "Diapers", code: "ACT_093515" },
  { label: "Razors, Blades", code: "ACT_092015" },
  { label: "Other Milk Desserts", code: "ACT_154515" },
  { label: "Eggs", code: "ACT_124520" },
  { label: "Acne Products", code: "ACT_091510" },
  { label: "Agrochemicals", code: "ACT_641500" },
  { label: "Women's Shoes", code: "ACT_031005" },
  { label: "Curtains, Linnens, Towels", code: "ACT_272500" },
  { label: "Frozen Fish & Seafood", code: "ACT_121015" },
  { label: "Edible Oils", code: "ACT_125005" },
  { label: "Eyes Hygiene", code: "ACT_092500" },
  { label: "Fresh Poultry", code: "ACT_120510" },
  { label: "Edible Fat Products", code: "ACT_125000" },
  { label: "Nuts & Dried Fruits", code: "ACT_153510" },
  { label: "Blank Tapes, Film, CDs, Disks, etc...", code: "ACT_242500" },
  { label: "Textile", code: "ACT_034500" },
  { label: "Canned Vegetables, Fruits", code: "ACT_121520" },
  { label: "Photocopiers", code: "ACT_573500" },
  { label: "Alternative Medicine & Homeopathy", code: "ACT_101500" },
  { label: "Tampons", code: "ACT_094005" },
  { label: "Toothbrushes", code: "ACT_093010" },
  { label: "Mouthwashes", code: "ACT_093015" },
  { label: "Agribusiness", code: "ACT_640500" },
  { label: "Perfume Product Lines", code: "ACT_061010" },
  { label: "Construction & Civil Engineering", code: "ACT_631900" },
  { label: "Jewelry", code: "ACT_032500" },
  { label: "Curtains, Linnens, Towels", code: "ACT_272500" },
  { label: "Frozen Fish & Seafood", code: "ACT_121015" },
  { label: "Edible Oils", code: "ACT_125005" },
  { label: "Eyes Hygiene", code: "ACT_092500" },
  { label: "Fresh Poultry", code: "ACT_120510" },
  { label: "Edible Fat Products", code: "ACT_125000" },
  { label: "Nuts & Dried Fruits", code: "ACT_153510" },
  { label: "Blank Tapes, Film, CDs, Disks, etc...", code: "ACT_242500" },
  { label: "Textile", code: "ACT_034500" },
  { label: "Canned Vegetables, Fruits", code: "ACT_121520" },
  { label: "Photocopiers", code: "ACT_573500" },
  { label: "Alternative Medicine & Homeopathy", code: "ACT_101500" },
  { label: "Tampons", code: "ACT_094005" },
  { label: "Toothbrushes", code: "ACT_093010" },
  { label: "Mouthwashes", code: "ACT_093015" },
  { label: "Agribusiness", code: "ACT_640500" },
  { label: "Perfume Product Lines", code: "ACT_061010" },
  { label: "Watches", code: "ACT_032000" },
  { label: "Spreadable Fats", code: "ACT_125015" },
  {
    label: "Motorhomes, Trailers & Recreational Vehicles (RV)",
    code: "ACT_361020",
  },
];
