import React, {useState, useEffect} from 'react';
import Masonry from 'react-masonry-css';
import '../css/moodboard-masonry.css';
import TileVimeo from './TileVimeo';
import TileVideo from './TileVideo';
import TileAudio from './TileAudio';
import TileYoutube from './TileYoutube';
import TileImage from './TileImage';

export default function MoodboardMasonry(props) {

    const renderTiles = () => {
        const moodboards = props.moodboards.sort((a, b) => a.order - b.order);
        return moodboards.map(moodboard => {
            if (moodboard.type.includes('image')) {
                return <TileImage key={moodboard._id} id={moodboard._id} src={moodboard.fileUrl} delete={props.delete}/>
            } else if (moodboard.type === 'video') {
                if (moodboard.fileUrl.includes('youtube'))
                    return <TileYoutube key={moodboard._id} id={moodboard._id} src={moodboard.fileUrl} delete={props.delete}/>
                else if (moodboard.fileUrl.includes('vimeo')) {
                    return <TileVimeo key={moodboard._id} id={moodboard._id} src={moodboard.fileUrl} delete={props.delete}/>
                } else if (moodboard.fileUrl.includes('filmboard.s3')) {
                    return <TileVideo key={moodboard._id} id={moodboard._id} src={moodboard.fileUrl} delete={props.delete}/>
                }
            } else if (moodboard.type === 'audio') {
                return <TileAudio key={moodboard._id} id={moodboard._id} src={moodboard.fileUrl} delete={props.delete}/>
            } else if (moodboard.type === 'pexels-video') {
                return <TileVideo key={moodboard._id} id={moodboard._id} src={moodboard.fileUrl} delete={props.delete}/>
            }
        });
    }


    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1
    };

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {renderTiles()}
        </Masonry>
    );
}