import { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import { Modal, Select, Button, notification } from "antd";

import projectService from "../../services/project";

import "./MoveToModal.scss";

const { Option } = Select;

const MoveToModal = forwardRef(({ onCopy, listAssets }, ref) => {
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [listBoard, setListBoard] = useState([]);
  const [listTab, setListTab] = useState([]);
  const [chosenProjectId, setChosenProjectId] = useState();
  const [chosenTabId, setChosenTabId] = useState();
  const [copying, setCopying] = useState(false);

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setShowMoveModal(false);
    },
    openModal: () => {
      setShowMoveModal(true);
    },
  }));

  useEffect(() => {
    if (showMoveModal) {
      projectService.getAll().then(({ data: allMoodboard }) => {
        setListBoard(allMoodboard);
      });
    }
  }, [showMoveModal]);

  const onChooseBoard = (boardId) => {
    setChosenProjectId(boardId);
    setChosenTabId(null);
    try {
      projectService.get(boardId).then(({ data: allTab }) => {
        setListTab(allTab[0].tabs);
      });
    } catch (err) {
      console.log("Error while get tab", err);
    }
  };

  const onCopying = () => {
    setCopying(true);
    try {
      onCopy(listAssets, chosenProjectId, chosenTabId);
      setShowMoveModal(false);
    } catch (err) {
      console.log("Error while copying", err);
    } finally {
      setCopying(false);
    }
  };

  const cleanUp = () => {
    setListTab([]);
    setChosenTabId(null);
  };

  return (
    <Modal
      title={""}
      open={showMoveModal}
      wrapClassName="move-modal-wrapper"
      mask={false}
      footer={null}
      onCancel={() => setShowMoveModal(false)}
      className="move-modal"
      destroyOnClose
      afterClose={cleanUp}
    >
      <div>Choose where to move asset(s) to</div>
      <Select placeholder="Select board" onChange={onChooseBoard}>
        {listBoard.map((boardOption) => (
          <Option key={boardOption._id} value={boardOption._id}>
            {boardOption.title?.length > 0 ? boardOption.title : "Untitled"}
          </Option>
        ))}
      </Select>
      {listTab.length > 0 && (
        <Select
          placeholder="Select tab"
          value={chosenTabId}
          onChange={(id) => setChosenTabId(id)}
        >
          {listTab.map((tabOption) => (
            <Option key={tabOption._id} value={tabOption._id}>
              {tabOption.title}
            </Option>
          ))}
        </Select>
      )}
      <Button
        type="primary"
        loading={copying}
        onClick={onCopying}
        className="confirm-btn"
      >
        Add asset(s)
      </Button>
    </Modal>
  );
});

export default MoveToModal;
