import React, { useState, useEffect, useCallback, useContext } from "react";
import "../css/moodboard-grid.css";
import TileVimeo from "./TileVimeo";
import TileVideo from "./TileVideo";
import TileAudio from "./TileAudio";
import TileYoutube from "./TileYoutube";
import TileImage from "./TileImage";
import TileWebsite from "./TileWebsite";

import {
  DndContext,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "./DraggableCard/SortableItem";
import axios from "axios";
import TileIspotPlayer from "./IspotPlayer/TileIspotPlayer";
import TileYoutubeShort from "./YoutubePlayer/TileYoutubeShortPlayer";
import { MoodboardTilesContext } from "./N";
import { Checkbox } from "antd";
import TileEmbed from "./TileEmbed";
import TileInstagram from "./TileInstagram";

export default function MoodboardTiles({
  moodboards,
  onDelete,
  onEdit,
  view,
  onCopy,
  onSaveEditImage,
}) {
  const { selectMode, updateAssetList } = useContext(MoodboardTilesContext);
  const [items, setItems] = useState(
    Array.from(moodboards, (_, i) => i.toString())
  );

  useEffect(() => {
    if (moodboards) {
      setItems(Array.from(moodboards, (_, i) => i.toString()));
    }
  }, [moodboards]);

  const onChangeOrder = useCallback(
    async (newArray) => {
      const sortedArray = [];
      newArray.forEach((item, index) =>
        sortedArray.push({ _id: moodboards[item]._id, order: index + 1 })
      );
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/moodboards/re-order`,
          {
            order: sortedArray,
          }
        );
      } catch (e) {
        console.log("order error", e);
      }
    },
    [moodboards]
  );

  const renderTiles = (moodboard) => {
    if (moodboard?.type && moodboard?.fileUrl) {
      if (moodboard.type === "docs") {
        return (
          <TileEmbed
            currentFile={moodboard}
            delete={onDelete}
            view={view}
            onSave={onSaveEditImage}
            onCopy={onCopy}
          />
        );
      } else if (moodboard.source === "instagram") {
        return (
          <TileInstagram
            currentFile={moodboard}
            delete={onDelete}
            view={view}
          />
        );
      } else if (moodboard.type.includes("image")) {
        return (
          <TileImage
            thumbnail={moodboard.thumbnailUrl}
            currentFile={moodboard}
            delete={onDelete}
            view={view}
            onSave={onSaveEditImage}
            onCopy={onCopy}
          />
        );
      } else if (moodboard.type === "video") {
        if (moodboard.fileUrl.includes("ispot.tv")) {
          return (
            <TileIspotPlayer
              key={moodboard._id}
              id={moodboard._id}
              src={moodboard.fileUrl}
              delete={onDelete}
              edit={onEdit}
              view={view}
              currentFile={moodboard}
            />
          );
        } else if (moodboard.fileUrl.includes("youtube.com/shorts")) {
          return (
            <TileYoutubeShort
              key={moodboard._id}
              id={moodboard._id}
              src={moodboard.fileUrl}
              delete={onDelete}
              edit={onEdit}
              view={view}
              currentFile={moodboard}
            />
          );
        } else if (moodboard.fileUrl.includes("youtube"))
          return (
            <TileYoutube
              key={moodboard._id}
              id={moodboard._id}
              src={moodboard.fileUrl}
              delete={onDelete}
              edit={onEdit}
              view={view}
              videoInfo={moodboard}
            />
          );
        else if (moodboard.fileUrl.includes("vimeo"))
          return (
            <TileVimeo
              key={moodboard._id}
              id={moodboard._id}
              src={moodboard.fileUrl}
              delete={onDelete}
              view={view}
              currentFile={moodboard}
            />
          );
        else if (moodboard.fileUrl.includes("filmboard.s3"))
          return (
            <TileVideo
              key={moodboard._id}
              id={moodboard._id}
              image={moodboard.thumbnailUrl}
              src={moodboard.fileUrl}
              delete={onDelete}
              view={view}
              edit={() => onEdit(moodboard)}
              currentFile={moodboard}
            />
          );
        else
          return (
            <TileVideo
              key={moodboard._id}
              id={moodboard._id}
              image={moodboard.thumbnailUrl}
              src={moodboard.fileUrl}
              delete={onDelete}
              view={view}
              edit={() => onEdit(moodboard)}
              currentFile={moodboard}
            />
          );
      } else if (moodboard.type === "audio") {
        return (
          <TileAudio
            key={moodboard._id}
            id={moodboard._id}
            src={moodboard.fileUrl}
            delete={onDelete}
            view={view}
          />
        );
      } else if (moodboard.type === "pexels-video") {
        return (
          <TileVideo
            key={moodboard._id}
            id={moodboard._id}
            image={moodboard.thumbnailUrl}
            src={moodboard.fileUrl}
            delete={onDelete}
            view={view}
            edit={() => onEdit(moodboard)}
            currentFile={moodboard}
          />
        );
      } else if (moodboard.type === "website") {
        return (
          <TileWebsite
            key={moodboard._id}
            id={moodboard._id}
            image={moodboard.thumbnailUrl}
            src={moodboard.fileUrl}
            delete={onDelete}
            view={view}
            moodboard={moodboard}
          />
        );
      }
    }
  };

  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const sensors = useSensors(touchSensor, mouseSensor);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        const sortedArray = arrayMove(items, oldIndex, newIndex);
        onChangeOrder(sortedArray);
        return sortedArray;
      });
    }
  };

  if (moodboards.length !== items.length) {
    return null;
  }

  const onClickCheckbox = (index) => {
    const checkbox = document.querySelectorAll("input[type=checkbox]");
    checkbox[index].click();
  };

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext items={items}>
        {items.map((id, index) => (
          <SortableItem
            key={moodboards[id]?._id}
            id={id}
            moodboards={moodboards}
            className={selectMode && "copy-mode"}
            onClick={selectMode ? () => onClickCheckbox(index) : null}
          >
            {selectMode && (
              <div
                onclick={(e) => {
                  e.preventDefault();
                  onClickCheckbox(index);
                }}
              >
                <Checkbox
                  className="copy-mode-checkbox"
                  onChange={(e) => {
                    updateAssetList(moodboards[id]);
                  }}
                />
              </div>
            )}
            {renderTiles(moodboards[id])}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
}
