import React, {useState} from 'react';

import srcUrl from '../images/default-audio.png';

export default function ThumbnailAudio(props) {
    
    const handlePlay = e => {
        e.preventDefault();
        if(props.setPlaying) {
            props.setPlaying(props.item);
        }
    }

    return (
        <img src={srcUrl} className={`video-thumbnail ${props.item._id}`} title={props.setPlaying && "Click to play this audio."} onClick={handlePlay} />
    );
}