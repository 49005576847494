import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Rodal from "rodal";
import { BrowserView, MobileView } from "react-device-detect";
import "rodal/lib/rodal.css";
import "../css/tabs.css";
import "../css/rodal.css";
import "../css/pexels.css";

import PexelPhoto from "./PexelPhoto";
import PexelVideo from "./PexelVideo";
import { Image } from "antd";
import axios from "axios";

//const client = createClient(process.env.REACT_APP_PEXELS_API_KEY);
const headers = {
  Authorization: "563492ad6f91700001000001a93e0e150e754cddbc735dc27395cb90",
};

export default function Pexels(props) {
  const recordsPerPage = 30;
  const [photos, setPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPhotos, setTotalPhotos] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalPhotosPages, setTotalPhotosPages] = useState(0);
  const [totalVideosPages, setTotalVideosPages] = useState(0);
  const [selectedTab, setSelectedTab] = useState("photos");
  const [allowNext, setAllowNext] = useState(true);
  const [allowPrevious, setAllowPrevious] = useState(false);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null);

  const styles = {
    background: "#191A1F",
    width: "85%",
    height: "85%",
    maxHeight: "calc(100vh - 100px)",
    overflowY: "hidden",
  };

  useEffect(() => {
    search();
  }, [currentPage]);

  const search = async () => {
    document
      .querySelectorAll(".not-found")
      .forEach((x) => (x.innerText = "Please try again later."));

    if (selectedTab === "photos") searchPhotos();

    if (selectedTab === "videos") searchVideos();
  };

  const searchPhotos = async () => {
    const query =
      document.getElementById("txt-search-query").value || `Landscape`;
    const { data: photos } = await axios.get(
      "https://api.pexels.com/v1/search",
      {
        params: { query, per_page: recordsPerPage, page: currentPage },
        headers,
      }
    );
    setTotalPhotos(photos.total_results);
    setPhotos(photos.photos);
    let t = photos.total_results / recordsPerPage;
    t = t === 0 ? t : Math.ceil(photos.total_results / recordsPerPage);
    setTotalPhotosPages(t);
  };

  const searchVideos = async () => {
    const query =
      document.getElementById("txt-search-query").value || `Landscape`;
    const { data: videos } = await axios.get(
      "https://api.pexels.com/videos/search",
      {
        params: { query, per_page: recordsPerPage, page: currentPage },
        headers,
      }
    );
    setTotalVideos(videos.total_results);
    setVideos(videos.videos);
    let t = videos.total_results / recordsPerPage;
    t = t === 0 ? t : Math.ceil(videos.total_results / recordsPerPage);
    setTotalVideosPages(t);
  };

  const handleSearch = (e) => {
    search();
    setCurrentPage(1);
  };

  const selectPhoto = (photo) => {
    setSelectedPhotos([
      ...selectedPhotos,
      {
        link: photo.src.portrait,
        thumbnailUrl: photo.src.tiny,
      },
    ]);
  };
  const deselectPhoto = (photo) => {
    setSelectedPhotos(
      selectedPhotos.filter((e) => e.link !== photo.src.portrait)
    );
  };

  const selectVideo = (video) => {
    let link = video.video_files[1].link;
    if (video.video_files.length > 4) {
      link = video.video_files[4].link;
    }

    setSelectedVideos([
      ...selectedVideos,
      {
        link,
        thumbnailUrl: video.image, //.replace("h=630", "h=200").replace("w=1200", "w=280")
      },
    ]);
    // setSelectedVideos([...selectedVideos, link]);
  };

  const deselectVideo = (video) => {
    if (video && video.video_files) {
      let link =
        video.video_files.length > 4
          ? video.video_files[4].link
          : video.video_files[1].link;
      setSelectedVideos(selectedVideos.filter((e) => e.link !== link));
    }
  };

  const onPreviewPhoto = (selectedImg) => {
    setPreviewSrc(selectedImg);
    setIsPreviewVisible(true);
  };

  const renderPhotos = () =>
    photos.map((photo) => (
      <PexelPhoto
        key={photo.id}
        photo={photo}
        selectPhoto={selectPhoto}
        deselectPhoto={deselectPhoto}
        onPreviewPhoto={onPreviewPhoto}
      />
    ));

  const renderVideos = () =>
    videos.map((video) => (
      <PexelVideo
        key={video.id}
        video={video}
        selectVideo={selectVideo}
        deselectVideo={deselectVideo}
      />
    ));

  const handleValidate = () => {
    const query = document.getElementById("txt-search-query").value || ``;
    if (!query || query.length === 0) {
      document.getElementById("txt-search-query").classList.add("is-invalid");
    } else {
      document
        .getElementById("txt-search-query")
        .classList.remove("is-invalid");
    }
  };

  const handleSelect = () => {
    //const selectedPhotos = [];

    /*$('input[type="checkbox"][id^="pexel-img-"]:checked').each(function () {
            selectedPhotos.push($(this).parent().find('img').attr('src'));
        });*/

    /*const selectedVideos = [];
        $('input[type="checkbox"][id^="pexel-video-"]:checked').each(function () {
            selectedVideos.push($(this).parent().find('video').find('source').attr('src'));
        });
        console.log(selectedVideos)*/

    props.onSelect({
      photos: selectedPhotos,
      videos: selectedVideos,
    });
    props.onClose();
  };

  const handleKeyPress = (event) => {
    if (event.key == "Enter") {
      handleSearch();
    }
  };

  const nextPage = () => {
    if (selectedTab === "photos") {
      let t = currentPage;
      if (t < totalPhotosPages) {
        setAllowNext(true);
        t += 1;
      }
      if (t > 0) {
        setAllowPrevious(true);
      }
      if (t === totalPhotosPages) {
        setAllowNext(false);
        setAllowPrevious(true);
      }

      setCurrentPage(t);
    } else if (selectedTab === "videos") {
      let t = currentPage;
      if (t < totalVideosPages) {
        setAllowNext(true);
        t += 1;
      }
      if (t > 0) {
        setAllowPrevious(true);
      }
      if (t === totalVideosPages) {
        setAllowNext(false);
        setAllowPrevious(true);
      }

      setCurrentPage(t);
    }
  };

  const previousPage = () => {
    if (selectedTab === "photos") {
      let t = currentPage;
      if (t > 1) {
        setAllowNext(true);
        setAllowPrevious(true);
        t -= 1;
      }
      if (t === 1) {
        setAllowNext(true);
        setAllowPrevious(false);
      }

      setCurrentPage(t);
    } else if (selectedTab === "videos") {
      let t = currentPage;
      if (t > 1) {
        setAllowNext(true);
        setAllowPrevious(true);
        t -= 1;
      }
      if (t === 1) {
        setAllowNext(true);
        setAllowPrevious(false);
      }

      setCurrentPage(t);
    }
  };

  const changeTab = (name) => {
    setSelectedTab(name);
    if (name === "photos") {
      searchPhotos();
    }
    if (name === "videos") {
      searchVideos();
    }
    setAllowNext(true);
    setAllowPrevious(false);
    setCurrentPage(1);
  };

  return (
    <Rodal
      visible={true}
      onClose={() => props.onClose()}
      closeOnEsc={false}
      closeMaskOnClick={true}
      customStyles={styles}
    >
      <div className="container">
        <div className="row mt-20">
          <div className="col-11">
            <input
              type="text"
              className="form-control form-control-3"
              id="txt-search-query"
              placeholder="Search from Pexels"
              onChange={handleValidate}
              onKeyUp={handleKeyPress}
            />
            <div className="invalid-feedback text-left">
              Please provide search query.
            </div>
          </div>
          <div className="col-1">
            <BrowserView>
              <button
                id="search-button"
                type="button"
                className="btn btn-dark"
                onClick={handleSearch}
              >
                Search
              </button>
            </BrowserView>
            <MobileView>
              <SearchOutlined
                className="btn btn-dark"
                id="search-button"
                onClick={handleSearch}
              />
            </MobileView>
          </div>
        </div>
        <Image
          preview={{
            visible: isPreviewVisible,
            onVisibleChange: (visible, prevVisible) => {
              setIsPreviewVisible(visible);
              if (!visible) {
                setPreviewSrc(null);
              }
            },
          }}
          style={{ display: "none" }}
          src={previewSrc}
        />
        <div className="row mt-10">
          <div className="col-12">
            <div className="tabbable-panel">
              <div className="tabbable-line">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" onClick={() => changeTab("photos")}>
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      style={{ paddingLeft: "0px" }}
                      aria-controls="home"
                      aria-selected="true"
                    >
                      Photos{" "}
                      <span className="total-record-count">
                        (
                        {totalPhotos
                          ? totalPhotos
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "0"}
                        )
                      </span>
                    </a>
                  </li>
                  <li className="nav-item" onClick={() => changeTab("videos")}>
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                    >
                      Videos{" "}
                      <span className="total-record-count">
                        (
                        {totalVideos
                          ? totalVideos
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : "0"}
                        )
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 pexel-gallery">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {(photos && photos.length > 0 && renderPhotos()) || (
                  <div className="not-found">No photos found.</div>
                )}
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                {(videos && videos.length > 0 && renderVideos()) || (
                  <div className="not-found">No videos found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-20">
          <div className="col text-left">
            <button
              className="btn btn-styled mt-0 btn-new m-1"
              disabled={!allowPrevious}
              onClick={previousPage}
            >
              <i className="fa fa-arrow-left"></i>
            </button>
            <button
              className="btn btn-styled mt-0 btn-new m-1"
              disabled={!allowNext}
              onClick={nextPage}
            >
              <i className="fa fa-arrow-right"></i>
            </button>
            <span className="ml-3">
              Page {currentPage} of{" "}
              {selectedTab === "photos" ? totalPhotosPages : totalVideosPages}
            </span>
          </div>
          <div className="col text-right">
            {/*<button className="btn btn-styled mt-0 btn-new m-1" onClick={e => props.onClose()}>Close</button>*/}
            <button
              className="btn btn-styled mt-0 btn-new m-1"
              onClick={handleSelect}
            >
              Select
              {selectedPhotos.length > 1
                ? " " + selectedPhotos.length + " Photos"
                : ""}
              {selectedVideos.length > 1
                ? " " + selectedVideos.length + " Videos"
                : ""}
            </button>
          </div>
        </div>
        <div className="row mt-20 mb-20">
          <a href="https://pexels.com">
            <div>Photos provided by Pexels</div>
          </a>
        </div>
      </div>
    </Rodal>
  );
}
