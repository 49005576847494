import { Button, DatePicker, Input, Modal, Select, notification } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import SearchItem from "./SearchItem";
import { countries } from "utils/countries";
import { debounce } from "utils/utils";
import session from "services/session";
import { brandList } from "utils/brandList";

import "./SearchPage.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;

const signupErrorMsg = "Please sign up to continue using this";
const upgradeErrorMsg = "Please upgrade your plan to continue using this";

const MAXIMUM_FREE_SEARCH = 15;

const SearchPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [country, setCountry] = useState("United States");
  const [yearRange, setYearRange] = useState([2018, 2024]);
  const [brand, setBrand] = useState();
  const [page, setPage] = useState(1);
  const [isOpenErrModal, setIsOpenErrModal] = useState(false);
  const [errMessage, setErrMessage] = useState();

  const userInfo = JSON.parse(localStorage.getItem("user"));

  const isLoggedIn = useMemo(() => {
    return session.get("user") ? true : false;
  }, [session]);

  const isFreeAccount = useMemo(() => {
    return userInfo?.package === "1";
  }, [userInfo]);

  useEffect(() => {
    const fetchVideo = async () => {
      // If user not login or using free account, they just use search for 5 times
      const numberSearch = Number(sessionStorage.getItem("numberOfSearch"));
      // we minus 1 because localStorage delay one time
      if (numberSearch > MAXIMUM_FREE_SEARCH - 1 && !isLoggedIn) {
        setErrMessage(signupErrorMsg);
        setIsOpenErrModal(true);
        return;
      } else if (numberSearch > MAXIMUM_FREE_SEARCH - 1 && isFreeAccount) {
        setErrMessage(upgradeErrorMsg);
        setIsOpenErrModal(true);
        return;
      }

      try {
        setLoading(true);
        sessionStorage.setItem(
          "numberOfSearch",
          numberSearch ? numberSearch + 1 : 1
        );
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}/ad-forum/search?year-range=${yearRange[0]}-${yearRange[1]}`,
          {
            headers: {
              "x-api-key": "BfFOvLfysZ6iguYmGaQVIhfMVDu3ArY5",
            },
            params: {
              activity_strkey: brand,
              country: country !== "All Countries" ? country : null,
              page,
              q: searchValue,
            },
          }
        );

        if (page > 1) {
          setSearchResults([...searchResults, ...result.data.data]);
        } else {
          setSearchResults(result.data.data);
        }
      } catch (e) {
        console.log("error", e);
      } finally {
        setLoading(false);
      }
    };
    fetchVideo();
  }, [searchValue, yearRange, country, page, brand]);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = document.body;
      if (scrollTop + clientHeight >= scrollHeight - 20 && !loading) {
        setPage(page + 1);
      }
    };

    if (searchResults.length) {
      document.body.addEventListener("scroll", handleScroll);
      return () => {
        document.body.removeEventListener("scroll", handleScroll);
      };
    }
  }, [loading, searchResults]);

  const changeSearch = ({ target: { value: newSearchValue } }) => {
    if (newSearchValue.length === 0) {
      setSearchValue(null);
    } else {
      setSearchValue(newSearchValue);
    }
    setPage(1);
  };

  const changeBrand = (selectBrand) => {
    setBrand(selectBrand);
    setPage(1);
  };

  const debounceSearchTerm = useCallback(debounce(changeSearch), []);

  const changeYear = (rangeYear) => {
    const newRangeYear = [rangeYear[0].year(), rangeYear[1].year()];
    setYearRange(newRangeYear);
    setPage(1);
    window.scrollTo({
      top: 0,
    });
  };

  const changeCountry = (selectCountry) => {
    setCountry(selectCountry);
    setPage(1);
  };

  return (
    <div className="searchPage">
      <div className="searchContainer">
        <Input
          size="large"
          placeholder="Search"
          prefix={<SearchOutlined />}
          onChange={debounceSearchTerm}
        />
        <Select
          className="brandInput"
          value={brand}
          defaultValue={"Business Sector"}
          size="large"
          onChange={changeBrand}
        >
          {brandList.map((brand) => (
            <Option key={brand.code} value={brand.code}>
              {brand.label}
            </Option>
          ))}
        </Select>

        <Select
          className="countrySelect"
          value={country}
          defaultValue={"Select country"}
          size="large"
          onChange={changeCountry}
        >
          {countries.map((country) => (
            <Option key={country} value={country}>
              {country}
            </Option>
          ))}
        </Select>
        <RangePicker
          className="yearPicker"
          picker="year"
          id={{
            start: "startInput",
            end: "endInput",
          }}
          defaultValue={[
            dayjs("2018-01-01", "YYYY-MM-DD"),
            dayjs("2024-01-01", "YYYY-MM-DD"),
          ]}
          onChange={changeYear}
          allowClear={false}
        />
      </div>

      <div className="searchResult">
        {searchResults.map((searchResult) => (
          <SearchItem key={searchResult.id} data={searchResult} />
        ))}
      </div>
      {loading && (
        <div className="loadingIconWrapper">
          <LoadingOutlined className="loading-icon" />
        </div>
      )}

      <Modal
        wrapClassName="search-err-modal"
        title={null}
        footer={null}
        open={isOpenErrModal}
        onCancel={() => setIsOpenErrModal(false)}
        width={600}
        destroyOnClose
      >
        {errMessage === signupErrorMsg ? (
          <div>
            <div className="title">Sign Up For Free</div>
            <div className="message">
              Add videos and stills from third-party apps, collaborate with
              others, get additional cloud storage, and support.
            </div>
            <Button type="primary" onClick={() => navigate("/signup")}>
              Sign up
            </Button>
          </div>
        ) : (
          <div>
            <div className="title">Upgrade to unlock full access</div>
            <div className="message">
              Add videos and stills from 200k+ broadcast commercials, generate
              AI references, get additional cloud storage, and premium support.
            </div>
            <Button type="primary" onClick={() => navigate("/billing")}>
              Upgrade
            </Button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SearchPage;
