import React, { useCallback, useEffect, useState } from "react";
import Rodal from "rodal";
import { Input as antInput } from "antd";
import "rodal/lib/rodal.css";
import "../css/tabs.css";
import "../css/rodal.css";
import $ from "jquery";
import commentService from "../services/comment";
import { swalError } from "../utils/swal";
import session from "../services/session";
import io from "socket.io-client";
import moment from "moment";
import Comment from "./Comment";
import CommentBox from "./CommentBox";
import Login from "./Login";
import Signup from "./Signup";
import CollaboratorsDropdown from "./CollaboratorsDropdown";
import { WithCursorDropdown, CursorDropdown } from "react-cursor-dropdown";
import collaborationService from "../services/collaboration";
import projectService from "../services/project";
import Swal from "sweetalert2";
import { stripHtmlTags } from "utils/utils";
import TextAreaMention from "./TextAreaMention/TextAreaMention";

const { TextArea } = antInput;

const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket"] });
socket.on("connect", () => {
  // console.log("Connected to SocketIO Server.");
});

const Input = (props) => {
  return (
    // <input
    //   {...props}
    //   type="text"
    //   id="txt-comment"
    //   className="form-control form-control-2"
    // />
    <TextArea
      placeholder="Share your thoughts"
      autoSize
      {...props}
      type="text"
      rows="1"
      id="txt-comment"
      className="form-control form-control-2"
      style={{ padding: "16px" }}
    />
  );
};

const InputWithCursorDropdown = WithCursorDropdown(Input);

export default function ContentComments(props) {
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [counter, setCounter] = useState(0);
  const [commentToUpdate, setCommentToUpdate] = useState(null);
  const [message, setMessage] = useState("");
  const [id, setId] = useState(``);
  const [project, setProject] = useState(null);
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    reloadProject();
  }, []);

  useEffect(() => {
    const projectId = window.location.href.split("/").pop();
    if (projectId && projectId.length === 24) {
      collaborationService
        .getCollaboratorsNamesByProject(projectId)
        .then((result) => {
          setMemberList(result.data);
        });
    }
  }, []);

  useEffect(() => {
    setComments([]);
    setMessage("Loading comments...");
    const moodboardId = props.id;
    socket.emit(`init-comments`, moodboardId);

    socket.off(`moodboard-comment-init-data`);
    socket.on(`moodboard-comment-init-data`, (data) => {
      setComments(data);
      if (data.length === 0) setMessage("No comments found.");
    });

    socket.off(`remote-update-comments`);
    socket.on(`remote-update-comments`, (data) => {
      setComments(data);
      if (data.length === 0) setMessage("No comments found.");
    });

    return () => {
      socket.emit(`disconnect-comments`, moodboardId);
    };
  }, [props.id]);

  const deleteCommentById = (id) => {
    socket.emit(`delete-comment`, {
      commentId: id,
      moodboardId: props.id,
    });
    socket.emit(`get-comments`, props.id);
  };

  const reloadComments = async () => {
    if (!props.id) return;
    await commentService.getByMoodboard(props.id).then((result) => {
      if (result.error) {
        swalError(result.error);
        return;
      }
      setComments(result.data);
      setMessage("No comments found.");
    });
  };

  const renderComments = () => {
    return comments.map((comment) => (
      <CommentBox
        key={comment._id}
        comment={comment}
        reloadComments={reloadComments}
        setCommentToUpdate={(obj) => {
          setId(obj.id);
          setComment(obj.text);
          setCommentToUpdate(obj);
        }}
        deleteComment={deleteCommentById}
        memberList={memberList}
      />
    ));
  };

  const reset = () => {
    document.getElementById("txt-comment-id").value = ``; //eror
    document
      .getElementsByClassName("tagify--mix")[0]
      .classList.remove("is-invalid");
    setComment("");
    setId("");
  };

  const handleComment = async (e) => {
    e.preventDefault();
    if (!(session.get("user") && session.get("user")._id !== undefined)) {
      swalError("Please log in");
      return;
    }

    if (!comment || comment.length === 0) {
      document
        .getElementsByClassName("tagify--mix")[0]
        .classList.add("is-invalid");
      return;
    } else {
      document
        .getElementsByClassName("tagify--mix")[0]
        .classList.remove("is-invalid");
    }

    if (!id || id.length === 0) {
      const projectId = window.location.href.split("/").pop();
      const moodboardId = props.id;

      const newComment = {
        _id: Math.random().toString(36).substring(7),
        text: comment,
        moodboardId: moodboardId,
        projectId: projectId,
        type: "moodboard",
        date: moment().format(),
        userId: session.get("user")._id,
      };
      setComments([...comments, newComment]);
      reset();

      socket.emit(`save-comment`, {
        text: comment,
        moodboardId: moodboardId,
        projectId: projectId,
        type: "moodboard",
        date: moment().format(),
        userId: session.get("user")._id,
      });
    } else {
      reset();
      const moodboardId = props.id;
      socket.emit(`update-comment`, {
        commentId: id,
        text: comment,
        moodboardId: moodboardId,
      });
    }
  };

  const reloadProject = async () => {
    let id = window.location.href.split("/").pop();
    if (id && id.length === 24) {
      await projectService.get(id).then((result) => {
        if (result.error) {
          swalError(result.error);
          Swal.close();
          return;
        }

        if (result.data.length === 1) {
          setProject(result.data[0]);
        }
      });
    }
  };

  const allowCollaborate = () => {
    if (project) {
      const loggedInUserId = session.get("user")._id;
      const loggedInUserEmail = session.get("user").email;
      if (!loggedInUserId || !loggedInUserEmail) {
        return false;
      }

      if (project.userId._id.toString() === loggedInUserId.toString()) {
        return true;
      }

      const collaboration = project.collaborations.find(
        (k) => k.email === loggedInUserEmail
      );
      if (collaboration) {
        if (
          parseInt(collaboration.accepted) === 1 &&
          parseInt(collaboration.type) === 2
        ) {
          return true;
        }
      }

      return false;
    }
  };

  return (
    <>
      {allowCollaborate() && (
        <div className="row mb-20">
          <div className="col">
            <form>
              <input type="hidden" id="txt-comment-id" value={id} />
              <TextAreaMention
                value={comment}
                setComment={setComment}
                onSubmit={handleComment}
                memberList={memberList}
              />
              <div className="invalid-feedback text-left">
                Please provide a text.
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="row comments">
        <div className="col">
          {(comments.length > 0 && renderComments()) || message}
        </div>
      </div>
    </>
  );
}
