import "../../css/moodboard-masonry.css";
import IspotPlayer from "./IspotPlayer";
import ItemBar from "../ItemBar/ItemBar";

function TileIspotPlayer({ currentFile, view, ...props }) {
  return (
    <div className={`tile ${props.id}`}>
      <ItemBar
        currentFile={currentFile}
        viewItem={() => view(currentFile._id)}
        deleteItem={() => props.delete(currentFile._id)}
      />
      <IspotPlayer
        url={props.src}
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
        className="ispot-tile"
      />
    </div>
  );
}

export default TileIspotPlayer;
