import axios from 'axios';

import session from "./session";
import moment from "moment";

export default class {

    static addMany = async (emails, type, projectId) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            date: moment().format(),
            emails: emails,
            type: type,
            projectId: projectId
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/collaborations/many`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static acceptInvite = async id => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            id: id
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/collaborations/accept`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static updateAccessType = async (id, typeId) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            type: typeId
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/collaborations/${id}`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getByProject = async projectId => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/collaborations/${projectId}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static getCollaboratorsNamesByProject = async projectId => {
        let result = {
            data: null,
            error: null
        };

        await axios.get(`${process.env.REACT_APP_API_URL}/collaborations/names/${projectId}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static check = async (type, moodboardId, projectId) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            userId: session.get('user')._id,
            type: type,
            moodboardId: moodboardId,
            projectId: projectId
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/likes/check`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static delete = async id => {
        let result = {
            data: null,
            error: null
        };

        await axios.delete(`${process.env.REACT_APP_API_URL}/collaborations/${id}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static deleteByProject = async projectId => {
        let result = {
            data: null,
            error: null
        };

        await axios.delete(`${process.env.REACT_APP_API_URL}/collaborations/project/${projectId}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}