import axios from 'axios';

import moment from "moment";
import session from "./session";

export default class {

    static add = async (script, projectId) => {
        let result = {
            data: null,
            error: null
        };

        const data = {
            lastUpdated: moment().format(),
            text: script,
            projectId: projectId
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/scripts`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}