import React from "react";
import ThumbnailVimeo from "./ThumbnailVimeo";
import ThumbnailYouTube from "./ThumbnailYouTube";
import ThumbnailVideo from "./ThumbnailVideo";
import ThumbnailAudio from "./ThumbnailAudio";
import ThumbnailImage from "./ThumbnailImage";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import IspotPlayer from "./IspotPlayer/IspotPlayer";

export default function DraggableThumbnail({ item, setPlaying }) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: item._id });

  const itemStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleSetPlaying = () => {
    setPlaying && setPlaying(item);
  };

  if (!!(item.type && item.fileUrl)) {
    return (
      <div
        style={itemStyle}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className={`video-thumbnail`}
      >
        {item.type === "video" && (
          <>
            {item.fileUrl.includes("ispot.tv") && (
              <IspotPlayer
                url={item.fileUrl}
                style={{ pointerEvents: "none" }}
                onClick={() => handleSetPlaying(item)}
              />
            )}
            {item.fileUrl.includes("vimeo") && (
              <ThumbnailVimeo item={item} setPlaying={handleSetPlaying} />
            )}
            {(item.fileUrl.includes("youtube") ||
              item.fileUrl.includes("youtu.be")) && (
              <ThumbnailYouTube item={item} setPlaying={handleSetPlaying} />
            )}
            {item.fileUrl.includes("s3") && (
              <ThumbnailVideo item={item} setPlaying={handleSetPlaying} />
            )}
            {item.source === "instagram" && (
              <img
                src={
                  "https://cdn.digitbin.com/wp-content/uploads/How-to-Send-Silent-and-Disappearing-Messages-on-Instagram.png"
                }
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
                onClick={() => handleSetPlaying(item)}
              />
            )}
            {item.thumbnailUrl && (
              <ThumbnailImage item={item} setPlaying={handleSetPlaying} />
            )}
          </>
        )}
        {item.type === "audio" && (
          <ThumbnailAudio item={item} setPlaying={handleSetPlaying} />
        )}
        {(item.type === "image" || item.type === "docs") && (
          <ThumbnailImage item={item} setPlaying={handleSetPlaying} />
        )}
        {item.type === "website" && (
          <ThumbnailImage item={item} setPlaying={handleSetPlaying} />
        )}
        {item.type === "pexels-video" && (
          <ThumbnailVideo item={item} setPlaying={handleSetPlaying} />
        )}
      </div>
    );
  }

  return null;
}
