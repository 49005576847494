import React, { useEffect, useState } from "react";
import Login from "./Login";
import Signup from "./Signup";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "../css/tabs.css";
import "../css/rodal.css";
import $ from "jquery";
import commentService from "../services/comment";
import { swalError } from "../utils/swal";
import io from "socket.io-client";
import Comment from "./Comment";
import CommentBox from "./CommentBox";

const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket"] });
socket.on("connect", () => {
  // console.log("Connected to SocketIO Server.");
});

export default function CommentsPopup(props) {
  const [comments, setComments] = useState([]);
  const [commentToUpdate, setCommentToUpdate] = useState({});
  const styles = {
    background: "#343232",
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    socket.emit(`moodboard-comment`, `${props.id}`);

    socket.on(`moodboard-comment-data`, (data) => {
      if (data) {
        console.log(data);
      }
    });
    (async () => {
      reloadComments();
    })();
  }, []);

  const reloadComments = async () => {
    if (props.type === "moodboard") {
      await commentService.getByMoodboard(props.id).then((result) => {
        if (result.error) {
          swalError(result.error);
          return;
        }

        setComments(result.data);
        props.reloadCommentsInParent();
      });
    } else if (props.type === "storyboard") {
      const projectId = window.location.href.split("/").pop();
      await commentService.getByStoryboard(projectId).then((result) => {
        if (result.error) {
          swalError(result.error);
          return;
        }

        setComments(result.data);
      });
    }
  };

  const renderComments = () =>
    comments.length > 0
      ? comments.map((comment) => (
          <CommentBox
            key={comment._id}
            comment={comment}
            reloadComments={reloadComments}
            setCommentToUpdate={(obj) => setCommentToUpdate(obj)}
          />
        ))
      : `No comments found.`;

  return (
    <div className="comments-popup">
      <Rodal
        visible={true}
        onClose={() => props.onClose()}
        closeOnEsc={true}
        closeMaskOnClick={true}
        customStyles={styles}
      >
        <div className="container text-center">
          <div className="row mt-10">
            <div className="col-12 col-sm-12 text-center comments-padding">
              <Comment
                reloadComments={reloadComments}
                playingItemId={props.id || null}
                setLoginOrSignup={props.setLoginOrSignup}
                comment={commentToUpdate}
                type={props.type}
              />
              {renderComments()}
            </div>
          </div>
        </div>
      </Rodal>
    </div>
  );
}
