import { Progress } from "antd";
import { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";

import "./UploadFile.scss";

const UploadFile = ({ file, uploadSpeed }) => {
  const [percentage, setPercentage] = useState(0);
  const [uploadDuration, setUploadDuration] = useState(null);
  const [uploadedMb, setUploadedMb] = useState(null);

  function bytesToMB(bytes) {
    const megabytes = bytes / 1048576;
    return megabytes.toFixed(2);
  }

  useEffect(() => {
    if (file) {
      const uploadDuration = Math.ceil(file.size / uploadSpeed) * 1000; // Total upload time in milliseconds
      setUploadDuration(uploadDuration);
    }
  }, [file]);

  useEffect(() => {
    if (uploadDuration) {
      let remainingTime = uploadDuration;
      const uploadInterval = setInterval(() => {
        remainingTime -= 1000;
        const timeElapsed = uploadDuration - remainingTime;

        // calculate uploaded mb
        let uploadedBytes = Math.min(
          file.size,
          (timeElapsed / uploadDuration) * file.size
        );
        setUploadedMb(bytesToMB(uploadedBytes));

        const percentageCompleted =
          ((uploadDuration - remainingTime) / uploadDuration) * 100;

        setPercentage(percentageCompleted);
        if (remainingTime <= 0) {
          clearInterval(uploadInterval);
          remainingTime = 0;
        }
      }, 1000);
    }
  }, [uploadDuration]);

  return (
    <div className="uploadFile">
      <div className="fileInfo">
        <UploadOutlined className="uploadIcon" />
        <div className="file-info">
          <div>{file.name}</div>
          <div className="upload-info">
            Uploading {uploadedMb}mb / {bytesToMB(file.size)}mb
          </div>
        </div>
      </div>

      <Progress percent={percentage} status="active" showInfo={false} />
    </div>
  );
};

export default UploadFile;
