import React, { useEffect, useState, useRef, useMemo } from "react";
import ReactDOM from "react-dom";
import io from "socket.io-client";

const socket = io(process.env.REACT_APP_API_URL, { transports: ["websocket"] });
socket.on("connect", () => {
  // console.log("Connected to SocketIO Server.");
});

function SyncingEditorSimple(props) {
  const [value, setValue] = useState(``);

  useEffect(() => {
    socket.emit(`init`, `${props.projectId}`);

    socket.on(`init-data`, (data) => {
      if (data && data.text) {
        setValue(data.text);
      }
    });

    socket.on(`remote-update`, (data) => {
      if (data && data.text) {
        setValue(data.text);
      }
    });
  }, []);

  const onTextChanged = (e) => {
    const value = e.target.value;
    setValue(value);
    socket.emit("update-text", {
      projectId: `${props.projectId}`,
      text: e.target.value,
    });
  };

  return (
    <div className="script-container">
      <textarea
        placeholder="No need to save changes for the script, changes will be saved automatically. Start typing..."
        className="form-control script-textarea"
        rows={55}
        value={value}
        onChange={onTextChanged}
      ></textarea>
    </div>
  );
}

export default SyncingEditorSimple;
