import { Button, Modal, notification } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import ReactPlayer from "react-player";
import axios from "axios";

import LoadingPercent from "./LoadingPercent";
import AddToBoardModal from "./AddToBoardModal";
import session from "services/session";

import "./SearchPage.scss";

const SearchItem = ({ data }) => {
  const modalRef = useRef();

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenGrabs, setScreenGrabs] = useState([]);
  const [listAssets, setListAssets] = useState([]);

  const isLoggedIn = useMemo(() => {
    return session.get("user") ? true : false;
  }, [session]);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const generateScreenGrab = async () => {
      setLoading(true);
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}/ad-forum/generate-screenshot`,
          {
            videoUrl: data.asset_original_url,
          }
        );
        setScreenGrabs(
          res.data.map((thumbnail) => {
            return `${process.env.REACT_APP_API_URL}${thumbnail.original}`;
          })
        );
      } catch (e) {
        console.log("e", e);
      } finally {
        setLoading(false);
      }
    };
    if (
      data &&
      data.asset_type === "video" &&
      isModalOpen &&
      screenGrabs.length === 0
    ) {
      generateScreenGrab();
    }
  }, [data, isModalOpen]);

  const onAddingVideo = (e) => {
    e.stopPropagation();
    if (isLoggedIn) {
      setListAssets([
        {
          fileUrl: data.asset_original_url,
          type: "video",
          thumbnail: data.asset_thumbnail.replace(
            "gimscdn.maydream.com",
            "ims.maydream.com"
          ),
        },
      ]);
      modalRef.current.openModal();
    } else {
      notification.error({ message: "Please login first" });
    }
  };

  const onAddScreenGrab = (e, screenGrab) => {
    e.stopPropagation();
    if (isLoggedIn) {
      setListAssets([
        { fileUrl: screenGrab, type: "image", thumbnail: screenGrab },
      ]);
      modalRef.current.openModal();
    } else {
      notification.error({ message: "Please login first" });
    }
  };

  return (
    <div className="searchItem">
      <img
        className="searchItem"
        src={data.asset_thumbnail.replace(
          "gimscdn.maydream.com",
          "ims.maydream.com"
        )}
        onClick={() => setIsModalOpen(true)}
      />
      <Button className="addBoardBtn" type="primary" onClick={onAddingVideo}>
        Add To Board
      </Button>

      <Modal
        title={null}
        footer={null}
        wrapClassName="searchItemModal"
        width={"100vw"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={null}
        destroyOnClose
      >
        <ArrowLeftOutlined
          className="backDetailBtn"
          onClick={() => handleCancel()}
        />
        {data.asset_type === "video" ? (
          <ReactPlayer
            className="search-video"
            url={data.asset_original_url}
            controls={true}
          />
        ) : (
          <img className="search-image" src={data.asset_original_url} />
        )}
        <div className="video-info">
          <div>
            <div className="videoTitle">{data.title}</div>
            <div className="videoCampain">{data.campaign}</div>
            <div className="contributors">
              {data.contributions.map((contributor, index) => (
                <div key={index} className="contributorWrapper">
                  <div className="contributorTitle">
                    {contributor.contribution}:
                  </div>
                  <div className="contributor">{contributor.entity}</div>
                </div>
              ))}
            </div>
          </div>
          <Button
            className="addBoardBtn"
            type="primary"
            onClick={onAddingVideo}
          >
            Add To Board
          </Button>
        </div>
        {loading && <LoadingPercent />}
        <div className="screengrabs-list">
          {screenGrabs?.length &&
            screenGrabs.map((screenGrab, index) => (
              <div key={index} className="screenGrab">
                <img className="screenGrab" src={screenGrab} />
                <Button
                  className="addBoardBtn"
                  type="primary"
                  onClick={(e) => onAddScreenGrab(e, screenGrab)}
                >
                  Add To Board
                </Button>
              </div>
            ))}
        </div>
      </Modal>
      <AddToBoardModal ref={modalRef} listAssets={listAssets} />
    </div>
  );
};

export default SearchItem;
