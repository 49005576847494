import React, { useState, useEffect } from "react";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import ImagePlayer from "./ImagePlayer";

export default function PexelPhoto(props) {
  const { onPreviewPhoto } = props;

  const [showImage, setShowImage] = useState(false);
  const [selectImage, setSelectImage] = useState(false);

  useEffect(() => {
    selectImage === true
      ? props.selectPhoto(props.photo)
      : props.deselectPhoto(props.photo);
  }, [selectImage]);

  const handleChange = async (e) => {
    setSelectImage(!selectImage);
  };
  return (
    <div className="pexel-img-block">
      {showImage && (
        <ImagePlayer
          src={props.photo.src.landscape}
          onClose={() => setShowImage(false)}
        />
      )}
      <FullscreenIcon
        className="pexel-item-icon"
        // onClick={(e) => {
        //   props.selectPhoto(props.photo);
        //   setShowImage(true);
        // }}
        onClick={() => onPreviewPhoto(props.photo.src.large)}
      />
      <input
        type="checkbox"
        id={`pexel-img-${props.photo.id}`}
        value={selectImage}
        onChange={handleChange}
      />
      <label htmlFor={`pexel-img-${props.photo.id}`}>
        <img className="img-pexel" src={props.photo.src.tiny} />
      </label>
    </div>
  );
}
