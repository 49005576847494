import axios from "axios";

export default class {
  static saveAsIs = async (obj) => {
    let result = {
      data: null,
      error: null,
    };

    const fd = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        fd.append(key, value);
      }
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}/moodboards/save`, fd)
      .then((resp) => {
        if (resp.status === 200) {
          result.data = resp.data;
        }
      })
      .catch((err) => {
        result.error = err.response.data;
      });

    return result;
  };

  static add = async (obj) => {
    let result = {
      data: null,
      error: null,
    };

    const fd = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      fd.append(key, value);
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}/moodboards`, fd)
      .then((resp) => {
        if (resp.status === 200) {
          result.data = resp.data;
        }
      })
      .catch((err) => {
        result.error = err.response.data;
      });

    return result;
  };

  static update = async (id, obj) => {
    let result = {
      data: null,
      error: null,
    };

    const fd = new FormData();
    for (const [key, value] of Object.entries(obj)) {
      fd.append(key, value);
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}/moodboards/${id}`, fd)
      .then((resp) => {
        if (resp.status === 200) {
          result.data = resp.data;
        }
      })
      .catch((err) => {
        result.error = err.response.data;
      });

    return result;
  };

  static delete = async (id) => {
    let result = {
      data: null,
      error: null,
    };

    await axios
      .delete(`${process.env.REACT_APP_API_URL}/moodboards/${id}`)
      .then((resp) => {
        if (resp.status === 200) {
          result.data = resp.data;
        }
      })
      .catch((err) => {
        result.error = err.response.data;
      });

    return result;
  };

  static getStats = async (id) => {
    let result = {
      data: null,
      error: null,
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}/moodboards/stats/${id}`)
      .then((resp) => {
        if (resp.status === 200) {
          result.data = resp.data;
        }
      })
      .catch((err) => {
        result.error = err.response.data;
      });

    return result;
  };

  static saveMoodBoardsOrder = async (moodBoards) => {
    let result = {
      data: null,
      error: null,
    };

    const data = moodBoards.map((board) => ({
      _id: board._id,
      order: board.order,
    }));

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/moodboards/re-order`,
        { order: data }
      );
      if (res.status === 200) {
        result.data = res.data;
      }
    } catch (err) {
      result.error = err.response.data;
    }

    return result;
  };
}
