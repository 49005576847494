import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import "../css/moodboard-masonry.css";

export default function ImagePlayer(props) {
  const styles = {
    background: "#000000",
    width: "100%",
    height: "100%",
    overflow: props.data && props.data.type === "website" ? "auto" : "hidden",
    objectFit:
      props.data && props.data.type === "website" ? "contain" : "cover",
    cursor: "default",
  };

  return (
    <Rodal
      visible={true}
      onClose={() => props.onClose()}
      closeOnEsc={true}
      showMask={true}
      animation={"fade"}
      closeMaskOnClick={true}
      customStyles={styles}
    >
      <div className="row">
        <div className="col">
          <img className="img img-rodal" src={props.src}></img>
        </div>
      </div>
      {props.data && props.data.type === "website" && (
        <>
          <div className="row text-left mt-30">
            <div className="col">Title: {props.data.title}</div>
          </div>
          <div className="row text-left">
            <div className="col">
              Website:{" "}
              <a
                href={props.data.websiteUrl}
                target="_blank"
                className="btn btn-link"
              >
                {props.data.websiteUrl}
              </a>
            </div>
          </div>
          <div className="row text-left">
            <div className="col">Description: {props.data.description}</div>
          </div>
        </>
      )}
    </Rodal>
  );
}
