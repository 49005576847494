import "../css/moodboard-masonry.css";
import ItemBar from "./ItemBar/ItemBar";

function TileVimeo({ currentFile, view, ...props }) {
  const id = props.src.split("/").pop();
  let src = props.src.includes("player.vimeo")
    ? props.src
    : `https://player.vimeo.com/video/${id}`;
  return (
    <div className={`tile ${props.id}`}>
      <ItemBar
        currentFile={currentFile}
        viewItem={() => view(currentFile._id)}
        deleteItem={() => props.delete(currentFile._id)}
      />
      <iframe
        id="player"
        className="player"
        src={src}
        frameBorder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default TileVimeo;
