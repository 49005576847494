import React, { useState, useEffect } from 'react';

import axios from 'axios';
import imgSrc from '../images/default-video.png';

export default function ThumbnailVimeo(props) {

    const [imgUrl, setImgUrl] = useState(imgSrc);

    useEffect(() => {
        if (props.item.thumbnailUrl !== "null") {
            setImgUrl(props.item.thumbnailUrl);
        } else if (props.item.thumbnailUrl === "null") {
            const id = props.item.fileUrl.split('/').pop();
            axios.get(`https://vimeo.com/api/v2/video/${id}.json`)
                .then(resp => {
                    if (resp.status === 200) {
                        const data = resp.data;
                        setImgUrl(data[0].thumbnail_medium);
                    }
                });
        }
    }, []);

    const handlePlay = e => {
        e.preventDefault();
        if (props.setPlaying) {
            props.setPlaying(props.item);
        }
    }

    return <img 
        src={imgUrl} 
        className={`video-thumbnail ${props.item._id}`} 
        title={props.setPlaying && "Click to play this video."}
        onClick={handlePlay} 
        />
}