import React, { useState, useEffect } from "react";
import userService from "../services/user";
import session from "../services/session";

import { useNavigate } from "react-router-dom";
import { swalLoading } from "../utils/swal";
import Swal from "sweetalert2";

export default function Login(props) {
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [successMessage, setSuccessMessage] = useState("");

	useEffect(() => {
		session.clear();
	}, []);

	const handleLogin = async e => {
		e.preventDefault();
		if (!email || email.length === 0) {
			setErrorMessage(`Email is required.`);
			return;
		} else {
			if (
				/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) ===
				false
			) {
				setErrorMessage(`Please enter a valid email address.`);
				return;
			}
		}
		if (!password || password.length === 0) {
			setErrorMessage(`Password is required.`);
			return;
		}

		swalLoading();
		await userService
			.login({ email: email, password: password })
			.then(result => {
				if (result.error) {
					setErrorMessage(result.error);
					Swal.close();
					return;
				}

				if (result.data) {
					const data = result.data;
					setErrorMessage("");
					setSuccessMessage("Login successful! Redirecting...");
					session.set("user", data);
					if (props.forPopup === false) {
						props.onLogin();
						navigate(`/`);
					} else {
						document.location.reload();
					}
				}
			});
	};

	return (
		<div className="login-signup-container">
			<div
				className="container-fluid text-center div-login"
				style={{
					marginTop: "auto",
					width: "600px",
					marginBottom: "auto"
				}}
			>
				<div className="row">
					<div className="col-12 col-sm-12 col-md-12 infoBox">
						<h2 className="m-4">Login</h2>
						<br />
						<form onSubmit={handleLogin}>
							<div className="form-group">
								<input
									type="text"
									className="form-control width80"
									placeholder="Email"
									required="required"
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
							</div>
							<div className="form-group">
								<input
									type="password"
									className="form-control width80"
									placeholder="Password"
									required="required"
									value={password}
									onChange={e => setPassword(e.target.value)}
								/>
							</div>
							<br />
							{errorMessage && (
								<div className="alert alert-danger">
									{errorMessage}
								</div>
							)}

							{successMessage && (
								<div className="alert alert-success">
									{successMessage}
								</div>
							)}

							<button
								type="submit"
								className="btn btn-pink-1 pad40 mr-10 width80"
								onClick={e => handleLogin(e)}
							>
								Login
							</button>

							<br />
							<br />
							<div
								className="form-group"
								style={{ marginTop: "20px" }}
							>
								<p>
									Forgot your password?
									<a href="/fp" className="btn btn-link">
										Reset here
									</a>
								</p>
							</div>
							<div className="form-group">
								<p>
									Don't have an account?
									<a href="/signup" className="btn btn-link">
										Sign up here
									</a>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
