import ReactDOM from "react-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ReactInternetSpeedMeter } from "react-internet-meter";

import UploadFile from "./UploadFile/UploadFile";

import "./UploadingPopup.scss";

const UploadingPopup = ({ uploadingList = [] }) => {
  const [uploadTime, setUploadTime] = useState(null);
  const [timeLeft, setTimeleft] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  const [uploadSpeed, setUploadSpeed] = useState(null);

  useEffect(() => {
    if (uploadingList.length > 0) {
      const selectedFiles = Array.from(uploadingList);
      startUpload(selectedFiles);
    }
  }, [uploadingList]);

  useEffect(() => {
    if (uploadTime) {
      let remainingTime = uploadTime;
      const uploadInterval = setInterval(() => {
        remainingTime -= 1000;
        setTimeleft(remainingTime);
        const percentageCompleted =
          ((uploadTime - remainingTime) / uploadTime) * 100;

        const progressBar = document.getElementsByClassName("percentage")[0];

        progressBar.style.width = `${percentageCompleted}%`;
        if (remainingTime <= 0) {
          clearInterval(uploadInterval);
          remainingTime = 0;
          progressBar.style.width = "100%";
        }
      }, 1000);
    }
  }, [uploadTime]);

  const startUpload = (files) => {
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    const uploadDuration = Math.ceil(totalSize / uploadSpeed) * 1000; // Total upload time in milliseconds
    setUploadTime(uploadDuration);
    setTimeleft(uploadDuration);
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ${seconds} second${
        seconds !== 1 ? "s" : ""
      } left`;
    }
    return `${seconds} second${seconds !== 1 ? "s" : ""} left`;
  };

  const onClose = () => {
    const listUploading = document.querySelector(".list-uploading");
    setCollapsed(!collapsed);

    if (collapsed) {
      listUploading.style.display = "block";
    } else {
      listUploading.style.display = "none";
    }
  };

  if (uploadingList.length > 0) {
    return ReactDOM.createPortal(
      <div className="popup-container">
        <div className="popup-content">
          <div className="title-upload">
            <span>Uploads</span>
            {collapsed ? (
              <UpOutlined className="close-btn" onClick={onClose} />
            ) : (
              <DownOutlined className="close-btn" onClick={onClose} />
            )}
          </div>
          <div className="list-uploading">
            {uploadingList?.map((file) => (
              <UploadFile
                key={file.size}
                file={file}
                uploadSpeed={uploadSpeed}
              />
            ))}
          </div>
        </div>
        <div className="bottom-upload-popup">
          <div className="bottom-text">
            Uploading {uploadingList.length} items
          </div>
          <div className="bottom-text">
            {timeLeft !== null && formatTime(timeLeft)}
          </div>
          {<div className="percentage" />}
        </div>
      </div>,
      document.body
    );
  }
  return (
    <>
      <ReactInternetSpeedMeter
        txtSubHeading="Internet is too slow"
        outputType="alert"
        customClassName={"internet-meter"}
        pingInterval={5000} // Time in ms between ping
        thresholdUnit="byte" // "byte", "kilobyte", "megabyte", "gigabyte"
        threshold={100} // Minimum speed to be considered online
        imageUrl="https://upload.wikimedia.org/wikipedia/commons/3/3f/JPEG_example_flower.jpg"
        downloadSize="16886" // Image size in bytes
        callbackFunctionOnNetworkTest={(speed) => {
          setUploadSpeed(speed);
        }}
      />
    </>
  );
};

export default UploadingPopup;
