import React, { useState, useEffect } from "react";
import userService from "../services/user";
import session from "../services/session";
import { getPackage } from "../utils/utils";
import { swalInfo, swalLoading, swalPkgChange } from "../utils/swal";
import "../css/billing.css";
import StripeCheckout from "react-stripe-checkout";
import moment from "moment";
import Swal from "sweetalert2";
import Switch from "react-switch";

function Billing(props) {
  const [activePackage, setActivePackage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [choosenPackage, setChoosenPackage] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (document.getElementById("txt-project-title"))
      document.getElementById("txt-project-title").style.display = "none";

    userService.getPackage().then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }

      if (result.data) {
        setErrorMessage("");
        const obj = {
          ...result.data,
          ...getPackage(result.data.id),
        };
        setActivePackage(obj);
      }
    });
  }, []);

  const updatePackage = async (id) => {
    await userService.updatePackage(id).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }

      if (result.data) {
        setErrorMessage("");
        setSuccessMessage(`Your billing plan is updated successfully.`);
        setTimeout(async () => {
          const userId = session.get("user")._id;
          const result = await userService.get(userId);
          session.set("user", result.data);
          window.location.reload();
        }, 500);
      }
    });
  };

  const handleChoosePackage = (id) => {
    if (activePackage) {
      const activePackageId = parseInt(activePackage.id);
      if (id === activePackageId) {
        swalInfo(`You are already on the ${activePackage.name} plan.`);
      } else if (id < activePackageId) {
        swalPkgChange(
          `Downgrading!`,
          `Your current package status will be overwritten and new limits will be applied!`,
          `info`,
          async () => {
            if (id === 1) {
              await updatePackage(id);
              return;
            }

            setChoosenPackage(getPackage(id));
          }
        );
      } else if (id > activePackageId) {
        setChoosenPackage(getPackage(id));
        setTimeout(() => {
          const payButton = document.getElementsByClassName("btn-pink");
          payButton[0].click();
        }, 0);

        /*swalPkgChange(
                    `Upgrade`,
                    `Upgrade your plan`,
                    `info`,
                    () => setChoosenPackage(getPackage(id)));*/
      }
    }
  };

  const handleToken = async (token) => {
    await userService.checkout(token, choosenPackage).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }

      if (result.data) {
        updatePackage(choosenPackage.id);
      }
    });
  };

  const handleCancelSubscription = async () => {
    swalPkgChange(
      `Are you sure?`,
      `You will lose the current upgraded features.`,
      `info`,
      async () => {
        await userService.cancelSubscription().then((result) => {
          if (result.error) {
            setErrorMessage(result.error);
            return;
          }

          if (result.data) {
            updatePackage(1);
            setErrorMessage("");
            setSuccessMessage(`Your billing plan is updated successfully.`);
            setTimeout(async () => {
              const userId = session.get("user")._id;
              const result = await userService.get(userId);
              session.set("user", result.data);
              window.location.reload();
            }, 500);
          }
        });
      }
    );
  };

  return (
    activePackage && (
      <div className="container text-center billing" style={{ color: "white" }}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <h2 className="m-4">
              <a href="/">
                <i
                  className="fa fa-chevron-left btn-back"
                  title="Back to dashboard"
                ></i>
              </a>
              <h4>
                Your current billing plan:{" "}
                <span className="pkg-active">{activePackage.name}</span>
                {activePackage.id !== 1 && (
                  <span>
                    {" "}
                    Valid until{" "}
                    <span className="pkg-active">
                      {moment(activePackage.expires).format("DD MMM YYYY")}
                    </span>
                  </span>
                )}
              </h4>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            {session.get("user").package !== "1" && (
              <button
                className="btn btn-link"
                onClick={handleCancelSubscription}
              >
                Cancel your subscription
              </button>
            )}
          </div>
        </div>
        {errorMessage && (
          <div className="row pkgs-row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="alert alert-danger">{errorMessage}</div>
            </div>
          </div>
        )}
        {successMessage && (
          <div className="row pkgs-row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="alert alert-success">{successMessage}</div>
            </div>
          </div>
        )}
        <div className="row pkgs-row">
          <div className="col-12 col-sm-12 col-md-12">
            <label htmlFor="material-switch">
              <span className="pkg-duration">Monthly</span>
              <Switch
                checked={checked}
                onChange={(checked) => setChecked(checked)}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={30}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rGBa(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rGBa(0, 0, 0, 0.2)"
                height={20}
                width={48}
                className="react-switch"
                id="material-switch"
              />
              <span className="pkg-duration">Annually</span>
            </label>
          </div>
        </div>

        {checked === false && (
          <div className="pkg-flex">
            <div className="col-4 col-sm-4 col-md-4 pkg-pad">
              <div className="pkg">
                <span className="pkg-name">Free</span>
                <p className="pkg-price">$0</p>
                <span className="billing-info">Free forever.</span>
                <ul className="ul">
                  <li>
                    {" "}
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    5 Projects
                  </li>
                  <hr className="hr" />
                  <li>
                    {" "}
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    15 Project Items
                  </li>
                  <hr className="hr" />
                  <li>
                    {" "}
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    100MB Project Storage
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      style={{
                        color: "#5B5D62",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    <p className="nonfeature">Money Back Guarantee</p>
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      style={{
                        color: "#5B5D62",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    <p className="nonfeature">Priority Support</p>
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      style={{
                        color: "#5B5D62",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    <p className="nonfeature">Generative AI</p>
                  </li>
                  <hr className="hr" />
                  <li style={{ display: "flex", gap: "4px" }}>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      style={{
                        color: "#5B5D62",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    <p className="nonfeature" style={{ marginBottom: 0 }}>
                      Unlimited Searches to Commercial Library
                    </p>
                  </li>
                  <hr className="hr" />
                </ul>
                <button
                  className="btn btn-outline-custom btn-pkg-choose"
                  onClick={(e) => handleChoosePackage(1)}
                >
                  Free Plan
                </button>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 pkg-pad">
              <div className="pkg">
                <span className="pkg-name">Plus</span>
                <p className="pkg-price">$9</p>
                <span className="billing-info">Per month, billed monthly.</span>
                <ul className="ul">
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    15 Projects
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Unlimited Project Items
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    5GB Project Storage
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Money Back Guarantee
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Priority Support
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Generative AI
                  </li>
                  <hr className="hr" />
                  <li style={{ display: "flex", gap: "4px" }}>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Unlimited Searches to Commercial Library
                  </li>
                  <hr className="hr" />
                </ul>
                <button
                  className="btn btn-outline-custom btn-pkg-choose"
                  onClick={(e) => handleChoosePackage(2)}
                >
                  Choose <strong>Plus Monthly</strong>
                </button>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 pkg-pad">
              <div className="pkg">
                <span className="pkg-name">Premium</span>
                <p className="pkg-price">$15</p>
                <span className="billing-info">Per month, billed monthly.</span>
                <ul className="ul">
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Unlimited Projects
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Unlimited Project Items
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    20GB Project Storage
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Money Back Guarantee
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Priority Support
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Generative AI
                  </li>
                  <hr className="hr" />
                  <li style={{ display: "flex", gap: "4px" }}>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    <span>Unlimited Searches to Commercial Library</span>
                  </li>
                  <hr className="hr" />
                </ul>
                <button
                  className="btn btn-outline-custom btn-pkg-choose"
                  onClick={(e) => handleChoosePackage(3)}
                >
                  Choose <strong>Premium Monthly</strong>
                </button>
              </div>
            </div>
          </div>
        )}
        {checked === true && (
          <div className="pkg-flex">
            <div className="col-4 col-sm-4 col-md-4 pkg-pad">
              <div className="pkg">
                <span className="pkg-name">Free</span>
                <p className="pkg-price">$0</p>
                <span className="billing-info">Free forever.</span>
                <ul className="ul">
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    5 Projects
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    15 Project Items
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    100MB Project Storage
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      style={{
                        color: "#5B5D62",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    <p className="nonfeature">Money Back Guarantee</p>
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-times"
                      aria-hidden="true"
                      style={{
                        color: "#5B5D62",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    <p className="nonfeature">Priority Support</p>
                  </li>
                  <hr className="hr" />
                </ul>
                <button
                  className="btn btn-outline-custom btn-pkg-choose"
                  onClick={(e) => handleChoosePackage(1)}
                >
                  Free Plan
                </button>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 pkg-pad">
              <div className="pkg">
                <span className="pkg-name">Plus</span>
                <p className="pkg-price">$99</p>
                <span className="billing-info">Per year, billed annually.</span>
                <ul className="ul">
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    15 Projects
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Unlimited Project Items
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    5GB Project Storage
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Money Back Guarantee
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Priority Support
                  </li>
                  <hr className="hr" />
                </ul>
                <button
                  className="btn btn-outline-custom btn-pkg-choose"
                  onClick={(e) => handleChoosePackage(4)}
                >
                  Choose <strong>Plus Annually</strong>
                </button>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 pkg-pad">
              <div className="pkg">
                <span className="pkg-name">Premium</span>
                <p className="pkg-price">$149</p>
                <span className="billing-info">Per year, billed annually.</span>
                <ul className="ul">
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Unlimited Projects
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Unlimited Project Items
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    20GB Project Storage
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Money Back Guarantee
                  </li>
                  <hr className="hr" />
                  <li>
                    <i
                      class="fa fa-check"
                      aria-hidden="true"
                      style={{
                        color: "#3444D8",
                        padding: "7px",
                      }}
                    ></i>{" "}
                    Priority Support
                  </li>
                  <hr className="hr" />
                </ul>
                <button
                  className="btn btn-outline-custom btn-pkg-choose"
                  onClick={(e) => handleChoosePackage(5)}
                >
                  Choose <strong>Premium Annually</strong>
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="row pkgs-row">
          <div className="col-12 col-sm-12 col-md-12 stripe-checkout">
            {choosenPackage && (
              <StripeCheckout
                token={handleToken}
                stripeKey={process.env.REACT_APP_STRIPE_PRIVATE_KEY}
                allowRemeMBerMe={false}
                shippingAddress={false}
                billingAddress={false}
                name={process.env.REACT_APP_TITLE}
                email={session.get("user").email}
                amount={choosenPackage.price * 100}
                currency="USD"
                opened={() => Swal.close()}
                closed={() => setChoosenPackage(null)}
              >
                <button
                  className="btn btn-lg btn-pink"
                  style={{ opacity: "0" }}
                  onClick={() => swalLoading()}
                >
                  <i
                    className="fab fa-cc-stripe"
                    style={{ marginRight: "5px" }}
                  ></i>
                  Pay ${choosenPackage.price} with Stripe
                </button>
              </StripeCheckout>
            )}
          </div>
        </div>
      </div>
    )
  );
}

export default Billing;
