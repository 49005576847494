import React, {useState, useEffect} from 'react';

import srcUrl from '../images/default-image.jpg';

export default function ThumbnailImage(props) {

    const [imgUrl, setImgUrl] = useState(srcUrl);

    useEffect(() => {
        // setImgUrl(props.item.fileUrl);
        if(props.item.thumbnailUrl){
            setImgUrl(props.item.thumbnailUrl);
        } else{
            setImgUrl(props.item.fileUrl);
        }
       
    }, []);

    const handlePlay = e => {
        e.preventDefault();
        if(props.setPlaying) {
            props.setPlaying(props.item);
        }
    }

    return (
        <img src={imgUrl} className={`video-thumbnail ${props.item._id}`} title={props.setPlaying && "Click to see this image."} onClick={handlePlay} />
    );
}