import React, { useState, useEffect, useMemo } from "react";
import "../css/moodboard-media-view.css";
import Img from "react-cool-img";
import ReactJWPlayer from "react-jw-player";
import ContentComments from "./ContentComments";
import DraggableThumbnail from "./DraggableThumbnail";
import moodBoardService from "../services/moodboard";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
} from "@dnd-kit/core";
import {
  sortableKeyboardCoordinates,
  rectSortingStrategy,
  SortableContext,
  arrayMove as dndKitArrayMove,
} from "@dnd-kit/sortable";
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from "@dnd-kit/modifiers";
import { swalError } from "../utils/swal";
import Swal from "sweetalert2";
import YoutubePlayer from "./YoutubePlayer/YoutubePlayer";
import IspotPlayer from "./IspotPlayer/IspotPlayer";
import { InstagramEmbed } from "react-social-media-embed";
import ReactPlayer from "react-player";

export default function MoodboardListView(props) {
  const [playingUrl, setPlayingUrl] = useState(null);
  const [playingItem, setPlayingItem] = useState(null);
  const [selectedMoodboard, setSelectedMoodboard] = useState(null);
  const [moodBoards, setMoodBoards] = useState([]);
  const [updateOrder, setUpdateOrder] = useState(false);
  const [rerender, setRerender] = useState(true);

  const itemIds = useMemo(
    () => moodBoards.map((board) => board._id),
    [moodBoards]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { setNodeRef } = useDroppable({ id: "droppable" });

  const arrayMove = (array, oldIndex, newIndex) => {
    return dndKitArrayMove(array, oldIndex, newIndex);
  };

  const handleDragOver = ({ over }) => {
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const overContainer = over.data.current?.sortable.containerId;

    if (!overContainer) {
      return;
    }
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      return;
    }

    const activeIndex = active.data.current.sortable.index;
    const overIndex = over.data.current?.sortable.index || 0;

    const tmpArr = arrayMove([...moodBoards], activeIndex, overIndex).map(
      (item, index) => ({
        ...item,
        order: index + 1,
      })
    );
    setMoodBoards(tmpArr);
    setUpdateOrder(true);
  };

  useEffect(() => {
    setMoodBoards(props.moodboards);
    if (!!props.moodboards.length) {
      setPlaying(props.moodboards[0]);
    }
  }, [props.moodboards]);

  useEffect(() => {
    if (updateOrder) {
      const updateOrder = async () => {
        try {
          await moodBoardService.saveMoodBoardsOrder(moodBoards);
        } catch (e) {
          swalError(e.error);
          Swal.close();
        }
      };

      updateOrder();
    }
  }, [moodBoards, updateOrder]);

  useEffect(() => {
    setRerender(true);
  }, [playingUrl]);

  useEffect(() => {
    if (rerender) {
      setRerender(false);
    }
  }, [rerender]);

  const setPlaying = (item) => {
    setPlayingUrl(null);
    setPlayingItem(null);
    if (item.type === "video") {
      if (
        item.fileUrl.includes("vimeo") &&
        !item.fileUrl.includes("player.vimeo")
      ) {
        const id = item.fileUrl.split("/").pop();
        setPlayingUrl(`https://player.vimeo.com/video/${id}`);
      } else if (
        (item.fileUrl.includes("youtube") ||
          item.fileUrl.includes("youtu.be")) &&
        !item.fileUrl.includes("/shorts")
      ) {
        const id =
          new URL(item.fileUrl).searchParams.get("v") ||
          new URL(item.fileUrl).pathname.split("/")[1];
        setPlayingUrl(`https://www.youtube.com/embed/${id}`);
      } else {
        setPlayingUrl(item.fileUrl);
      }
    } else {
      setPlayingUrl(item.fileUrl);
    }

    setPlayingItem(item);
    setSelectedMoodboard(item._id);
  };

  const renderThumbnails = () => {
    if (moodBoards.length > 0) {
      return (
        <SortableContext
          id="sortable"
          items={itemIds}
          strategy={rectSortingStrategy}
        >
          <div ref={setNodeRef}>
            {moodBoards.map((item, i) => (
              <DraggableThumbnail
                key={item._id}
                index={i}
                item={item}
                setPlaying={setPlaying}
              />
            ))}
          </div>
        </SortableContext>
      );
    } else {
      return (
        <div className="container-fluid text-center not-found">
          No moodboards found.
        </div>
      );
    }
  };

  const renderDisplaying = () => {
    if (playingItem) {
      const type = playingItem.type;
      if (type === "video") {
        if (playingUrl.includes("ispot.tv")) {
          return (
            <IspotPlayer url={playingUrl} style={{ paddingBottom: "4%" }} />
          );
        } else if (playingUrl.includes("youtube.com/shorts")) {
          return <YoutubePlayer url={playingUrl} style={{ height: "100%" }} />;
        } else if (
          playingUrl.includes("youtube") ||
          playingUrl.includes("vimeo")
        ) {
          return (
            <iframe
              id="player"
              className="player"
              src={playingUrl}
              key={playingUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          );
        } else if (playingItem.source === "instagram") {
          return <InstagramEmbed key={playingUrl} url={playingUrl} />;
        } else if (!rerender) {
          return (
            <ReactPlayer
              className="player-react"
              url={playingUrl}
              controls={true}
            />
          );
        }
        // return <video className="player" controls key={playingUrl}>
        //     <source src={playingUrl} type="video/mp4" />
        // </video>
      } else if (type === "docs") {
        return (
          <iframe
            id="player"
            className="player"
            src={playingUrl}
            key={playingUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      } else if (type === "audio") {
        return (
          <div style={{ margin: "20px", marginTop: "100px" }} key={playingUrl}>
            <audio controls className="audio-player" src={playingUrl}></audio>
          </div>
        );
      } else if (type === "image") {
        //return <img className="image-player" key={playingUrl} src={playingUrl} />
        return (
          <Img className="image-player" key={playingUrl} src={playingUrl} />
        );
      } else if (type === "website") {
        return (
          <>
            <Img
              className="image-player mb-30"
              key={playingUrl}
              src={playingUrl}
            />
            Title: {playingItem.title}
            <br />
            Website:{" "}
            <a
              href={playingItem.websiteUrl}
              target="_blank"
              className="btn btn-link"
            >
              {playingItem.websiteUrl}
            </a>
            <br />
            Description: {playingItem.description}
          </>
        );
      } else if (type === "pexels-video") {
        if (!rerender) {
          return (
            <ReactJWPlayer
              className="player"
              playerId={playingUrl.toString()}
              playerScript="https://cdn.jwplayer.com/libraries/s7lNp89j.js"
              file={playingUrl}
              image={playingItem.thumbnailUrl}
            />
          );
        }
        // return <video className="player" key={playingUrl} controls>
        //     <source src={playingUrl} type="video/mp4" />
        // </video>
      }
    } else {
      return (
        <div className="container-fluid text-center not-found">
          No moodboards found.
        </div>
      );
    }
  };

  return (
    <div className="container-fluid text-center mt-20">
      <div className="row">
        <div className="col-2 col-sm-1 col-md-1 text-center thumbs thumbnails-col">
          <DndContext
            sensors={sensors}
            onDragStart={() => setUpdateOrder(false)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
          >
            {renderThumbnails()}
          </DndContext>
        </div>
        <div class="second-view-wrapper">
          <div
            className={`col-10 col-sm-11 col-md-8 text-left media-view-col ${
              playingUrl?.includes("ispot.tv") ? "media-view-col-ispot" : ""
            }`}
          >
            {renderDisplaying()}
          </div>
          <div
            className="col-12 col-sm-12 col-md-3 text-left comment-second-view"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            {selectedMoodboard && <ContentComments id={selectedMoodboard} />}
          </div>
        </div>
      </div>
    </div>
  );
}
