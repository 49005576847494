import React, { useState, useEffect } from "react";
import session from "../services/session";
import moment from "moment";
import { swalError, swalInfo, swalUploading, swalSuccess } from "../utils/swal";
import projectService from "../services/project";
import moodboardService from "../services/moodboard";
import Swal from "sweetalert2";
import "../css/project.css";
import "../css/tabs.css";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import ViewModuleOutlinedIcon from "@material-ui/icons/ViewModuleOutlined";
import ViewQuiltOutlinedIcon from "@material-ui/icons/ViewQuiltOutlined";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import ShareIcon from "@material-ui/icons/Share";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import MdAdd from "@material-ui/icons/Add";
import MdClose from "@material-ui/icons/Clear";
import MoodboardMasonry from "./MoodboardMasonry";
import MoodboardTiles from "./MoodboardTiles";
import Pexels from "./Pexels";
import Embed from "./Embed";
import Collaborate from "./Collaborate";
import MoodboardListView from "./MoodboardListView";
import { useNavigate } from "react-router-dom";

export default function View(props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showPexels, setShowPexels] = useState(false);
  const [showEmbed, setShowEmbed] = useState(false);
  const [showCollaborate, setShowCollaborate] = useState(false);
  const [pexelsPhotos, setPexelsPhotos] = useState([]);
  const [pexelsVideos, setPexelsVideos] = useState([]);
  const [embedUrls, setEmbedUrls] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [displayType, setDisplayType] = useState("masonry");
  const [title, setTitle] = useState("");
  const [project, setProject] = useState(null);

  useEffect(() => {
    reloadProject();
  }, []);

  const reloadProject = async () => {
    let id = window.location.href.split("/").pop();
    if (id && id.length === 24) {
      await projectService.get(id).then((result) => {
        if (result.error) {
          swalError(result.error);
          return;
        }

        if (result.data.length === 1) {
          setProject(result.data[0]);
        } else {
          swalInfo(`Project not found with project ID.`);
          return;
        }
      });
    } else {
      swalError(`Invalid project ID detected in the URL.`);
      return;
    }
  };

  const saveMoodboards = async (arr, project) => {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < arr.length; i++) {
        const k = arr[i];
        await moodboardService.add({
          order: k.order,
          title: k.title,
          description: k.description,
          type: k.type,
          projectId: project._id,
          fileUrl: k.url,
          fileName: null,
          fileSize: 0,
          fileKey: null,
          userId: session.get("user").id,
          date: moment().format(),
        });
      }
      resolve(true);
    });
  };

  const uploadMoodboards = async (arr, project) => {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < arr.length; i++) {
        const k = arr[i];
        await moodboardService.add({
          order: k.order,
          title: k.title,
          description: k.description,
          type: k.type,
          projectId: project._id,
          file: k.file,
          userId: session.get("user").id,
          date: moment().format(),
        });
      }
      resolve(true);
    });
  };

  const onSelectPexels = (data) => {
    let t = Array.from(pexelsPhotos) || [];
    data.photos.forEach((url) => t.push(url));
    setPexelsPhotos(t);

    let tt = Array.from(pexelsVideos) || [];
    data.videos.forEach((url) => tt.push(url));
    setPexelsVideos(tt);
  };

  const onSelectEmbed = (url) => {
    let t = Array.from(embedUrls) || [];
    t.push(url);
    setEmbedUrls(t);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!title || title.length === 0) {
      swalInfo(`Please provide project title.`);
      return;
    }

    let moodboard = [];
    let moodboardOrder = 1;

    pexelsPhotos.forEach((url) => {
      moodboard.push({
        type: "image",
        url: url,
        title: ``,
        description: ``,
        order: moodboardOrder,
      });
      moodboardOrder += 1;
    });

    pexelsVideos.forEach((url) => {
      moodboard.push({
        type: "video",
        url: url,
        title: ``,
        description: ``,
        order: moodboardOrder,
      });
      moodboardOrder += 1;
    });

    embedUrls.forEach((url) => {
      moodboard.push({
        type: "video",
        url: url,
        title: ``,
        description: ``,
        order: moodboardOrder,
      });
      moodboardOrder += 1;
    });

    let moodboardFiles = [];
    const files = document.getElementById("txt-selected-files").files;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      moodboardFiles.push({
        type: file.type,
        file: file,
        title: file.name,
        description: ``,
        order: moodboardOrder,
      });
      moodboardOrder += 1;
    }

    if (!moodboard || moodboard.length === 0) {
      swalInfo(`Please provide atleast one moodboard item.`);
      return;
    }

    const obj = {
      title: title,
      description: ``,
      date: moment().format(),
      userId: session.get("user").id,
      // file: logo
    };

    swalUploading();
    await projectService.add(obj).then(async (result) => {
      if (result.error) {
        swalError(result.error);
        Swal.close();
        return;
      }

      if (result.data) {
        await saveMoodboards(moodboard, result.data);
        await uploadMoodboards(moodboardFiles, result.data);
        Swal.close();
        swalSuccess(`Project created successfully!`);
        navigate(`/projects/${result.data._id}`);
      }
    });
  };

  const changeView = (e, type) => {
    e.preventDefault();
    document
      .querySelectorAll(".display-options svg")
      .forEach((i) => i.classList.remove("text-white"));
    document
      .querySelector(`.display-options .${type}`)
      .classList.add("text-white");
    setDisplayType(type);
  };

  return (
    <div className="container-fluid text-center" style={{ padding: "0px" }}>
      {showPexels && (
        <Pexels
          onClose={() => setShowPexels(false)}
          onSelect={onSelectPexels}
        />
      )}
      {showEmbed && (
        <Embed onClose={() => setShowEmbed(false)} onSelect={onSelectEmbed} />
      )}
      {showCollaborate && project && (
        <Collaborate
          onClose={() => setShowCollaborate(false)}
          projectId={project._id}
          project={project}
        />
      )}

      <div className="display-options">
        <ViewQuiltOutlinedIcon
          className="material-svg masonry text-white"
          onClick={(e) => changeView(e, "masonry")}
        />
        <ViewModuleOutlinedIcon
          className="material-svg grid"
          onClick={(e) => changeView(e, "grid")}
        />
        <ViewListOutlinedIcon
          className="material-svg list"
          onClick={(e) => changeView(e, "list")}
        />
      </div>

      <div className="share-options">
        <ShareIcon
          className="material-svg"
          onClick={() => setShowCollaborate(true)}
        />
      </div>

      {project && project.moodboards && displayType === "grid" && (
        <div className="moodboard-tiles text-center">
          <MoodboardTiles moodboards={project.moodboards} />
        </div>
      )}
      {project && project.moodboards && displayType === "masonry" && (
        <MoodboardMasonry moodboards={project.moodboards} />
      )}
      {project && project.moodboards && displayType === "list" && (
        <MoodboardListView moodboards={project.moodboards} />
      )}

      <input
        id="txt-selected-files"
        type="file"
        style={{ display: "none" }}
        accept=".jpg,.jpeg,.png,.mp4.mp3"
        multiple
      />
      <FloatingMenu
        slideSpeed={500}
        direction="up"
        spacing={8}
        isOpen={isOpen}
        style={{ position: "fixed", bottom: "5vh", right: "2vh" }}
      >
        <MainButton
          iconResting={<MdAdd />}
          iconActive={<MdClose />}
          backgroundColor="black"
          onClick={() => setIsOpen(!isOpen)}
          size={45}
          className="btn-floating-main"
          title="Add photos, videos and files."
        />
        <ChildButton
          icon={<VideoLibraryIcon />}
          size={40}
          className="btn-floating-child"
          onClick={() => setShowEmbed(true)}
          title="Embed YouTube/Vimeo videos."
        />
        <ChildButton
          icon={<InsertDriveFileIcon />}
          className="btn-floating-child"
          size={40}
          onClick={() => document.getElementById("txt-selected-files").click()}
          title="Upload audio/photos files from your computer."
        />
        <ChildButton
          icon={<AddPhotoAlternateIcon />}
          className="btn-floating-child"
          size={40}
          onClick={() => setShowPexels(true)}
          title="Add photos/videos directly from Pexels."
        />
      </FloatingMenu>
    </div>
  );
}
