import {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useMemo,
} from "react";
import { Modal, Select, Button, Input } from "antd";
import moment from "moment";
import axios from "axios";

import projectService from "../../services/project";
import moodboardService from "../../services/moodboard";
import session from "../../services/session";

import "./AddToBoardModal.scss";

const { Option } = Select;

const AddToBoardModal = forwardRef(({ listAssets }, ref) => {
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [listBoard, setListBoard] = useState([]);
  const [listTab, setListTab] = useState([]);
  const [chosenProjectId, setChosenProjectId] = useState();
  const [chosenTabId, setChosenTabId] = useState();
  const [loading, setloading] = useState(false);
  const [newProjectName, setNewProjectName] = useState();

  useImperativeHandle(ref, () => ({
    closeModal: () => {
      setShowMoveModal(false);
    },
    openModal: () => {
      setShowMoveModal(true);
    },
  }));

  useEffect(() => {
    if (showMoveModal) {
      projectService.getAll().then(({ data: allMoodboard }) => {
        setListBoard(allMoodboard);
      });
    }
  }, [showMoveModal]);

  const isDisabledAddBtn = useMemo(() => {
    if (chosenProjectId === "new" && !newProjectName) {
      return true;
    }
    if (chosenProjectId !== "new" && !chosenTabId) {
      return true;
    }
    return false;
  }, [chosenProjectId, newProjectName, chosenTabId]);

  const onChooseBoard = (boardId) => {
    setChosenProjectId(boardId);
    setChosenTabId(null);
    setListTab([]);
    if (boardId === "new") {
      return;
    }
    try {
      projectService.get(boardId).then(({ data: allTab }) => {
        setListTab(allTab[0].tabs);
        setChosenTabId(allTab[0].tabs[0]._id);
      });
    } catch (err) {
      console.log("Error while get tab", err);
    }
  };

  const onAddToBoard = async () => {
    if (chosenProjectId === "new") {
      try {
        const {
          data: { _id: projectId },
        } = await axios.post(`${process.env.REACT_APP_API_URL}/projects`, {
          title: newProjectName,
          userId: session.get("user").id,
          date: moment().format(),
        });
        const { data: projectDetail } = await axios.get(
          `${process.env.REACT_APP_API_URL}/projects/${projectId}`
        );

        const body = {
          projectId: projectId,
          size: 0,
          tabId: projectDetail[0].tabs[0]._id,
          fileUrl: listAssets[0].fileUrl,
          thumbnailUrl: listAssets[0].thumbnail,
          fileName: null,
          fileSize: 0,
          fileKey: null,
          userId: session.get("user").id,
          date: moment().format(),
          type: listAssets[0].type,
          order: 1,
        };

        await moodboardService.add(body);
      } catch (e) {
        console.log("error", e);
      }
    } else {
      try {
        const body = {
          projectId: chosenProjectId,
          size: 0,
          tabId: chosenTabId,
          fileUrl: listAssets[0].fileUrl,
          thumbnailUrl: listAssets[0].thumbnail,
          fileName: null,
          fileSize: 0,
          fileKey: null,
          userId: session.get("user").id,
          date: moment().format(),
          type: listAssets[0].type,
        };

        await moodboardService.add(body);
      } catch (e) {
        console.log("error", e);
      }
    }
  };

  const onAdding = async () => {
    setloading(true);
    try {
      await onAddToBoard();
      setShowMoveModal(false);
    } catch (err) {
      console.log("Error while adding", err);
    } finally {
      setloading(false);
    }
  };

  const cleanUp = () => {
    setListTab([]);
    setChosenTabId(null);
  };

  return (
    <Modal
      title={""}
      open={showMoveModal}
      wrapClassName="add-board-modal-wrapper"
      mask={false}
      footer={null}
      onCancel={() => setShowMoveModal(false)}
      className="move-modal"
      destroyOnClose
      afterClose={cleanUp}
    >
      <div>Choose where to add asset(s) to</div>
      <Select placeholder="Select board" onChange={onChooseBoard}>
        {listBoard.map((boardOption) => (
          <Option key={boardOption._id} value={boardOption._id}>
            {boardOption.title?.length > 0 ? boardOption.title : "Untitled"}
          </Option>
        ))}
        <Option key="new" value="new">
          Create a new board
        </Option>
      </Select>
      {chosenProjectId === "new" && (
        <Input
          placeholder="Input new board name"
          onChange={(e) => setNewProjectName(e.target.value)}
        ></Input>
      )}
      {listTab.length > 0 && (
        <Select
          placeholder="Select tab"
          value={chosenTabId}
          onChange={(id) => setChosenTabId(id)}
        >
          {listTab.map((tabOption) => (
            <Option key={tabOption._id} value={tabOption._id}>
              {tabOption.title}
            </Option>
          ))}
        </Select>
      )}
      <Button
        type="primary"
        disabled={isDisabledAddBtn}
        loading={loading}
        onClick={onAdding}
        className="confirm-btn"
      >
        Add asset(s)
      </Button>
    </Modal>
  );
});

export default AddToBoardModal;
