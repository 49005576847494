import React, { useState, useEffect } from "react";
import session from "../services/session";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { withRouter } from "react-router";
import {
	swalError,
	swalName,
	swalSuccess,
	swalPassword,
	swalPicture,
	swalPosition,
	swalLoading,
	swalUploading
} from "../utils/swal";
import userService from "../services/user";
import { getPackage } from "../utils/utils";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.min.css";
import "../css/swal.css";
import "../App.css";
import projectService from "../services/project";
import EmailIcon from "@material-ui/icons/Email";
import FeedbackIcon from "@material-ui/icons/Feedback";
import PaymentIcon from "@material-ui/icons/Payment";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LockIcon from "@material-ui/icons/Lock";
import BusinessIcon from "@material-ui/icons/Business";
import ImageIcon from "@material-ui/icons/Image";
import VideoPlayer from "./VideoPlayer";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import SettingsIcon from "@material-ui/icons/Settings";

function Header(props) {
	const navigate = useNavigate();
	const [showTitle, setShowTitle] = useState(false);
	const [showDemo, setDemo] = useState(false);

	// useEffect(() => {
	//     if(window.location.href.includes('/n/')) {
	//         let id = window.location.href.split('/').pop();
	//         if(id.length === 24) {
	//             setShowTitle(true);
	//         }
	//     }
	// }, [window.location]);

	const handleLogout = e => {
		e.preventDefault();
		props.onLogout();

		setTimeout(() => {
			session.clear();
			navigate("/login");
		}, 500);
	};

	const emailFilmboard = e => {
		e.preventDefault();
		window.location.href = `mailto:support@filmboard.io`;
	};

	const handleUpdateName = e => {
		e.preventDefault();
		swalName(session.get("user").fullname, async newName => {
			await userService.updateName(newName).then(result => {
				if (result.error) {
					swalError(result.error);
					return;
				}

				if (result.data) {
					swalSuccess(`Name updated successfully!`);
					document.getElementById("username-header").innerText =
						newName;
					session.set("user", result.data);
				}
			});
		});
	};

	const handleUpdatePassword = e => {
		e.preventDefault();
		swalPassword(async newPassword => {
			await userService.updatePassword(newPassword).then(result => {
				if (result.error) {
					swalError(result.error);
					return;
				}

				if (result.data) {
					swalSuccess(`Password updated successfully!`);
					session.set("user", result.data);
				}
			});
		});
	};

	const handleUpdatePosition = e => {
		e.preventDefault();
		swalPosition(session.get("user").position || ``, async position => {
			await userService.updatePosition(position).then(result => {
				if (result.error) {
					swalError(result.error);
					return;
				}

				if (result.data) {
					swalSuccess(`Position updated successfully!`);
					session.set("user", result.data);
				}
			});
		});
	};

	const handleUpdatePicture = e => {
		e.preventDefault();
		swalPicture(async file => {
			// const str = await getBaseUrl(file);
			swalUploading();
			await userService
				.updatePicture({
					file: file
				})
				.then(result => {
					if (result.error) {
						swalError(result.error);
						return;
					}

					if (result.data) {
						swalSuccess(`Picture updated successfully!`);
						document.getElementById("img-circle-header").src = `${
							result.data.picture
						}?${new Date().getTime()}`;
						session.set("user", result.data);
						Swal.close();
					}
				});
		});
	};

	const resetTitle = e => {
		document.getElementById("txt-project-title").value = " ";
	};

	const getBaseUrl = file =>
		new Promise((resolve, reject) => {
			try {
				var reader = new FileReader();
				var baseString;
				reader.onloadend = function () {
					baseString = reader.result;
					resolve(baseString);
				};
				reader.readAsDataURL(file);
			} catch (e) {
				reject(e);
			}
		});

	const getPackageName = () => {
		const pkgId = session.get("user").package;
		return getPackage(pkgId).name;
	};

	const updateTitle = e => {
		e.preventDefault();
		let title = document.getElementById("txt-project-title").value;
		let id = window.location.href.split("/").pop();
		if (id.length === 24 && title.length > 0) {
			projectService.updateTitle(id, e.target.value);
		}
	};

	return (
		<div>
			<nav
				className="navbar navbar-expand-lg navbar-dark "
				style={{ backgroundColor: "#0A0A0A" }}
			>
				<a
					className="navbar-brand"
					href="/"
					style={{ color: "rgb(56,78,210)", fontWeight: "500" }}
				>
					<img className="img-logo" src="../logo.png" />
				</a>
				<button
					className="navbar-toggler"
					type="button"
					data-toggle="collapse"
					data-target="#navbarSupportedContent"
					aria-controls="navbarSupportedContent"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</button>

				<input
					type="text"
					className="form-control txt-project-title"
					id="txt-project-title"
					placeholder="Project Title"
					onBlur={updateTitle}
					style={{ display: "none" }}
				/>

				<div
					className="collapse navbar-collapse"
					id="navbarSupportedContent"
				>
					<div className="navbar-nav ml-auto">
						{props.loggedIn !== true && (
							<ul className="navbar-nav pull-right">
								<li className="nav-item">
									<NavLink
										className="nav-link"
										to="/login"
										onClick={resetTitle}
									>
										Login
									</NavLink>
								</li>
								<li className="nav-item">
									<NavLink
										className="nav-link"
										to="/signup"
										onClick={resetTitle}
									>
										Signup
									</NavLink>
								</li>
							</ul>
						)}
						{props.loggedIn === true && (
							<div className="navbar-nav pull-right">
								{showDemo && (
									<VideoPlayer
										onClose={() => setDemo(false)}
									/>
								)}
								{/*<button type="button" className="btn mt-0 btn-pink share-button demo-button" style={{borderColor: 'rgb(117,117,117)'}} onClick={e => setDemo(!showDemo)} ><PlayCircleOutlineIcon></PlayCircleOutlineIcon> Watch Demo</button>*/}
								<li className="nav-item dropdown">
									<a
										className="nav-link dropdown-toggle"
										href="#"
										id="navbarDropdownMenuLink"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
									>
										<img
											id="img-circle-header"
											className="img-circle-header"
											src={session.get("user").picture}
										/>
										<span id="username-header">
											{session.get("user") &&
												session.get("user").fullname}
										</span>
									</a>
									<div
										className="dropdown-menu dropdown-menu-right"
										aria-labelledby="navbarDropdownMenuLink"
									>
										<a href="/">
											<button className="dropdown-item">
												<ViewModuleIcon
													style={{
														marginRight: "10px"
													}}
													fontSize="small"
												></ViewModuleIcon>
												My projects
											</button>
										</a>
										<button
											className="dropdown-item"
											onClick={handleUpdateName}
										>
											<AccountBoxIcon
												style={{ marginRight: "10px" }}
												fontSize="small"
											></AccountBoxIcon>
											Update your name
										</button>
										<button
											className="dropdown-item"
											onClick={handleUpdatePassword}
										>
											<LockIcon
												style={{ marginRight: "10px" }}
												fontSize="small"
											></LockIcon>
											Update password
										</button>
										<button
											className="dropdown-item"
											onClick={handleUpdatePosition}
										>
											<BusinessIcon
												style={{ marginRight: "10px" }}
												fontSize="small"
											></BusinessIcon>
											Update company position
										</button>
										<button
											className="dropdown-item"
											onClick={handleUpdatePicture}
										>
											<ImageIcon
												style={{ marginRight: "10px" }}
												fontSize="small"
											></ImageIcon>
											Update your picture
										</button>
										<a href="/billing">
											<button className="dropdown-item">
												<PaymentIcon
													style={{
														marginRight: "10px"
													}}
													fontSize="small"
												></PaymentIcon>
												Billing ({getPackageName()})
											</button>
										</a>
										<a href="/setting">
											<button className="dropdown-item">
												<SettingsIcon
													style={{
														marginRight: "10px"
													}}
													fontSize="small"
												></SettingsIcon>
												Settings
											</button>
										</a>
										{/*<button
											className="dropdown-item"
											onClick={e => navigate("/feedback")}
										>
											<FeedbackIcon
												style={{ marginRight: "10px" }}
												fontSize="small"
											></FeedbackIcon>
											Feedback
										</button>*/}
										<button
											className="dropdown-item"
											onClick={e => emailFilmboard(e)}
										>
											<EmailIcon
												style={{ marginRight: "10px" }}
												fontSize="small"
											></EmailIcon>
											Email us
										</button>
										<button
											className="dropdown-item"
											id="btn-logout"
											onClick={e => handleLogout(e)}
										>
											<i
												className="fa fa-power-off"
												style={{ marginRight: "10px" }}
											></i>
											Logout
										</button>
									</div>
								</li>
							</div>
						)}
					</div>
				</div>
			</nav>
		</div>
	);
}

export default Header;
