import React, {useState, useEffect} from "react";
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import '../css/moodboard-masonry.css';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {Menu, MenuItem} from "@material-ui/core";
import Img from 'react-cool-img';
import ImagePlayer from './ImagePlayer';

function TileWebsite(props) {
    const [showImage, setShowImage] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [imageSrc, setImageSrc] = useState(props.src)
    const open = Boolean(anchorEl);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useEffect(() => {
        if (props.thumbnail) {
            setImageSrc(props.thumbnail)
        }
    });

    return <div className={`tile ${props.id}`}>
        <div className="tile-options">
            <MoreHorizIcon onClick={handleClick}/>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={e => {
                    setAnchorEl(null);
                    props.view(props.id);
                }}>
                    View Comments
                </MenuItem>
                <MenuItem onClick={e => {
                    setAnchorEl(null);
                    props.delete(props.id);
                }}>
                    Delete
                </MenuItem>
            </Menu>
        </div>
        {showImage && <ImagePlayer data={props.moodboard} src={props.src} onClose={() => setShowImage(false)}/>}
        <Img
            className="img"
            src={props.src}
            alt="Filmboard Image"
            onClick={e => setShowImage(true)}
        />
    </div>
}

export default TileWebsite;