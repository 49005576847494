import React, {useState, useEffect} from 'react';
import Rodal from "rodal";
import 'rodal/lib/rodal.css';
import '../css/tabs.css';
import '../css/rodal.css';
import '../css/pexels.css';
import validator from 'validator';

export default function EmbedWebsite(props) {

    const [url, setUrl] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        const input = document.getElementById('txt-embed-url')
        input.setAttribute("autofocus", "");
        input.focus()
        input.select()
    }, []);
    
    const styles = {
        background: '#191A1F',
        width: '70%',
        height: 'fit-content',
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'hidden',
    };

    const handleValidate = () => {
        let element = document.getElementById('txt-embed-url');
        let val = element.value;
        if(val.length === 0 || !validator.isURL(val)) {
            element.classList.add('is-invalid');
            setUrl(val);
            return;
        }

        element.classList.remove('is-invalid');
        setUrl(val);
    }

    const handleSelect = () => {
        if(url.length === 0 || !validator.isURL(url)) {
            document.getElementById('txt-embed-url').classList.add('is-invalid');
            return;
        }

        props.onSelect({
            url,
            title,
            description,
            type: "website"
        });
        props.onClose();
    }

    return (
        <Rodal visible={true}
               onClose={() => props.onClose()}
               closeOnEsc={false}
               closeMaskOnClick={true}
               customStyles={styles}>
            <div className="container">
                <div className="row mt-30">
                    <div className="col-12">
                        <input type="text"
                               className="form-control form-control-3"
                               id="txt-embed-url"
                               placeholder="Paste a website URL (required)"
                               value={url}
                               onChange={handleValidate}/>
                        <div className="invalid-feedback text-left">
                            Please provide a valid website URL.
                        </div>
                    </div>
                </div>
                <div className="row mt-10">
                    <div className="col-12">
                        <input type="text"
                               className="form-control form-control-3"
                               id="txt-title"
                               placeholder="Title (optional)"
                               value={title}
                               onChange={e => setTitle(e.target.value)}/>
                    </div>
                </div>
                <div className="row mt-10">
                    <div className="col-12">
                        <textarea
                               className="form-control form-control-3"
                               id="txt-description"
                               placeholder="Description (optional)"
                               value={description}
                               onChange={e => setDescription(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="row">
                    <div className="col text-right mt-10">
                        <button className="btn btn-styled mt-0 btn-new m-1" id="select-button"
                                onClick={handleSelect}>Save website
                        </button>
                    </div>
                </div>
            </div>
        </Rodal>
    );
}