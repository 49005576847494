import React, {useState, useEffect} from 'react';

export default function PageNotFound(props) {
    return (
        <div className="container-fluid text-center">
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12" style={{marginTop: '70px'}}>
                    <p className="page-not-found">404!</p>
                    <p className="page-not-found">Page not found 🤷</p>
                    <p className="page-not-found-hint">Verify that you have correct URL in the browser searchbar.</p>
                </div>
            </div>
        </div>
    );
}