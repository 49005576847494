import React, { useState, useEffect, useRef } from "react";
import "../css/project.css";
import "../css/tabs.css";
import "../css/moodboard-grid.css";
import session from "../services/session";
import moment from "moment";
import {
  swalDeleteForm,
  swalError,
  swalUploading,
  swalConfirm,
  swalRenameTab,
  swalUploadingWithProgress,
} from "../utils/swal";
import projectService from "../services/project";
import moodboardService from "../services/moodboard";
import tabService from "../services/tab";
import Swal from "sweetalert2";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import MissedVideoCallIcon from "@material-ui/icons/MissedVideoCall";
import TabIcon from "@material-ui/icons/Tab";
import WebIcon from "@material-ui/icons/Web";
import { useNavigate } from "react-router-dom";
import { Button, message, notification } from "antd";
import ViewModuleOutlinedIcon from "@material-ui/icons/ViewModuleOutlined";
import ViewListOutlinedIcon from "@material-ui/icons/ViewListOutlined";
import CommentOutlinedIcon from "@material-ui/icons/CommentOutlined";
import Embed from "./Embed";
import EmbedWebsite from "./EmbedWebsite";
import Pexels from "./Pexels";
import MoodboardTiles from "./MoodboardTiles";
import Collaborate from "./Collaborate";
import ContentDetails from "./ContentDetails";
import MoodboardListView from "./MoodboardListView";
import Avatar from "react-avatar";
import { BrowserView, MobileView } from "react-device-detect";
import { v4 as uuid } from "uuid";
import { useParams } from "react-router-dom";
import DraggableTabs from "./DraggableTabs/DraggableTabs";
import axios from "axios";
import MoodboardStoryView from "./ViewMode/MoodboardStoryView";
import MoodboardMediaView from "./ViewMode/MoodboardMediaView";
import PresentationMode from "./PresentationMode/PresentationMode";
import MoveToModal from "./MoveToModal/MoveToModal";
import StoryViewIcon from "../images/storyViewIcon.svg";
import ViewListIcon from "../images/viewListIcon.svg";
import MediaViewIcon from "../images/mediaViewIcon.svg";
import ViewModuleIcon from "../images/viewModuleIcon.svg";
import AssetViewIcon from "../images/assetViewIcon.png";
import { ShareAltOutlined, PlusOutlined } from "@ant-design/icons";
import AssetView from "./ViewMode/AssetView/AssetView";
import AIGeneration from "./AIGeneration/AIGeneration";
import UploadingPopup from "./UploadingPopup/UploadingPopup";

export const uploadToS3 = async (arr) => {
  return new Promise(async (resolve, reject) => {
    let fileUrl = "";
    for (let i = 0; i < arr.length; i++) {
      let k = arr[i];
      let ext = k.file.name.split(".").pop();
      let key = `${uuid()}.${ext}`;

      const s3Link = await presignLink(k.file.name);

      const file = k.file;
      await axios.put(s3Link, file, {
        headers: { "Content-Type": k.file.type },
      });

      // Remove param of url
      const parsedUrl = new URL(s3Link);
      fileUrl = parsedUrl.origin + parsedUrl.pathname;
    }

    resolve(fileUrl);
  });
};

export const presignLink = async (name) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/users/get-resign-link`,
    {
      name,
    }
  );
  return res.data;
};

export const MoodboardTilesContext = React.createContext();

export default function N(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showPexels, setShowPexels] = useState(false);
  const [showEmbed, setShowEmbed] = useState(false);
  const [showWebsite, setShowWebsite] = useState(false);
  const [showCollaborate, setShowCollaborate] = useState(false);
  const [displayType, setDisplayType] = useState("grid");
  const [project, setProject] = useState(null);
  const [moodboardOrder, setMoodboardOrder] = useState(1);
  const [viewCommentsFor, setViewCommentsFor] = useState(null);
  const [activeTabId, setActiveTabId] = useState(null);
  const [tabMoodboards, setTabMoodboards] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState();
  const [selectMode, setSelectMode] = useState(false);
  const [isMoveMode, setIsMoveMode] = useState(false);
  const [listCopyAssets, setListCopyAssets] = useState([]);
  const [uploadingList, setUploadingList] = useState([]);

  const storyViewRef = useRef();
  const mediaViewRef = useRef();
  const modalRef = useRef();

  const userInfo = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    reloadProject();
  }, [id]);

  const reloadProject = () => {
    if (!id || id.length !== 24) return;
    projectService.get(id).then((result) => {
      if (result.error) {
        swalError(result.error);
        Swal.close();
        return;
      }

      if (result.data.length === 1) {
        loadProject(result.data[0]);
        setTabMoodboards(result.data[0].moodboards);

        if (result.data[0].tabs && result.data[0].tabs.length > 0) {
          setActiveTabId(result.data[0].tabs[0]._id);

          if (document.querySelector(`.ant-tabs-tab-active`)) {
            document.querySelector(`.ant-tabs-tab-active`).click();
          }
        }
      }
    });
  };

  const loadProject = (project) => {
    // setTitle(project.title);
    setProject(project);
    setMoodboardOrder(project.moodboards.length + 1);
    const el = document.getElementById("txt-project-title");
    el.style.display = "block";
    el.value = project.title;
    el.focus();
    el.removeAttribute("readonly");
    if (project.userId._id !== session.get("user")._id) {
      el.setAttribute("readonly", "readonly");
    }
  };

  const saveMoodboards = async (arr, projectId, tabId) => {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < arr.length; i++) {
        const k = arr[i];
        const body = {
          order: k.order,
          title: k.title,
          description: k.description,
          type: k.type,
          projectId: projectId,
          size: k?.size || 0,
          tabId: tabId,
          fileUrl: k.url,
          thumbnailUrl: k.thumbnailUrl || null,
          fileName: null,
          fileSize: 0,
          fileKey: null,
          userId: session.get("user").id,
          date: moment().format(),
        };

        if (k.source !== undefined) {
          body.source = k.source;
        }

        await moodboardService.add(body);
      }
      resolve(true);
    });
  };

  const deleteItem = async (id) => {
    swalDeleteForm(async () => {
      await deleteService(id);
      reloadMoodboards(activeTabId);
    });
  };

  const deleteItemAndReload = async (id) => {
    swalDeleteForm(async () => {
      await deleteService(id);
      reloadProject();
    });
  };

  const deleteService = async (mediaId) => {
    await moodboardService.delete(mediaId).then((result) => {
      if (result.error) {
        swalError(result.error);
        return;
      }
    });
  };

  const deleteMultipleItem = async (listMedia) => {
    return new Promise(async (resolve, reject) => {
      for (let i = 0; i < listMedia.length; i++) {
        const media = listMedia[i];
        await deleteService(media._id);
      }
      resolve(true);
    });
  };

  const viewItem = (id) => {
    if (id) {
      tabMoodboards.forEach((x, idx) => {
        if (x._id === id) {
          setViewCommentsFor(idx + 1);
        }
      });
    }
  };

  const uploadMoodboardsToS3 = async (arr, projectId, tabId) => {
    try {
      // Create an array of promises
      const uploadPromises = arr.map(async (k) => {
        let ext = k.file.name.split(".").pop();
        let key = `${uuid()}.${ext}`;

        const s3Link = await presignLink(k.file.name);

        const file = k.file;
        await axios.put(s3Link, file, {
          headers: { "Content-Type": k.file.type },
        });

        // Remove param of url
        const parsedUrl = new URL(s3Link);
        const cleanUrl = parsedUrl.origin + parsedUrl.pathname;

        const body = {
          order: k.order,
          title: k.title,
          description: k.description,
          type: k.type,
          projectId: projectId,
          tabId: tabId,
          userId: session.get("user").id,
          date: moment().format(),
          fileName: k.file.name,
          fileKey: key,
          fileSize: k.file.size,
          fileUrl: cleanUrl,
          thumbnailUrl: k.type === "image" ? cleanUrl : null,
        };

        if (k.source) {
          body.source = k.source;
        }

        await moodboardService.saveAsIs(body);
      });

      // Execute all promises concurrently
      await Promise.all(uploadPromises);

      // Resolve the main promise
      return true;
    } catch (error) {
      // Reject the main promise in case of any error
      throw error;
    }
  };

  const onSelectPexels = async (data) => {
    swalUploading();
    let f = [];
    let order = moodboardOrder;
    data.photos.forEach((x) => {
      f.push({
        type: "image",
        url: x.link,
        thumbnailUrl: x.thumbnailUrl,
        title: ``,
        description: ``,
        order: order,
        source: "pexels",
      });
      order += 1;
    });

    data.videos.forEach((v) => {
      f.push({
        type: "pexels-video",
        url: v.link,
        thumbnailUrl: v.thumbnailUrl,
        title: ``,
        description: ``,
        order: order,
        source: "pexels",
      });
      order += 1;
    });

    setMoodboardOrder(order + 1);
    await saveMoodboards(f, project._id, activeTabId);

    if (project.moodboards.length === 0) reloadProject();
    else reloadMoodboards(activeTabId);

    Swal.close();
  };

  const onAddAiImages = async (photos) => {
    swalUploading();
    let f = [];
    let order = moodboardOrder;
    photos.forEach((photo) => {
      f.push({
        type: "image",
        url: photo.url,
        thumbnailUrl: photo.url,
        title: ``,
        description: ``,
        order: order,
        source: "",
      });
      order += 1;
    });

    setMoodboardOrder(order + 1);
    await saveMoodboards(f, project._id, activeTabId);

    if (project.moodboards.length === 0) reloadProject();
    else reloadMoodboards(activeTabId);

    Swal.close();
  };

  const onAddScreenshot = async (screenshotUrl, otherData) => {
    let f = [
      {
        type: "image",
        url: screenshotUrl,
        thumbnailUrl: screenshotUrl,
        title: ``,
        description: ``,
        order: moodboardOrder,
        ...otherData,
      },
    ];

    await saveMoodboards(f, project._id, activeTabId);
    notification.success({
      message: "Added screenshot to project successfully",
    });

    setMoodboardOrder(moodboardOrder + 1);

    if (project.moodboards.length === 0) reloadProject();
    else reloadMoodboards(activeTabId);
    return true;
  };

  const onSelectEmbed = async (url, type, otherData) => {
    let t = [];
    swalUploading();
    let order = moodboardOrder;
    t.push({
      type: type,
      url: url,
      title: otherData?.title || ``,
      description: ``,
      order: order,
      thumbnailUrl: otherData?.thumbnailUrl,
      size: otherData?.size,
      source: otherData?.source,
    });
    setMoodboardOrder(order + 1);
    await saveMoodboards(t, project._id, activeTabId);

    if (project.moodboards.length === 0) reloadProject();
    else reloadMoodboards(activeTabId);

    Swal.close();
  };

  const onCopying = async (listMedia, projectId, tabId) => {
    let t = [];
    swalUploading();
    let order = moodboardOrder;
    listMedia.forEach((media, index) => {
      order = order + index;
      t.push({
        ...media,
        url: media.fileUrl,
        order: order,
      });
    });

    if (activeTabId === tabId) setMoodboardOrder(order + 1);
    await saveMoodboards(t, projectId, tabId);
    await deleteMultipleItem(listMedia);

    reloadMoodboards(activeTabId);

    Swal.close();
  };

  const onCopyingMultiple = async (listMedia, projectId, tabId) => {
    let t = [];
    swalUploading();
    let order = moodboardOrder;
    listMedia.forEach((media, index) => {
      order = order + index;
      t.push({
        ...media,
        url: media.fileUrl,
        order: order,
      });
    });

    if (activeTabId === tabId) setMoodboardOrder(order + 1);
    await saveMoodboards(t, projectId, tabId);
    if (isMoveMode) {
      await deleteMultipleItem(listMedia);
      notification.success({ message: "Successfully Moved" });
    } else {
      notification.success({ message: "Successfully Copied" });
    }
    setListCopyAssets([]);
    setSelectMode(false);
    setIsMoveMode(false);
    if (activeTabId === tabId || isMoveMode) {
      await reloadMoodboards(activeTabId);
    }
    Swal.close();
  };

  const onUpdating = async (media) => {
    swalUploading();
    await moodboardService.update(media._id, media);
    if (project.moodboards.length === 0) reloadProject();
    else reloadMoodboards(activeTabId);

    Swal.close();
  };

  const onUpdatingEmbed = async (video) => {
    await onUpdating(video);
    setShowEmbed(false);
    setSelectedVideo(null);
  };

  const onSelectWebsite = async (obj) => {
    let t = [];
    swalUploading();
    let order = moodboardOrder;
    t.push({
      type: obj.type,
      url: obj.url,
      title: obj.title,
      description: obj.description,
      order: order,
    });
    setMoodboardOrder(order + 1);
    await saveMoodboards(t, project._id, activeTabId);

    if (project.moodboards.length === 0) reloadProject();
    else reloadMoodboards(activeTabId);

    Swal.close();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    let t = document.getElementById("txt-project-title").value || ``;
    if (tabMoodboards && tabMoodboards.length === 0 && t.length === 0) {
      swalConfirm(async () => {
        const id = window.location.href.split("/").pop();
        await projectService.delete(id);
        navigate(`/`);
      });
    } else {
      navigate(`/`);
    }
  };

  const changeView = (e, type) => {
    e.preventDefault();
    document
      .querySelectorAll(".display-options img")
      .forEach((i) => i.classList.remove("view-icon-active"));
    document
      .querySelector(`.display-options .${type}`)
      .classList.add("view-icon-active");
    setDisplayType(type);
    reloadProject();
  };

  const getFileType = (type) => {
    if (type.includes("image")) return "image";
    if (type.includes("audio")) return "audio";
    if (type.includes("video")) return "video";
  };

  const handleUploadSelectedFiles = async (e) => {
    const files = e.target.files;
    if (files.length === 0) return;

    setUploadingList([...files]);

    const moodboardFiles = [];
    let order = 1;
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      order = moodboardOrder;
      let type = getFileType(file.type);
      moodboardFiles.push({
        type: type,
        file: file,
        title: file.name,
        description: ``,
        order: order,
        source: "uploaded",
      });
      order += 1;
    }

    setMoodboardOrder(order);
    await uploadMoodboardsToS3(moodboardFiles, project._id, activeTabId);

    if (project.moodboards.length === 0) reloadProject();
    else reloadMoodboards(activeTabId);

    setUploadingList([]);
  };

  const allowCollaborate = () => {
    if (project) {
      const loggedInUserId = session.get("user")._id;
      const loggedInUserEmail = session.get("user").email;
      if (!loggedInUserId || !loggedInUserEmail) {
        return false;
      }

      if (project.userId._id.toString() === loggedInUserId.toString()) {
        return true;
      }

      const collaboration = project.collaborations.find(
        (k) => k.email === loggedInUserEmail
      );
      if (collaboration) {
        if (
          parseInt(collaboration.accepted) === 1 &&
          parseInt(collaboration.type) === 2
        ) {
          return true;
        }
      }

      return false;
    }
  };

  const handleAddTab = async (e) => {
    e.preventDefault();
    let max = project.tabs && project.tabs.length;
    let obj = {
      title: `Tab ${max + 1}`,
      date: moment().format(),
      projectId: project._id,
    };

    tabService.add(obj).then((result) => {
      if (result.data) {
        reloadProject();
      }
    });
  };

  const reloadMoodboards = async (tabId) => {
    await tabService.get(tabId).then((result) => {
      if (result.data) {
        setTabMoodboards(result.data[0].moodboards);
      }
    });
  };

  const handleTabClick = (tabId) => {
    // document
    //   .querySelectorAll(`.btn-tab`)
    //   .forEach((t) => t.classList.remove("tab-active"));
    // e.target.classList.add("tab-active");
    // let id = e.target.getAttribute("id");
    setActiveTabId(tabId);
    reloadMoodboards(tabId);
    if (displayType === "list") {
      setTimeout(() => {
        if (document.querySelector(".thumbs .video-thumbnail")) {
          document.querySelector(".thumbs .video-thumbnail").click();
        }
      }, 500);
    }
  };

  const handleDeleteTab = (tabId) => {
    swalDeleteForm(async () => {
      await tabService.delete(tabId).then((result) => {
        if (result.error) {
          swalError(result.error);
          return;
        }
        reloadProject();
      });
    });
  };

  const handleEditVideo = (videoInfo) => {
    setShowEmbed(true);
    setSelectedVideo(videoInfo);
  };

  const handleRenameTab = (title, id) => {
    swalRenameTab(title, (newTitle) => {
      let obj = {
        title: newTitle,
        id: id,
      };
      tabService.update(obj).then((result) => {
        if (result.error) {
          swalError(result.error);
          return;
        }
        reloadProject();
      });
    });
  };

  const onUpdateTabOrder = async (newOrder) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/tabs/update`,
        { tabs: newOrder }
      );
    } catch (e) {
      console.log("error", e);
    }
  };

  const startSelect = () => {
    if (!selectMode) {
      message.warning("Choose at least one media before continue");
      setSelectMode(!selectMode);
    } else {
      setListCopyAssets([]);
      setSelectMode(!selectMode);
      setIsMoveMode(false);
    }
  };

  const startMove = () => {
    modalRef.current.openModal();
    setIsMoveMode(true);
  };

  const startCopy = () => {
    setIsMoveMode(false);
    modalRef.current.openModal();
  };

  const renderTabs = () => {
    if (project && project.tabs.length > 0) {
      return (
        <DraggableTabs
          items={project.tabs.map((tab) => {
            return {
              label: tab.title,
              key: tab._id,
            };
          })}
          onUpdateTabOrder={onUpdateTabOrder}
          onClickTab={handleTabClick}
          onDoubleClickTab={handleRenameTab}
          onDeleteTab={handleDeleteTab}
        />
      );
    }
  };

  const renderCollaborators = () => {
    if (project && project.collaborationusers.length > 0) {
      return project.collaborationusers.map((t) => (
        <Avatar
          key={t._id}
          style={{ margin: "2px" }}
          size={30}
          round={true}
          name={t.fullname}
          maxInitials={2}
          color="#7380A1"
        />
      ));
    }
  };

  const updateAssetList = (selectedAssets) => {
    if (listCopyAssets.some((asset) => asset._id === selectedAssets._id)) {
      const tempArr = [...listCopyAssets];
      setListCopyAssets(
        tempArr.filter((asset) => asset._id !== selectedAssets._id)
      );
    } else {
      let updatedAssets = [...listCopyAssets];
      updatedAssets.push(selectedAssets);
      setListCopyAssets(updatedAssets);
    }
  };

  const onClickCommercial = () => {
    navigate("/");
    setTimeout(() => {
      const element = document.querySelector('a[href="#search"]');
      element.click();
    }, 0);
  };

  return (
    <div
      className={`container-fluid big-wrapper ${
        displayType === "media" && "project-board-main-view"
      }`}
      style={{ padding: "0px" }}
    >
      {showPexels && (
        <Pexels
          onClose={() => setShowPexels(false)}
          onSelect={onSelectPexels}
        />
      )}
      {showEmbed && (
        <Embed
          onClose={() => {
            setShowEmbed(false);
            setSelectedVideo(null);
          }}
          onSelect={onSelectEmbed}
          onEdit={onUpdatingEmbed}
          onScreenshot={onAddScreenshot}
          videoInfo={selectedVideo}
        />
      )}
      {showWebsite && (
        <EmbedWebsite
          onClose={() => setShowWebsite(false)}
          onSelect={onSelectWebsite}
        />
      )}

      {showCollaborate && project && (
        <Collaborate
          onClose={() => setShowCollaborate(false)}
          projectId={project._id}
          project={project}
        />
      )}
      {viewCommentsFor && (
        <ContentDetails
          selectedIndex={viewCommentsFor}
          data={tabMoodboards}
          onClose={() => setViewCommentsFor(null)}
        />
      )}

      {/*<input type="text" className="form-control txt-project-title" id="txt-project-title" placeholder="Project Title"*/}
      {/*    value={title} onChange={e => setTitle(e.target.value)} onBlur={updateTitle} />*/}

      {/*<Tour
                steps={steps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
            />*/}
      {project && project.moodboards.length == 0 && (
        <div className="display-options">
          <KeyboardBackspaceIcon
            className="material-svg back-button-selector"
            style={{ marginRight: "10px" }}
            onClick={handleCancel}
            title="Back to dashboard"
          />
        </div>
      )}
      {project && project.moodboards.length > 0 && (
        <>
          {window.innerWidth <= 1180 ? (
            <div className="display-options">
              <div className="row header-mobile-tool">
                <div
                  className="col-5 text-left change-view-btns"
                  style={{ gap: "4px" }}
                >
                  <KeyboardBackspaceIcon
                    className="material-svg back-button-selector"
                    style={{ marginRight: "10px" }}
                    onClick={handleCancel}
                    title="Back to dashboard"
                  />
                  <img
                    src={AssetViewIcon}
                    className={`view-icon asset ${
                      displayType === "asset" && "view-icon-active"
                    }`}
                    onClick={(e) => changeView(e, "asset")}
                  />
                  <img
                    src={ViewModuleIcon}
                    className={`view-icon grid ${
                      displayType === "grid" && "view-icon-active"
                    }`}
                    id="gridButton"
                    onClick={(e) => changeView(e, "grid")}
                  />
                  <img
                    src={ViewListIcon}
                    className={`view-icon list ${
                      displayType === "list" && "view-icon-active"
                    }`}
                    onClick={(e) => changeView(e, "list")}
                  />
                  <img
                    src={StoryViewIcon}
                    className={`view-icon story ${
                      displayType === "story" && "view-icon-active"
                    }`}
                    onClick={(e) => changeView(e, "story")}
                  />
                  <img
                    src={MediaViewIcon}
                    className={`view-icon media ${
                      displayType === "media" && "view-icon-active"
                    }`}
                    onClick={(e) => changeView(e, "media")}
                  />
                </div>
                <div className="col-7 text-right pm tool-box">
                  {renderCollaborators()}
                  {allowCollaborate() && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="btn share-button-selector btn-share"
                        onClick={() => setShowCollaborate(true)}
                        title="Share"
                        icon={<ShareAltOutlined />}
                      ></Button>
                      <PresentationMode data={tabMoodboards} />
                      {displayType !== "asset" && (
                        <div
                          className="btn-group"
                          style={{
                            marginRight: "10px",
                            width: "30px",
                          }}
                        >
                          <div className="dropdown" style={{ width: "80px" }}>
                            <button
                              className="btn btn-new-2"
                              style={{
                                width: "30px",
                                height: "30px",
                                marginLeft: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <PlusOutlined />
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              style={{ zIndex: "2" }}
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                onClick={(e) => setShowPexels(true)}
                              >
                                <AddPhotoAlternateIcon /> Add Stock
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={onClickCommercial}
                              >
                                <MissedVideoCallIcon /> Commercials
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={(e) => setShowEmbed(true)}
                              >
                                <VideoLibraryIcon /> Embed
                              </a>
                              {userInfo?.package !== "1" && (
                                <AIGeneration onAddImage={onAddAiImages} />
                              )}
                              <a
                                className="dropdown-item"
                                onClick={(e) =>
                                  document
                                    .getElementById("txt-selected-files")
                                    .click()
                                }
                              >
                                <InsertDriveFileIcon /> Upload Files
                              </a>

                              <a
                                className="dropdown-item"
                                onClick={handleAddTab}
                              >
                                <TabIcon /> Add New Tab
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="row mt-10">
                <div className="col text-center tabs">
                  {displayType !== "asset" && renderTabs()}
                </div>
              </div>
            </div>
          ) : (
            <div className="display-options">
              <div className="row">
                <div className="col-2 text-left change-view-btns">
                  <KeyboardBackspaceIcon
                    className="material-svg back-button-selector"
                    style={{ marginRight: "10px" }}
                    onClick={handleCancel}
                    title="Back to dashboard"
                  />
                  <img
                    src={AssetViewIcon}
                    className={`view-icon asset ${
                      displayType === "asset" && "view-icon-active"
                    }`}
                    onClick={(e) => changeView(e, "asset")}
                  />
                  <img
                    src={ViewModuleIcon}
                    className={`view-icon grid ${
                      displayType === "grid" && "view-icon-active"
                    }`}
                    id="gridButton"
                    onClick={(e) => changeView(e, "grid")}
                  />
                  <img
                    src={ViewListIcon}
                    className={`view-icon list ${
                      displayType === "list" && "view-icon-active"
                    }`}
                    onClick={(e) => changeView(e, "list")}
                  />
                  <img
                    src={StoryViewIcon}
                    className={`view-icon story ${
                      displayType === "story" && "view-icon-active"
                    }`}
                    onClick={(e) => changeView(e, "story")}
                  />
                  <img
                    src={MediaViewIcon}
                    className={`view-icon media ${
                      displayType === "media" && "view-icon-active"
                    }`}
                    onClick={(e) => changeView(e, "media")}
                  />
                </div>

                <div
                  className="col-6 text-center tabs"
                  style={{ height: "62px" }}
                >
                  {displayType !== "asset" && renderTabs()}
                </div>

                <div
                  className="col-4 text-right pm"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {renderCollaborators()}
                  {allowCollaborate() && (
                    <>
                      {!selectMode && (
                        <button
                          style={{ marginLeft: "10px" }}
                          className="btn share-button-selector btn-share"
                          onClick={() => setShowCollaborate(true)}
                          title="Share"
                        >
                          Share
                        </button>
                      )}
                      {!selectMode && <PresentationMode data={tabMoodboards} />}

                      {displayType === "grid" && listCopyAssets.length > 0 && (
                        <button
                          style={{ marginLeft: "10px" }}
                          className="btn share-button-selector btn-move-to"
                          onClick={() => startMove()}
                        >
                          Move
                        </button>
                      )}
                      {displayType === "grid" && listCopyAssets.length > 0 && (
                        <button
                          style={{ marginLeft: "10px" }}
                          className="btn share-button-selector btn-move-to"
                          onClick={() => startCopy()}
                        >
                          Copy
                        </button>
                      )}
                      {displayType === "grid" && (
                        <button
                          style={{ marginLeft: "10px" }}
                          className={`btn share-button-selector ${
                            selectMode ? "cancel-btn" : "btn-move-to"
                          }`}
                          onClick={() => startSelect()}
                        >
                          {selectMode ? "Cancel" : "Select"}
                        </button>
                      )}
                      {!selectMode && displayType !== "asset" && (
                        <div className="btn-group">
                          <div className="dropdown" style={{ width: "80px" }}>
                            <button
                              className="btn dropdown-toggle btn-new-2"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Add
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-right"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                className="dropdown-item"
                                onClick={(e) => setShowPexels(true)}
                              >
                                <AddPhotoAlternateIcon /> Stock
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={onClickCommercial}
                              >
                                <MissedVideoCallIcon /> Commercials
                              </a>
                              <a
                                className="dropdown-item"
                                onClick={(e) => setShowEmbed(true)}
                              >
                                <VideoLibraryIcon /> Embed
                              </a>
                              {userInfo?.package !== "1" && (
                                <AIGeneration onAddImage={onAddAiImages} />
                              )}
                              <a
                                className="dropdown-item"
                                onClick={(e) =>
                                  document
                                    .getElementById("txt-selected-files")
                                    .click()
                                }
                              >
                                <InsertDriveFileIcon /> Files
                              </a>
                              {/*<a
                         className="dropdown-item"
                         onClick={(e) => setShowWebsite(true)}
                       >
                         <WebIcon /> Add Website
                       </a>*/}

                              <a
                                className="dropdown-item"
                                onClick={handleAddTab}
                              >
                                <TabIcon /> Tab
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                      {(displayType === "story" || displayType === "media") && (
                        <button className="btn-comment">
                          <CommentOutlinedIcon
                            onClick={() =>
                              displayType === "story"
                                ? storyViewRef.current.onToggleComment()
                                : mediaViewRef.current.onToggleComment()
                            }
                          />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {tabMoodboards && displayType === "asset" && (
        <AssetView
          projects={project}
          onView={viewItem}
          onDelete={deleteItemAndReload}
        />
      )}
      {tabMoodboards && displayType === "grid" && (
        <div className="moodboard-tiles text-center mt-20" id="moodboard-tiles">
          <MoodboardTilesContext.Provider
            value={{
              onCopy: onCopying,
              selectMode,
              updateAssetList,
            }}
          >
            <MoodboardTiles
              moodboards={tabMoodboards}
              onDelete={deleteItem}
              view={viewItem}
              onEdit={handleEditVideo}
              onSaveEditImage={onUpdating}
            />
          </MoodboardTilesContext.Provider>
        </div>
      )}

      {tabMoodboards && displayType === "list" && (
        <MoodboardListView moodboards={tabMoodboards} />
      )}

      {tabMoodboards && displayType === "story" && (
        <MoodboardStoryView ref={storyViewRef} data={tabMoodboards} />
      )}

      {tabMoodboards && displayType === "media" && (
        <MoodboardMediaView ref={mediaViewRef} data={tabMoodboards} />
      )}
      <MoveToModal
        ref={modalRef}
        onCopy={onCopyingMultiple}
        listAssets={listCopyAssets}
      />
      <input
        id="txt-selected-files"
        type="file"
        style={{ display: "none" }}
        accept=".jpg,.jpeg,.png,.mp4,.mp3, .mov"
        multiple
        onChange={handleUploadSelectedFiles}
      />

      {project && project.moodboards.length === 0 && (
        <div className="default-project-container text-center container action-button-selector">
          <div
            className="default-project-button"
            onClick={() => {
              // setIsOpen(!isOpen)
              setShowPexels(true);
            }}
          >
            <AddPhotoAlternateIcon /> Add Stock
          </div>
          <div
            className="default-project-button"
            onClick={() => {
              // setIsOpen(!isOpen)
              document.getElementById("txt-selected-files").click();
            }}
          >
            <InsertDriveFileIcon /> Upload Files
          </div>
          <div
            className="default-project-button"
            onClick={() => {
              setShowEmbed(true);
            }}
          >
            <VideoLibraryIcon /> Embed
          </div>
          <div className="default-project-button" onClick={onClickCommercial}>
            <VideoLibraryIcon /> Add Commercials
          </div>
          {userInfo?.package !== "1" && (
            <AIGeneration
              className="default-project-button"
              onAddImage={onAddAiImages}
            />
          )}
        </div>
      )}

      <UploadingPopup uploadingList={uploadingList} />
    </div>
  );
}
