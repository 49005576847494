import React, { useState, useEffect } from "react";
import settingService from "../services/setting";
import session from "../services/session";
import { getUrlParam } from "../utils/utils";
import { NavLink, Redirect } from "react-router-dom";
import Switch from "react-switch";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { Tooltip } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export default function Setting(props) {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [data, setData] = useState(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    settingService.getEmailNotifications().then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setSuccessMessage(null);
        return;
      }

      if (result.data) {
        setErrorMessage(null);
        setChecked(result.data.emailNotifications);
      }
    });
  }, []);

  const handleCheckChange = (check) => {
    setChecked(check);
    settingService.setEmailNotifications(check).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setSuccessMessage(null);
        return;
      }

      if (result.data) {
        setErrorMessage(null);
        setSuccessMessage(`Setting updated successfully!`);
      }
    });
  };

  return (
    <div className="container" style={{ color: "white" }}>
      <div className="row mt-20 mb-20">
        <div className="col-12">
          <label className="h2">
            <Tooltip title="Back to projects" aria-label="Back to projects">
              <KeyboardBackspaceIcon
                className="material-svg back-button-selector"
                style={{ marginRight: "10px" }}
                onClick={(e) => navigate(`/`)}
              />
            </Tooltip>
            Settings
          </label>
        </div>
      </div>
      <div className="row mt-20 mb-20">
        <div className="col-12">
          <label className="h4">Email Notifications</label>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <label htmlFor="chk-receive-emails" style={{ marginRight: "20px" }}>
            Receive comment notifications
          </label>
          <Switch
            checked={checked}
            onChange={handleCheckChange}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rGBa(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rGBa(0, 0, 0, 0.2)"
            height={15}
            width={48}
            className="react-switch"
            id="chk-receive-emails"
          />
          <br />
          <small style={{ color: "#878787" }}>
            You'll receive email notification when someone comments on your
            board or tags you in a comment.
          </small>
        </div>
      </div>

      <div className="row mt-30">
        <div className="col">
          {errorMessage && <p className="alert alert-danger">{errorMessage}</p>}
          {successMessage && (
            <p className="alert alert-success">{successMessage}</p>
          )}
        </div>
      </div>
    </div>
  );
}
