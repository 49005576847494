import React, { useState, useEffect } from "react";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import ReactJWPlayer from "react-jw-player";
import ContentComments from "./ContentComments";
import IspotPlayer from "./IspotPlayer/IspotPlayer";
import YoutubePlayer from "./YoutubePlayer/YoutubePlayer";
import { InstagramEmbed } from "react-social-media-embed";
import ReactPlayer from "react-player";

export default function ContentDetails(props) {
  let t = props.selectedIndex - 1;
  const [index, setIndex] = useState(t);
  const [disablePrevious, setDisablePrevious] = useState(t > 1 ? false : true);
  const [disableNext, setDisableNext] = useState(
    t === props.data.length ? true : false
  );
  const [selectedMoodboard, setSelectedMoodboard] = useState(props.data[t]._id);

  useEffect(() => {
    renderDisplaying();
    manageButtons();
    let t = props.data[index];
    if (t) setSelectedMoodboard(t._id);
  }, [index]);

  useEffect(() => {
    function handlekeydownEvent(event) {
      const { key, keyCode } = event;
      if (keyCode == 37 && index > 0) {
        goPrevious();
      }
      if (keyCode == 39 && index < props.data.length - 1) {
        goNext();
      }
    }

    document.addEventListener("keyup", handlekeydownEvent);
    return () => {
      document.removeEventListener("keyup", handlekeydownEvent);
    };
  }, [index]);

  const styles = {
    background: "#000000",
    width: "90%",
    height: "85%",
    overflowX: "hidden",
    overflowY: "auto",
    cursor: "default",
  };

  const renderDisplaying = () => {
    const item = props.data[index];
    const type = item.type;
    const playingUrl = item.fileUrl;
    if (type === "video") {
      if (playingUrl.includes("ispot.tv")) {
        return <IspotPlayer url={playingUrl} />;
      } else if (playingUrl.includes("youtube.com/shorts")) {
        return <YoutubePlayer url={playingUrl} style={{ height: "550px" }} />;
      } else if (playingUrl.includes("youtube")) {
        let id = new URL(playingUrl).searchParams.get("v");
        let src = `https://www.youtube.com/embed/${id}`;
        return (
          <iframe
            id="player"
            className="player"
            src={src}
            key={playingUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      } else if (item.source === "instagram") {
        return <InstagramEmbed key={playingUrl} url={playingUrl} />;
      } else if (playingUrl.includes("vimeo")) {
        let id = playingUrl.split("/").pop();
        let src = `https://player.vimeo.com/video/${id}`;
        return (
          <iframe
            id="player"
            className="player"
            src={src}
            key={playingUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      } else {
        // return <video className="player" controls key={playingUrl}>
        //     <source src={playingUrl} type="video/mp4"/>
        // </video>
        return (
          <ReactPlayer
            className="player-react"
            url={playingUrl}
            controls={true}
          />
        );
      }
    } else if (type === "docs") {
      return (
        <iframe
          id="player"
          className="player"
          src={playingUrl}
          key={playingUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else if (type === "audio") {
      return (
        <div style={{ margin: "20px", marginTop: "100px" }} key={playingUrl}>
          <audio
            controls
            className="audio-player w-100"
            src={playingUrl}
          ></audio>
        </div>
      );
    } else if (type === "image") {
      return <img className="image-player" key={playingUrl} src={playingUrl} />;
    } else if (type === "website") {
      return (
        <>
          <img
            className="image-player mb-30"
            key={playingUrl}
            src={playingUrl}
          />
          <br />
          Title: {item.title}
          <br />
          Website:{" "}
          <a href={item.websiteUrl} target="_blank" className="btn btn-link">
            {item.websiteUrl}
          </a>
          <br />
          Description: {item.description}
        </>
      );
    } else if (type === "pexels-video") {
      // return <video className="player" key={playingUrl} controls>
      //     <source src={playingUrl} type="video/mp4"/>
      // </video>
      return (
        <ReactJWPlayer
          className="player"
          playerId={playingUrl.toString()}
          playerScript="https://cdn.jwplayer.com/libraries/s7lNp89j.js"
          file={playingUrl}
          image={item.thumbnailUrl}
        />
      );
    }
  };

  const goPrevious = (e) => {
    //e.preventDefault();
    if (index > 0) {
      setIndex(index - 1);
    }

    manageButtons();
  };

  const goNext = (e) => {
    //e.preventDefault();
    if (index < props.data.length) {
      setIndex(index + 1);
    }

    manageButtons();
  };

  const manageButtons = () => {
    setDisablePrevious(true);
    setDisableNext(true);
    if (index > 0) setDisablePrevious(false);
    if (index < props.data.length - 1) setDisableNext(false);
  };

  return (
    <Rodal
      visible={true}
      onClose={() => props.onClose()}
      closeOnEsc={true}
      showMask={true}
      animation={"fade"}
      closeMaskOnClick={true}
      customStyles={styles}
    >
      <div className="container-fluid content-details">
        <div className="row">
          <div className="col-1 text-center nav-icon-wrapper">
            <IconButton
              className={`nav-icon nav-icon-prev`}
              disabled={disablePrevious}
              onClick={goPrevious}
            >
              <ChevronLeft />
            </IconButton>
          </div>
          <div className="content-detail-wrapper">
            <div className="col-md-7 col-sm-12 text-left">
              <div className="displaying-body">
                {props.data && renderDisplaying()}
              </div>
            </div>
            <div className="col-md-3 col-sm-12 text-left comment-box-wrapper">
              <ContentComments id={selectedMoodboard} />
            </div>
          </div>

          <div className="col-1 text-center nav-icon-wrapper">
            <IconButton
              className={`nav-icon`}
              disabled={disableNext}
              onClick={goNext}
            >
              <ChevronRight />
            </IconButton>
          </div>
        </div>
      </div>
    </Rodal>
  );
}
