import {  Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./AssetView.scss";
import { useEffect, useState } from "react";
import AssetFile from "./AssetFile";
import axios from "axios";

const AssetView = ({ projects, onView, onDelete }) => {
  const [listTabs, setListTabs] = useState([]);
  const [searchStr, setSearchStr] = useState(null);

  useEffect(() => {
    let tabs = projects.tabs.map((tab) => ({ ...tab, files: [] }));
    projects.moodboards.forEach((moodboard) => {
      tabs = tabs.map((tab) => {
        if (tab._id === moodboard.tabId ) {
          if (searchStr) {
            if (moodboard.title.toLowerCase().includes(searchStr.toLowerCase())) {
              tab.files.push(moodboard);
            }
          } else {
            tab.files.push(moodboard);
          }
        }
        return tab;
      });
    });
    setListTabs(tabs);
  }, [projects, searchStr]);

  const handleDrop = async (selectedFile) => {
    const reOrderTabIndex = listTabs.findIndex(tab => tab._id === selectedFile.tabId)
    const tabNeedReorder = listTabs[reOrderTabIndex].files
        const sortedArray = [];
        tabNeedReorder.forEach((item, index) =>
          sortedArray.push({ _id: item._id, order: index + 1 })
        );
        try {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/moodboards/re-order`,
            {
              order: sortedArray,
            }
          );
        } catch (e) {
          console.log("order error", e);
        }
  };

  const handleDrag = (fromIndex, toIndex, selectFile) => {
    let tempOrder = [...listTabs];
    const reOrderTabIndex = listTabs.findIndex(tab => tab._id === selectFile.tabId)
    const [movedCard] = tempOrder[reOrderTabIndex].files.splice(fromIndex, 1);
    tempOrder[reOrderTabIndex].files.splice(toIndex, 0, movedCard);
    setListTabs(tempOrder);
  }

  return (
    <div className="asset-view-container">
      <div className="asset-view-header">
        <div className="asset-row">
          <div>All files</div>
          <div className="total-file-wrapper">{projects.moodboards.length}</div>
        </div>
        <Input
          className="search-file"
          placeholder="Search file"
          prefix={<SearchOutlined />}
          onChange={({target: {value}}) => setSearchStr(value)}
        />
      </div>

      <div className="table-column">
        <div>NAME</div>
        <div>SIZE</div>
        <div>ADDED</div>
        <div>SOURCE</div>
      </div>

      {listTabs.length > 0 &&
        listTabs.map((tab) => (
          <div key={tab._id}>
            <div className="table-column asset-tab-title">{tab.title}</div>
            {tab.files.map((file, index) => (
              <AssetFile
                key={file._id}
                index={index}
                file={file}
                handleDrag={handleDrag}
                handleDrop={handleDrop}
                onView={onView}
                onDelete={onDelete}
              />
            ))}
          </div>
        ))}
    </div>
  );
};

export default AssetView;
