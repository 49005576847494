import React, { useState, useEffect } from 'react';
import '../css/billing.css';
import UpvotyWidget from '../react-upvoty/index';

function Billing(props) {

    useEffect(() => {     
    }, []);

    return (
        <div className="container text-center mt-20">
            <UpvotyWidget
                baseUrl={'filmboard.upvoty.com'}
                boardHash={'344755f5a7b88ca7b14fda16d5e9c1667364299c1d2fd948205c053770d59cf5'}/>
            <div className="mt-20">
                <h5>Need additional help?</h5>
                <p>support@filmboard.io</p>
            </div>
        </div>
    );
}

export default Billing;