import React, { useState, useEffect } from "react";

import srcUrl from "../images/default-video.png";

export default function ThumbnailYouTube(props) {
  const [id, setId] = useState("");

  useEffect(() => {
    try {
      let id;
      if (props.item.fileUrl.includes("/shorts")) {
        id = props.item.fileUrl.split("shorts/")[1];
      } else {
        const url = new URL(props.item.fileUrl);

        id = url.searchParams.get("v") || url.pathname.split("/")[1];
      }

      setId(`https://img.youtube.com/vi/${id}/hqdefault.jpg`); //maxres1.jpg
    } catch (err) {
      setId(srcUrl);
    }
  }, []);

  const handlePlay = (e) => {
    e.preventDefault();
    if (props.setPlaying) {
      props.setPlaying(props.item);
    }
  };

  return (
    <img
      src={id}
      className={`video-thumbnail ${props.item._id}`}
      title={props.setPlaying && "Click to play this video."}
      onClick={handlePlay}
    />
  );
}
