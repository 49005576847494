import "../css/moodboard-masonry.css";
import ItemBar from "./ItemBar/ItemBar";

function TileEmbed({ currentFile, view, ...props }) {
  return (
    <div className={`tile ${props.id}`}>
      <ItemBar
        currentFile={currentFile}
        viewItem={() => view(currentFile._id)}
        deleteItem={() => props.delete(currentFile._id)}
      />
      <iframe
        id="player"
        className="player"
        src={currentFile?.fileUrl}
        frameBorder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default TileEmbed;
