import React, {useState, useEffect} from 'react';
import {Redirect} from 'react-router-dom';
import StoryboardCell from "./StoryboardCell";
import $ from "jquery";
import {swalPackageLimitReached} from "../utils/swal";

export default function Storyboard(props) {

    const init = [{fileUrl: '', title: '', description: '', shot: '', angle: '', movement: '', audio: ''}];
    const [items, setItems] = useState(init);

    useEffect(() => {
        if(props.storyboards && props.storyboards.length > 0) {
            setItems(props.storyboards.sort((a, b) => parseInt(a.order) - parseInt(b.order)));
        }
    }, [props.storyboards]);

    useEffect(() => {
        try {
            const els = document.querySelectorAll(`.storyboard .item`);
            if (els.length > 0) {
                const el = els[els.length - 1];
                el.querySelector(`.item-title`).focus();
                el.querySelector(`.item-title`).scrollIntoView();
            }
        }
        catch (e) {}
    }, [items]);

    const duplicateItem = e => {
        e.preventDefault();
        const item = $(e.target).closest('.item');
        const type = item.find('.item-type').val();
        const title = item.find('.item-title').val();
        const description = item.find('.item-description').val();
        const shot = item.find('.item-shot').val();
        const angle = item.find('.item-angle').val();
        const movement = item.find('.item-movement').val();
        const audio = item.find('.item-audio').val();

        const t = Array.from(items);
        t.push({fileUrl: '', title: title, description: description, shot: shot, angle: angle, movement: movement, audio: audio});
        setItems(t);
    }

    const handleAddMore = () => {
        const t = Array.from(items);
        t.push({fileUrl: '', title: '', description: '', shot: '', angle: '', movement: '', audio: ''});
        setItems(t);
    }

    const renderItems = () =>
        items.map(item => <StoryboardCell key={item._id} item={item} duplicateItem={duplicateItem} />);

    return (
        <div className="container storyboard">
            <div className="row mt-10 mb-10">
                <div className="col-12 col-sm-6 col-md-6 text-left">
                    <small style={{color: 'grey'}}>* Only items with valid title and file will be saved. Others will be skipped.</small>
                </div>
                <div className="col-12 col-sm-6 col-md-6 text-right">
                    <button className="btn btn-sm btn-outline-light m-1"
                            onClick={() => handleAddMore()}>Add Shot
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-12" id="storyboard-items">
                    {renderItems()}
                </div>
            </div>
        </div>
    );
}