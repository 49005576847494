import axios from 'axios';

import session from "./session";
import moment from "moment";

export default class {
    static getEmailNotifications = async () => {
        let result = { data: null, error: null };
        await axios.get(`${process.env.REACT_APP_API_URL}/settings/${session.get('user')._id}`)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }

    static setEmailNotifications = async check => {
        let result = { data: null, error: null };
        const data = {
            check: check,
            userId: session.get('user')._id
        };

        await axios.post(`${process.env.REACT_APP_API_URL}/settings/`, data)
            .then(resp => {
                if (resp.status === 200) {
                    result.data = resp.data;
                }
            })
            .catch(err => {
                result.error = err.response.data;
            });

        return result;
    }
}