import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-number-input";
import userService from "../services/user";

import session from "../services/session";
import collaborationService from "../services/collaboration";
import { swalLoading } from "../utils/swal";
import Swal from "sweetalert2";
import srcUrl from "../images/banner_3.jpg";

export default function Signup(props) {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [phone, setPhone] = useState();
  const [question1, setQuestion1] = useState();
  const [question2, setQuestion2] = useState();
  const [question3, setQuestion3] = useState();
  const [question4, setQuestion4] = useState();
  const [question5, setQuestion5] = useState();

  useEffect(() => {
    session.clear();
  }, []);

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!fullname || fullname.length === 0) {
      setErrorMessage(`Full Name is required.`);
      return;
    }
    if (!email || email.length === 0) {
      setErrorMessage(`Email is required.`);
      return;
    } else {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false
      ) {
        setErrorMessage(`Please enter a valid email address.`);
        return;
      }
    }
    if (!password || password.length === 0) {
      setErrorMessage(`Password is required.`);
      return;
    }

    swalLoading();

    const body = {
      phone,
      question1,
      question2,
      question3,
      question4,
      question5,
      company,
    };

    await userService
      .signup(fullname, email, password, position, body)
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          Swal.close();
          return;
        }

        if (result.data) {
          const data = result.data;
          setErrorMessage("");
          setSuccessMessage(
            "Signup successful! Please check your email and activate your account."
          );
          Swal.close();
          onAcceptInvite();
        }
      });
  };

  const onAcceptInvite = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let token;
    if (urlParams.has("token")) {
      token = urlParams.get("token");
    }
    if (!token || token.length !== 24) {
      return;
    }

    collaborationService.acceptInvite(token);
  };

  const formatFullname = (e) => {
    e.preventDefault();
    let splitStr = fullname.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++)
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);

    setFullname(splitStr.join(" "));
  };

  return (
    <div className="login-signup-container">
      <div
        className="container-fluid text-center div-signup"
        style={{ marginTop: "auto", width: "600px", marginBottom: "auto" }}
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 infoBox">
            {props.mobile != true}
            <h2 className="m-4">Sign Up</h2>
            <br />
            <form onSubmit={handleSignup}>
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control input-field width80"
                    id="txtSignupFullname"
                    placeholder="Full Name"
                    required="required"
                    onBlur={formatFullname}
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                  ></input>
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="email"
                    className="form-control input-field width80"
                    placeholder="Email"
                    required="required"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="password"
                    className="form-control input-field width80"
                    placeholder="Password"
                    required="required"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control input-field width80"
                    placeholder="Company (Optional)"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <input
                    type="text"
                    className="form-control input-field width80"
                    placeholder="Position (Optional)"
                    value={position}
                    onChange={(e) => setPosition(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <PhoneInput
                  className="form-control input-field width80 phone-input"
                  placeholder="Enter phone number"
                  value={phone}
                  defaultCountry="US"
                  onChange={setPhone}
                />
              </div>
              <div className="form-group">
                <div className="input-container">
                  <textarea
                    type="text"
                    className="form-control input-field width80"
                    placeholder="What are the most important aspects of your pre-production process?"
                    required="required"
                    onChange={(e) => setQuestion1(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="form-group">
                <div className="input-container">
                  <textarea
                    type="text"
                    className="form-control input-field width80"
                    placeholder="What is the biggest challenge in your creative development process?"
                    required="required"
                    onChange={(e) => setQuestion2(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <br />
              <div className="width80 text-left mb-2">
                On a scale of 1-10, How important are visual references to the
                work that you do?
              </div>
              <select
                className="form-control input-field width80"
                onChange={(e) => setQuestion3(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  Select
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
              <br />
              <div className="width80 text-left mb-2">
                Are you open to speaking with us about product feedback?
              </div>
              <div className="form-group">
                <select
                  className="form-control input-field width80"
                  onChange={(e) => setQuestion4(e.target.value)}
                >
                  <option value="" disabled selected hidden>
                    Select
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
              <div className="form-group">
                <select
                  className="form-control input-field width80"
                  onChange={(e) => setQuestion5(e.target.value)}
                >
                  <option value="" disabled selected hidden>
                    How did you hear about us?
                  </option>
                  <option value="facebook">Facebook</option>
                  <option value="reddit">Reddit</option>
                  <option value="friend">Friend or Colleague</option>
                  <option value="instagram">Instagram</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <br />
              {errorMessage && (
                <div className="alert alert-danger">{errorMessage}</div>
              )}

              {successMessage && (
                <div className="alert alert-success">{successMessage}</div>
              )}

              <button
                type="submit"
                className="btn btn-pink-1 pad40 width100"
                onClick={(e) => handleSignup(e)}
              >
                Create an Account
              </button>

              <div className="form-group" style={{ marginTop: "20px" }}>
                <p>
                  Already have an account?
                  <a href="/login" className="btn btn-link">
                    Sign in here
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
