import { Dropdown, Image, Input } from "antd";
import { DashOutlined } from "@ant-design/icons";
import moment from "moment";
import { useDrag, useDrop } from "react-dnd";

import ImageIcon from "images/imageIcon.png";
import VideoIcon from "images/videoIcon.png";

import axios from "axios";
import { useEffect, useState } from "react";
import { ytPattern } from "components/Embed";

import "./AssetView.scss";

const AssetFile = ({
  file,
  index,
  handleDrag,
  handleDrop,
  onView,
  onDelete,
}) => {
  const [isActive, setIsActive] = useState(file.active || true);

  const [, ref] = useDrag({
    type: file.tabId,
    item: { index, ...file },
  });

  const fileSize = file.size || (file?.fileSize / 1024).toFixed(2);

  useEffect(() => {
    if (ytPattern.test(file.fileUrl)) {
      checkAvailable();
    }
  }, [file]);

  const checkAvailable = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/video/check-link-status`,
        { videoUrl: file.fileUrl }
      );
      setIsActive(res.data.isValid);
    } catch (e) {
      console.log("check available error", e);
    }
  };

  const [, drop] = useDrop({
    accept: file.tabId,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        handleDrag(draggedItem.index, index, file);
        draggedItem.index = index;
      }
    },
    drop: (item) => {
      handleDrop(item);
    },
  });

  return (
    <div
      ref={(node) => ref(drop(node))}
      className={`table-column`}
      onDoubleClick={() => onView(file._id)}
    >
      <div className={`asset-row ${!isActive && "asset-inactive"}`}>
        <Image
          src={file.type === "image" ? ImageIcon : VideoIcon}
          className="file-type-icon"
          preview={false}
        />
        {file.title || file.fileUrl}
      </div>
      <div className="break-word">{fileSize > 0 ? `${fileSize} MB` : ""}</div>

      <div className="break-word">
        {moment(file.date).format("DD MMM YYYY")}
      </div>

      <div className="break-word capitalize">{file.source}</div>
      <Dropdown
        menu={{
          items: [
            {
              label: <div onClick={() => onView(file._id)}>View comments</div>,
              key: "1",
            },
            {
              label: <div onClick={() => onDelete(file._id)}>Delete</div>,
              key: "3",
            },
          ],
        }}
        trigger={["click"]}
      >
        <DashOutlined className="more-asset-icon" />
      </Dropdown>
    </div>
  );
};

export default AssetFile;
