import React, { useState, useEffect } from "react";
import projectService from "../services/project";
import session from "../services/session";
import { Redirect } from "react-router-dom";

import {
	swalDeleteForm,
	swalError,
	swalSuccess,
	swalShare,
	swalInfo
} from "../utils/swal";
import "../css/project-page.css";
import SyncingEditorMaterialSlate from "./SyncingEditorMaterialSlate";
import EditorMaterialSlate from "./EditorMaterialSlate";
import UploadAndViewVendorFiles from "./UploadAndViewVendorFiles";
import SyncingEditorSimple from "./SyncingEditorSimple";
import Collaborate from "./Collaborate";
import Upload from "./Upload";
import UploadView from "./UploadView";
import LoginOrSignup from "./LoginOrSignup";
import MoodboardView from "./MoodboardView";
import StoryboardView from "./StoryboardView";
import { useNavigate } from "react-router-dom";

export default function ProjectPage(props) {
	const navigate = useNavigate();
	const [loginOrSignup, setLoginOrSignup] = useState(false);
	const [collaboratePopup, setCollaboratePopup] = useState(false);
	const [projectId, setProjectId] = useState(null);
	const [project, setProject] = useState(null);
	const [moodboards, setMoodboards] = useState(null);
	const [storyboards, setStoryboards] = useState(null);
	const [collaborations, setCollaborations] = useState(null);
	const [isCollaborator, setIsCollaborator] = useState(false);
	const [accessType, setAccessType] = useState(1);

	useEffect(() => {
		reloadProject();
	}, []);

	const reloadProject = async () => {
		let id = window.location.href.split("/").pop();
		if (id && id.length === 24) {
			setProjectId(id);
			await projectService.get(id).then(result => {
				if (result.error) {
					swalError(result.error);
					return;
				}

				if (result.data.length === 1) {
					loadProject(result.data[0]);
					if (document.querySelector(`.video-thumbnail`) !== null) {
						document.querySelector(`.video-thumbnail`).click();
					}
				} else {
					swalInfo(`Project not found with project ID.`);
					return;
				}
			});
		} else {
			swalError(`Invalid project ID detected in the URL.`);
			return;
		}
	};

	const setAccessProperties = t => {
		if (t && session.get("user") && session.get("user")._id) {
			t.map(collaboration => {
				if (collaboration.email === session.get("user").email) {
					setIsCollaborator(true);
					setAccessType(collaboration.type);
				}
			});
		}
	};

	const loadProject = project => {
		setProject(project);
		setMoodboards(project.moodboards);
		setStoryboards(project.storyboards);
		setCollaborations(project.collaborations);
		setAccessProperties(project.collaborations);
	};

	const handleEdit = e => {
		if (project.userId._id !== session.get("user")._id) {
			if (accessType === 1) {
				swalInfo(`You don't have permission to edit the project.`);
				return;
			}
		}

		navigate(`/project/${project._id}`);
	};

	const handleDelete = e => {
		e.preventDefault();

		if (project.userId !== session.get("user")._id) {
			swalInfo(`Only project owner can delete the project.`);
			return;
		}

		swalDeleteForm(async () => {
			await projectService.delete(project._id).then(result => {
				if (result.error) {
					swalError(result.error);
					return;
				}

				swalSuccess("Project deleted successfully!");
				navigate("/");
			});
		});
	};

	const handleCollaborate = e => setCollaboratePopup(true);

	return !project ? null : (
		<div className="container-fluid text-center mt-10 fade">
			{loginOrSignup && (
				<LoginOrSignup onClose={() => setLoginOrSignup(false)} />
			)}
			{collaboratePopup && (
				<Collaborate
					onClose={() => setCollaboratePopup(false)}
					projectId={project._id}
					project={project}
				/>
			)}
			<div className="row mt-10">
				<div className="col-2 col-sm-1 col-md-1 text-right">
					<i
						className="fa fa-chevron-left btn-back"
						onClick={() => navigate(`/`)}
						title="Back to dashboard"
					></i>
				</div>
				<div className="col-10 col-sm-8 text-left">
					<p className="title">
						{project.fileUrl && (
							<img
								className="project-logo"
								src={project.fileUrl}
							/>
						)}
						{project.title}
					</p>
				</div>
				<div className="col-12 col-sm-3 col-md-3 text-right">
					{session.get("user") &&
						session.get("user")._id === project.userId._id && (
							<span>
								<button
									className="btn btn-pink btn-sm m-1"
									onClick={handleCollaborate}
								>
									Share
								</button>
							</span>
						)}
					{session.get("user") &&
						session.get("user")._id &&
						(session.get("user")._id === project.userId._id ||
							isCollaborator) && (
							<span>
								<button
									className="btn btn-light btn-sm m-1"
									title="Edit this board"
									onClick={handleEdit}
								>
									Edit Project{" "}
									<i className="fa fa-pencil-alt"></i>
								</button>
								<button
									className="btn btn-light btn-sm m-1"
									title="Delete this board"
									onClick={handleDelete}
								>
									<i className="fa fa-trash-alt"></i>
								</button>
							</span>
						)}
				</div>
			</div>
			<div className="row">
				<div className="col-1 col-sm-1 col-md-1"></div>
				<div className="col-11 col-sm-11 text-left">
					<p className="description">{project.description}</p>
				</div>
			</div>
			<div className="row mt-10">
				<div className="col-12 col-sm-12 text-center">
					<div className="tabbable-panel">
						<div className="tabbable-line">
							<ul
								className="nav nav-tabs"
								id="myTab"
								role="tablist"
							>
								<li className="nav-item">
									<a
										className="nav-link active"
										id="home-tab"
										data-toggle="tab"
										href="#home"
										role="tab"
										aria-controls="home"
										aria-selected="true"
									>
										Moodboard
									</a>
								</li>
								<li className="nav-item">
									<a
										className="nav-link"
										id="script-tab"
										data-toggle="tab"
										href="#script"
										role="tab"
										aria-controls="script"
										aria-selected="false"
									>
										Script
									</a>
								</li>
								<li className="nav-item">
									<a
										className="nav-link"
										id="profile-tab"
										data-toggle="tab"
										href="#profile"
										role="tab"
										aria-controls="profile"
										aria-selected="false"
									>
										Storyboard
									</a>
								</li>
								<li className="nav-item">
									<a
										className="nav-link"
										id="contact-tab"
										data-toggle="tab"
										href="#contact"
										role="tab"
										aria-controls="contact"
										aria-selected="false"
									>
										Vendors
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="row mt-10">
				<div className="col-12 col-sm-12 text-center">
					<div className="tab-content" id="myTabContent">
						<div
							className="tab-pane fade show active"
							id="home"
							role="tabpanel"
							aria-labelledby="home-tab"
						>
							<MoodboardView moodboards={moodboards || null} />
						</div>
						<div
							className="tab-pane fade"
							id="profile"
							role="tabpanel"
							aria-labelledby="profile-tab"
						>
							<StoryboardView storyboards={storyboards || null} />
						</div>
						<div
							className="tab-pane fade"
							id="contact"
							role="tabpanel"
							aria-labelledby="contact-tab"
						>
							<UploadAndViewVendorFiles
								showDeleteButton={
									session.get("user") &&
									session.get("user")._id ===
										project.userId._id
								}
							/>
						</div>
						<div
							className="tab-pane fade"
							id="script"
							role="tabpanel"
							aria-labelledby="script-tab"
						>
							{/*<SyncingEditorSimple projectId={project._id} />*/}
							<SyncingEditorMaterialSlate
								projectId={project._id}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
