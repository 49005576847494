import React, { useEffect, useState } from "react";
import "./App.css";
import "./css/drag-drop.css";
import "./css/project.css";
import "./css/tabs.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import session from "./services/session";
import Header from "./components/Header";
import Login from "./components/Login";
import Signup from "./components/Signup";
import N from "./components/N";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Activate from "./components/Activate";
import Accept from "./components/Accept";
import Billing from "./components/Billing";
import Feedback from "./components/Feedback";
import Projects from "./components/Projects";
import Project from "./components/Project";
import ProjectPage from "./components/ProjectPage";
import PageNotFound from "./components/PageNotFound";
import New from "./components/New";
import View from "./components/View";
import Setting from "./components/Setting";

import "antd/dist/reset.css";
import "react-phone-number-input/style.css";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (session.get("user") && session.get("user")._id !== undefined) {
      setLoggedIn(true);
    }
  }, []);

  return (
    <BrowserRouter>
      <Header loggedIn={loggedIn} onLogout={() => setLoggedIn(false)} />
      <Routes>
        <Route
          exact
          path="/login"
          element={<Login onLogin={() => setLoggedIn(true)} forPopup={false} />}
        />
        <Route
          exact
          path="/signup"
          element={
            <Signup onLogin={() => setLoggedIn(true)} forPopup={false} />
          }
        />
        <Route exact path="/fp" element={<ForgotPassword />} />
        <Route exact path="/rp" element={<ResetPassword />} />
        <Route exact path="/activate" element={<Activate />} />
        <Route exact path="/accept" element={<Accept />} />
        <Route exact path="/billing" element={<Billing />} />
        <Route exact path="/feedback" element={<Feedback />} />
        <Route exact path="/" element={<Projects />} />
        <Route exact path="/projects/:id" element={<ProjectPage />} />
        <Route exact path="/project" element={<Project />} />
        <Route exact path="/project/:id" element={<Project />} />
        <Route exact path="/new" element={<New />} />
        <Route exact path="/view/:id" element={<View />} />
        <Route exact path="/n/:id" element={<N />} />
        <Route exact path="/setting" element={<Setting />} />
        <Route element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
