import React, { useState, useRef } from "react";
import { Modal } from "antd";
import { MenuItem } from "@material-ui/core";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";
import "./ImageEditor.scss";
import { uploadToS3 } from "../N";

function base64ToFile(base64String, fileName) {
  const byteString = atob(base64String.split(",")[1]);
  const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], { type: mimeString });

  const file = new File([blob], fileName, { type: mimeString });
  return file;
}

const ImageEditorModal = ({ imageSrc, onFinishEdit, onClickEdit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const editorRef = useRef();

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  const handleSave = async (editedImageObject) => {
    const imageFile = base64ToFile(
      editedImageObject.imageBase64,
      editedImageObject.fullName
    );
    try {
      const uploadedUrl = await uploadToS3([{ file: imageFile }]);
      onFinishEdit(uploadedUrl, closeModal);
    } catch (e) {
      console.log("Error");
    }
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          onClickEdit();
          openModal();
        }}
      >
        Edit
      </MenuItem>

      <Modal
        open={isModalOpen}
        onCancel={closeModal}
        width="100%"
        height="100%"
        footer={null}
        closable={false}
        wrapClassName="image-editor-wrapper"
        className="imageEditorModal"
        destroyOnClose
      >
        <FilerobotImageEditor
          source={imageSrc}
          onSave={handleSave}
          onClose={handleCancel}
          Text={{
            text: "Filerobot...",
          }}
          Rotate={{ angle: 90, componentType: "slider" }}
          Crop={{
            presetsItems: [
              {
                titleKey: "classicTv",
                descriptionKey: "4:3",
                ratio: 4 / 3,
                // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
              },
              {
                titleKey: "cinemascope",
                descriptionKey: "21:9",
                ratio: 21 / 9,
                // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
              },
            ],
            presetsFolders: [
              {
                titleKey: "socialMedia", // will be translated into Social Media as backend contains this translation key
                // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                groups: [
                  {
                    titleKey: "facebook",
                    items: [
                      {
                        titleKey: "profile",
                        width: 180,
                        height: 180,
                        descriptionKey: "fbProfileSize",
                      },
                      {
                        titleKey: "coverPhoto",
                        width: 820,
                        height: 312,
                        descriptionKey: "fbCoverPhotoSize",
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ANNOTATE} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
        />
      </Modal>
    </>
  );
};

export default ImageEditorModal;
