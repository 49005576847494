import { useState } from "react";
import { Button, Modal } from "antd";

import "./PresentationMode.scss";
import PresentationContent from "./PresentationContent";
import { BrowserView, MobileView } from "react-device-detect";
import { FullscreenOutlined } from "@ant-design/icons";

const PresentationMode = ({ data }) => {
  const [isShowPresentationMode, setPresentationMode] = useState(false);

  return (
    <>
      <MobileView>
        <Button
          style={{
            marginLeft: "5px",
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="btn share-button-selector btn-new-2"
          onClick={() => setPresentationMode(true)}
          icon={<FullscreenOutlined />}
        ></Button>
      </MobileView>
      <BrowserView>
        <button
          style={{ marginLeft: "10px", width: "fit-content" }}
          className="btn share-button-selector btn-new-2"
          onClick={() => setPresentationMode(true)}
          title="Present"
        >
          Present
        </button>
      </BrowserView>
      <Modal
        centered
        wrapClassName="presentation-modal-wrapper"
        className="presentation-modal"
        bodyStyle={{ backgroundColor: "black", padding: 0, height: "100%" }}
        open={isShowPresentationMode}
        onCancel={() => setPresentationMode(false)}
        width="100%"
        height="100%"
        footer={null}
      >
        <PresentationContent data={data} />
      </Modal>
    </>
  );
};

export default PresentationMode;
