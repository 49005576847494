import React, { useEffect } from "react";

const LoadingPercent = () => {
  useEffect(() => {
    let currentProgress = 0;
    let step = 0.5; // the smaller this is the slower the progress bar

    let interval = setInterval(function () {
      currentProgress += step;
      let progress =
        Math.round((Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000) /
        1000;
      document.querySelector(".progress-bar").style.width = progress + "%";
      document
        .querySelector(".progress-bar")
        .setAttribute("aria-valuenow", progress);
      document.querySelector(".progress-bar").textContent = progress + "%";

      if (progress >= 100) {
        clearInterval(interval);
      } else if (progress >= 70) {
        step = 0.1;
      }
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div style={{ padding: "1.75rem 0" }}>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped"
          role="progressbar"
          style={{ width: "0%" }}
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  );
};

export default LoadingPercent;
