import React, {useState, useEffect} from 'react';
import Rodal from "rodal";
import 'rodal/lib/rodal.css';

export default function AudioPlayer(props) {

    const styles = {
        background: '#343232',
        width: '400px',
        height: '90px',
        overflow: 'hidden',
    };

    return (
        <Rodal visible={true}
               onClose={() => props.onClose()}
               closeOnEsc={false}
               closeMaskOnClick={true}
               customStyles={styles}>
            <audio className="audio" controls>
                <source src={props.src} type="audio/mp3" />
            </audio>
        </Rodal>
    );
}