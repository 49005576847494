import "./IspotPlayer.css";

const IspotPlayer = ({ url, style, className, onClick }) => {
  const id = url.includes("/ad/")
    ? url.split("/ad/")[1].split("/")[0]
    : url.split("/a/")[1];
  return (
    <div className={`ispot-player ${className}`} onClick={onClick}>
      <iframe
        src={`https://www.ispot.tv/share/${id}`}
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          ...style,
        }}
        scrolling="no"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default IspotPlayer;
