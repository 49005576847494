import React from 'react';
import {swalDeleteForm, swalError, swalChangeAccessType, swalSuccess, swalLoading} from "../utils/swal";
import collaborationService from '../services/collaboration';
import Swal from "sweetalert2";

export default function Collaborator(props) {

    const handleDelete = id => swalDeleteForm(() => props.deleteCollaborator(id));

    const handleChangeType = id => {
        swalChangeAccessType(async typeId => {
            swalLoading();
            await collaborationService.updateAccessType(id, typeId)
                .then(result => {
                    if (result.error) {
                        Swal.close();
                        swalError(result.error);
                        return;
                    }

                    Swal.close();
                    swalSuccess(`Access type updated successfully!`);
                    props.reload();
                });
        });
    }

    return (
        <div className="uploaded-file" style={{marginLeft: '0px'}}>
            <span>{props.collaborator.email}</span>
            <span className="comment-bar">|</span>
            <span>{parseInt(props.collaborator.type) === 1 ? 'Can view' : 'Can edit'}</span>
            {
                props.showDeleteButton === true && <span className="comment-bar">|</span>
            }
            {
                props.showDeleteButton === true &&
                    <span>
                        <a className="btn-delete-file" onClick={() => handleDelete(props.collaborator._id)}>Remove Access</a>
                        <span className="comment-bar">|</span>
                        <a className="btn-change-access-type" onClick={() => handleChangeType(props.collaborator._id)}>Change</a>
                    </span>
            }
        </div>
    );
}