import { InstagramEmbed } from "react-social-media-embed";
import "../css/moodboard-masonry.css";
import ItemBar from "./ItemBar/ItemBar";

function TileInstagram({ currentFile, view, ...props }) {
  return (
    <div className={`tile ${props.id}`}>
      <ItemBar
        currentFile={currentFile}
        viewItem={() => view(currentFile._id)}
        deleteItem={() => props.delete(currentFile._id)}
      />
      {currentFile?.fileUrl && (
        <InstagramEmbed key={currentFile.fileUrl} url={currentFile.fileUrl} />
      )}
    </div>
  );
}

export default TileInstagram;
