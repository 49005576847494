import React, {useState} from "react";
import imgSrc from "../images/default-audio.png";
import DeleteOutlineSharpIcon from '@material-ui/icons/DeleteOutlineSharp';
import '../css/moodboard-masonry.css';
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {Menu, MenuItem} from "@material-ui/core";
import AudioPlayer from './AudioPlayer';

function AudioTile(props) {
    const [showAudio, setShowAudio] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    return <div className={`tile ${props.id}`} title="Click to play audio">
        
        {/*<span className='moodboard-item-icon' onClick= { e => props.delete(props.id)}><DeleteOutlineSharpIcon className='moodboard-item-icon'/></span>*/}
        <div className="tile-options">
            <MoreHorizIcon onClick={handleClick}/>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={e => {
                    setAnchorEl(null);
                    props.view(props.id);
                }}>
                    View Comments
                </MenuItem>
                <MenuItem onClick={e => {
                    setAnchorEl(null);
                    props.delete(props.id);
                }}>
                    Delete
                </MenuItem>
            </Menu>
        </div>
        {showAudio && <AudioPlayer src={props.src} onClose={() => setShowAudio(false)}/>}
        <div className="tile-audio" onClick={e => setShowAudio(true)}>
            <img className="img" src={imgSrc}/>
        </div>
    </div>
}

export default AudioTile;