import React, { useState, useEffect } from "react";
import session from "../services/session";
import moment from "moment";
import {
	swalDeleteForm,
	swalError,
	swalSuccess,
	swalInfo,
	swalUploading,
	swalConfirm
} from "../utils/swal";
import projectService from "../services/project";
import moodboardService from "../services/moodboard";
import Swal from "sweetalert2";
import "../css/project.css";
import "../css/tabs.css";
import bgImgSrc from "../images/new-project-background.jpg";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import {
	FloatingMenu,
	MainButton,
	ChildButton
} from "react-floating-button-menu";
import MdAdd from "@material-ui/icons/Add";
import MdClose from "@material-ui/icons/Clear";
import Embed from "./Embed";
import Pexels from "./Pexels";
import { useNavigate } from "react-router-dom";

export default function New(props) {
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [showPexels, setShowPexels] = useState(false);
	const [showEmbed, setShowEmbed] = useState(false);
	const [pexelsPhotos, setPexelsPhotos] = useState([]);
	const [pexelsVideos, setPexelsVideos] = useState([]);
	const [embedUrls, setEmbedUrls] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState([]);

	const [title, setTitle] = useState("");
	// const [description, setDescription] = useState('');
	// const [project, setProject] = useState(null);
	// const [moodboards, setMoodboards] = useState(null);
	// const [storyboards, setStoryboards] = useState(null);
	// const [script, setScript] = useState(``);

	useEffect(() => {
		document.getElementById("txt-project-title").focus();
	}, []);

	// useEffect(() => {
	//     reloadProject();
	//     document.querySelector('#home-tab').click();
	// }, []);

	// const reloadProject = async () => {
	//     let id = window.location.href.split('/').pop();
	//     if (id && id.length === 24) {
	//         await projectService.get(id)
	//             .then(result => {
	//                 if (result.error) {
	//                     swalError(result.error);
	//                     Swal.close();
	//                     return;
	//                 }

	//                 if (result.data.length === 1) {
	//                     loadProject(result.data[0]);
	//                 }
	//             });
	//     }
	// }

	// const loadProject = project => {
	//     setTitle(project.title);
	//     setDescription(project.description);
	//     setProject(project);
	//     setMoodboards(project.moodboards);
	//     setStoryboards(project.storyboards);
	// }

	// const validUrl = str => {
	//     const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
	//     return pattern.test(str);
	// }

	// const handleSave = async e => {
	//     e.preventDefault();

	//     if (!title || title.length === 0) {
	//         swalInfo(`Please provide project title.`);
	//         return;
	//     }

	//     let moodboard = [];
	//     let moodboardOrder = 1;
	//     document.querySelectorAll(`.moodboard .item`).forEach(x => {
	//         const type = x.querySelector(`.item-type`).value;
	//         if (type === 'video') {
	//             let title = x.querySelector(`.item-title`).value || '';
	//             let url = x.querySelector(`.item-url`).value || '';
	//             if (title && title.length > 0 && url && url.length > 0) {
	//                 moodboard.push({
	//                     id: x.querySelector(`.item-id`).value || '',
	//                     type: type,
	//                     url: url,
	//                     title: title,
	//                     description: x.querySelector(`.item-description`).value || '',
	//                     order: moodboardOrder
	//                 });
	//             }
	//         }

	//         if (type === 'audio' || type === 'image') {
	//             let title = x.querySelector(`.item-title`).value || '';
	//             let file = x.querySelector(`.item-file`).files[0] || null;
	//             if (title && title.length > 0) {
	//                 moodboard.push({
	//                     id: x.querySelector(`.item-id`).value || '',
	//                     type: type,
	//                     file: file,
	//                     title: title,
	//                     description: x.querySelector(`.item-description`).value || '',
	//                     order: moodboardOrder
	//                 });
	//             }
	//         }

	//         moodboardOrder = moodboardOrder + 1;
	//     });

	//     let storyboard = [];
	//     let storyboardOrder = 1;
	//     document.querySelectorAll(`.storyboard .item`).forEach(x => {
	//         let title = x.querySelector(`.item-title`).value || '';
	//         let file = x.querySelector(`.item-file`).files[0] || null;
	//         if (title && title.length > 0) {
	//             storyboard.push({
	//                 id: x.querySelector(`.item-id`).value || '',
	//                 file: file,
	//                 title: title,
	//                 description: x.querySelector(`.item-description`).value || '',
	//                 shot: x.querySelector(`.item-shot`).value || '',
	//                 angle: x.querySelector(`.item-angle`).value || '',
	//                 movement: x.querySelector(`.item-movement`).value || '',
	//                 audio: x.querySelector(`.item-audio`).value || '',
	//                 order: storyboardOrder
	//             });
	//             storyboardOrder = storyboardOrder + 1;
	//         }
	//     });

	//     // if (!moodboard || moodboard.length === 0) {
	//     //     swalInfo(`Please provide atleast one item.`);
	//     //     return;
	//     // }
	//     //
	//     // let isValid = true;
	//     // moodboard.forEach(x => {
	//     //     if(x.type === 'video') {
	//     //         if (x.url.length === 0 || !validUrl(x.url) || x.title.length === 0)
	//     //             isValid = false;
	//     //     }
	//     //     else if(x.type === 'audio' || x.type === 'image') {
	//     //         if(x.title.length === 0)
	//     //             isValid = false;
	//     //
	//     //         if(x.id === '')
	//     //             if (x.file === null || x.file.size === 0)
	//     //                 isValid = false;
	//     //     }
	//     // });
	//     //
	//     // if (!isValid) {
	//     //     swalInfo(`Please provide valid data.`);
	//     //     return;
	//     // }

	//     const logo = document.getElementById('file-project-logo').files[0];
	//     const obj = {
	//         title: title,
	//         description: description,
	//         date: moment().format(),
	//         userId: session.get('user').id,
	//         file: logo
	//     };

	//     if (project && project._id) {
	//         swalUploading();
	//         await projectService.update(project._id, obj)
	//             .then(async result => {
	//                 if (result.error) {
	//                     swalError(result.error);
	//                     Swal.close();
	//                     return;
	//                 }

	//                 if (result.data) {
	//                     swalUploading();
	//                     await updateAndUploadMoodboards(moodboard, result.data);
	//                     await updateAndUploadStoryboards(storyboard, result.data);
	//                     Swal.close();
	//                     swalSuccess(`Project updated successfully!`);
	//                     navigate(`/projects/${project._id}`);
	//                 }
	//             });
	//     } else {
	//         swalUploading();
	//         await projectService.add(obj)
	//             .then(async result => {
	//                 if (result.error) {
	//                     swalError(result.error);
	//                     Swal.close();
	//                     return;
	//                 }

	//                 if (result.data) {
	//                     swalUploading();
	//                     await saveScript(script, result.data);
	//                     await saveAndUploadMoodboards(moodboard, result.data);
	//                     await saveAndUploadStoryboards(storyboard, result.data);
	//                     Swal.close();
	//                     swalSuccess(`Project created successfully!`);
	//                     navigate(`/projects/${result.data._id}`);
	//                 }
	//             });
	//     }
	// }

	// const saveScript = (scriptJson, project) => {
	//     return new Promise(async (resolve, reject) => {
	//         await scriptService.add(scriptJson, project._id);
	//         resolve(true);
	//     });
	// }

	// const saveAndUploadMoodboards = async (arr, project) => {
	//     return new Promise(async (resolve, reject) => {
	//         for (let i = 0; i < arr.length; i++) {
	//             const k = arr[i];
	//             if (k.type === 'video') {
	//                 await moodboardService.add({
	//                     order: k.order,
	//                     title: k.title,
	//                     description: k.description,
	//                     type: k.type,
	//                     projectId: project._id,
	//                     fileUrl: k.url,
	//                     fileName: null,
	//                     fileSize: 0,
	//                     fileKey: null,
	//                     userId: session.get('user').id,
	//                     date: moment().format()
	//                 });
	//             } else {
	//                 await moodboardService.add({
	//                     order: k.order,
	//                     title: k.title,
	//                     description: k.description,
	//                     type: k.type,
	//                     projectId: project._id,
	//                     file: k.file,
	//                     userId: session.get('user').id,
	//                     date: moment().format()
	//                 });
	//             }
	//         }
	//         resolve(true);
	//     });
	// }

	const saveMoodboards = async (arr, project) => {
		return new Promise(async (resolve, reject) => {
			for (let i = 0; i < arr.length; i++) {
				const k = arr[i];
				await moodboardService.add({
					order: k.order,
					title: k.title,
					description: k.description,
					type: k.type,
					projectId: project._id,
					fileUrl: k.url,
					fileName: null,
					fileSize: 0,
					fileKey: null,
					userId: session.get("user").id,
					date: moment().format()
				});
			}
			resolve(true);
		});
	};

	const uploadMoodboards = async (arr, project) => {
		return new Promise(async (resolve, reject) => {
			for (let i = 0; i < arr.length; i++) {
				const k = arr[i];
				await moodboardService.add({
					order: k.order,
					title: k.title,
					description: k.description,
					type: k.type,
					projectId: project._id,
					file: k.file,
					userId: session.get("user").id,
					date: moment().format()
				});
			}
			resolve(true);
		});
	};

	// const updateAndUploadMoodboards = async (arr, project) => {
	//     return new Promise(async (resolve, reject) => {
	//         for (let i = 0; i < arr.length; i++) {
	//             const k = arr[i];
	//             if (k.type === 'video') {
	//                 if (k.id) {
	//                     await moodboardService.update(k.id, {
	//                         order: k.order,
	//                         title: k.title,
	//                         description: k.description,
	//                         fileUrl: k.url,
	//                     });
	//                 } else {
	//                     await moodboardService.add({
	//                         order: k.order,
	//                         title: k.title,
	//                         description: k.description,
	//                         type: k.type,
	//                         projectId: project._id,
	//                         fileUrl: k.url,
	//                         fileName: null,
	//                         fileSize: 0,
	//                         fileKey: null,
	//                         userId: session.get('user').id,
	//                         date: moment().format()
	//                     });
	//                 }
	//             }

	//             if (k.type === 'audio' || k.type === 'image') {
	//                 if (k.id) {
	//                     await moodboardService.update(k.id, {
	//                         order: k.order,
	//                         title: k.title,
	//                         description: k.description,
	//                         file: k.file,
	//                     });
	//                 } else {
	//                     await moodboardService.add({
	//                         order: k.order,
	//                         title: k.title,
	//                         description: k.description,
	//                         type: k.type,
	//                         projectId: project._id,
	//                         file: k.file,
	//                         userId: session.get('user').id,
	//                         date: moment().format()
	//                     });
	//                 }
	//             }
	//         }
	//         resolve(true);
	//     });
	// }

	// const saveAndUploadStoryboards = async (arr, project) => {
	//     return new Promise(async (resolve, reject) => {
	//         for (let i = 0; i < arr.length; i++) {
	//             const k = arr[i];
	//             await storyboardService.add({
	//                 order: k.order,
	//                 title: k.title,
	//                 description: k.description,
	//                 shot: k.shot,
	//                 angle: k.angle,
	//                 movement: k.movement,
	//                 audio: k.audio,
	//                 projectId: project._id,
	//                 file: k.file,
	//                 userId: session.get('user').id,
	//                 date: moment().format()
	//             });
	//         }
	//         resolve(true);
	//     });
	// }

	// const updateAndUploadStoryboards = async (arr, project) => {
	//     return new Promise(async (resolve, reject) => {
	//         for (let i = 0; i < arr.length; i++) {
	//             const k = arr[i];
	//             if (k.id) {
	//                 await storyboardService.update(k.id, {
	//                     order: k.order,
	//                     title: k.title,
	//                     description: k.description,
	//                     shot: k.shot,
	//                     angle: k.angle,
	//                     movement: k.movement,
	//                     audio: k.audio,
	//                     file: k.file,
	//                 });
	//             } else {
	//                 await storyboardService.add({
	//                     order: k.order,
	//                     title: k.title,
	//                     description: k.description,
	//                     shot: k.shot,
	//                     angle: k.angle,
	//                     movement: k.movement,
	//                     audio: k.audio,
	//                     projectId: project._id,
	//                     file: k.file,
	//                     userId: session.get('user').id,
	//                     date: moment().format()
	//                 });
	//             }
	//         }
	//         resolve(true);
	//     });
	// }

	// const handleDeleteProject = e => {
	//     e.preventDefault();
	//     if (!project || !project._id)
	//         return;
	//     swalDeleteForm(async () => {
	//         await projectService.delete(project._id)
	//             .then(result => {
	//                 if (result.error) {
	//                     swalError(result.error);
	//                     Swal.close();
	//                     return;
	//                 }
	//                 swalSuccess(`Project deleted successfully!`);
	//                 navigate(`/projects`);
	//             });
	//     });
	// }

	// const handleDeleteCurrentLogo = e => {
	//     e.preventDefault();
	//     if (!project || !project._id)
	//         return;

	//     swalDeleteForm(async () => {
	//         await projectService.deleteLogo(project._id)
	//             .then(result => {
	//                 if (result.error) {
	//                     swalError(result.error);
	//                     return;
	//                 }
	//                 swalSuccess(`Project logo deleted successfully!`);
	//                 window.location.reload();
	//             });
	//     });
	// }

	const onSelectPexels = data => {
		let t = Array.from(pexelsPhotos) || [];
		data.photos.forEach(url => t.push(url));
		setPexelsPhotos(t);

		let tt = Array.from(pexelsVideos) || [];
		data.videos.forEach(url => tt.push(url));
		setPexelsVideos(tt);
	};

	const onSelectEmbed = url => {
		let t = Array.from(embedUrls) || [];
		t.push(url);
		setEmbedUrls(t);
	};

	const handleSave = async e => {
		e.preventDefault();

		if (!title || title.length === 0) {
			swalInfo(`Please provide project title.`);
			return;
		}

		let moodboard = [];
		let moodboardOrder = 1;

		pexelsPhotos.forEach(url => {
			moodboard.push({
				type: "image",
				url: url,
				title: ``,
				description: ``,
				order: moodboardOrder
			});
			moodboardOrder += 1;
		});

		pexelsVideos.forEach(url => {
			moodboard.push({
				type: "video",
				url: url,
				title: ``,
				description: ``,
				order: moodboardOrder
			});
			moodboardOrder += 1;
		});

		embedUrls.forEach(url => {
			moodboard.push({
				type: "video",
				url: url,
				title: ``,
				description: ``,
				order: moodboardOrder
			});
			moodboardOrder += 1;
		});

		let moodboardFiles = [];
		const files = document.getElementById("txt-selected-files").files;
		for (let i = 0; i < files.length; i++) {
			let file = files[i];
			moodboardFiles.push({
				type: file.type,
				file: file,
				title: file.name,
				description: ``,
				order: moodboardOrder
			});
			moodboardOrder += 1;
		}

		if (!moodboard || moodboard.length === 0) {
			swalInfo(`Please provide atleast one moodboard item.`);
			return;
		}

		const obj = {
			title: title,
			description: ``,
			date: moment().format(),
			userId: session.get("user").id
			// file: logo
		};

		swalUploading();
		await projectService.add(obj).then(async result => {
			if (result.error) {
				swalError(result.error);
				Swal.close();
				return;
			}

			if (result.data) {
				await saveMoodboards(moodboard, result.data);
				await uploadMoodboards(moodboardFiles, result.data);
				Swal.close();
				swalSuccess(`Project created successfully!`);
				navigate(`/projects/${result.data._id}`);
			}
		});
	};

	const handleCancel = e => {
		e.preventDefault();
		if (
			title.length > 0 ||
			pexelsPhotos.length > 0 ||
			pexelsVideos.length > 0 ||
			embedUrls.length > 0 ||
			document.getElementById("txt-selected-files").files.length > 0
		) {
			swalConfirm(() => navigate(`/`));
		} else {
			navigate(`/`);
		}
	};

	return (
		<div
			className="container-fluid text-center"
			style={{ padding: "0px", overflowY: "hidden" }}
		>
			{showPexels && (
				<Pexels
					onClose={() => setShowPexels(false)}
					onSelect={onSelectPexels}
				/>
			)}
			{showEmbed && (
				<Embed
					onClose={() => setShowEmbed(false)}
					onSelect={onSelectEmbed}
				/>
			)}
			<img className="new-project-background" src={bgImgSrc} />
			<input
				type="text"
				className="form-control txt-project-title"
				id="txt-project-title"
				placeholder="Project Title"
				value={title}
				onChange={e => setTitle(e.target.value)}
			/>
			<div className="btns-project">
				<button
					className="btn btn-styled mt-0 btn-new m-1"
					onClick={handleSave}
				>
					Save project
				</button>
				<button
					className="btn btn-styled mt-0 btn-new m-1"
					onClick={handleCancel}
				>
					Back to dashboard
				</button>
			</div>
			<input
				id="txt-selected-files"
				type="file"
				style={{ display: "none" }}
				accept=".jpg,.jpeg,.png,.mp4.mp3"
				multiple
			/>
			<FloatingMenu
				slideSpeed={500}
				direction="up"
				spacing={8}
				isOpen={isOpen}
				style={{ position: "fixed", bottom: "5vh", right: "5vw" }}
			>
				<MainButton
					iconResting={<MdAdd />}
					iconActive={<MdClose />}
					backgroundColor="black"
					onClick={() => setIsOpen(!isOpen)}
					size={45}
					className="btn-floating-main"
					title="Add photos, videos and files."
				/>
				<ChildButton
					icon={<VideoLibraryIcon />}
					size={40}
					className="btn-floating-child"
					onClick={() => setShowEmbed(true)}
					title="Embed YouTube/Vimeo videos."
				/>
				<ChildButton
					icon={<InsertDriveFileIcon />}
					className="btn-floating-child"
					size={40}
					onClick={() =>
						document.getElementById("txt-selected-files").click()
					}
					title="Upload audio/photos files from your computer."
				/>
				<ChildButton
					icon={<AddPhotoAlternateIcon />}
					className="btn-floating-child"
					size={40}
					onClick={() => setShowPexels(true)}
					title="Add photos/videos directly from Pexels."
				/>
			</FloatingMenu>
		</div>
	);
}
