import React, { useState, useEffect, useMemo } from "react";
import projectService from "../services/project";
import {
  swalError,
  swalPackageLimitReached,
  swalLoading,
  swalUploading,
  swalSuccess,
} from "../utils/swal";
import session from "../services/session";
import { Redirect } from "react-router-dom";
import userService from "../services/user";
import { getPackage } from "../utils/utils";
import Swal from "sweetalert2";
import "../css/project-page.css";
import "../css/projects.css";
import "../css/tabs.css";
import moment from "moment";
import Tour from "reactour";
import ProjectBox from "./ProjectBox";
import { useNavigate } from "react-router-dom";
import SearchPage from "./SearchPage/SearchPage";

export default function Projects(props) {
  const navigate = useNavigate();
  const [nameSorting, setNameSorting] = useState("");
  const [dateSorting, setDateSorting] = useState("");
  const [data, setData] = useState([]);
  const [userPackage, setUserPackage] = useState({});
  const [sharedWithMe, setSharedWithMe] = useState([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("project");
  const steps = [
    {
      selector: ".projects-selector",
      content: "This is where all projects that you created are listed.",
      style: {
        backgroundColor: "#000000",
      },
    },
    {
      selector: ".shared-projects-selector",
      content:
        "Shared projects are projects where someone invites you to their board.",
      style: {
        backgroundColor: "#000000",
      },
    },
    {
      selector: ".new-project-button-selector",
      content: "Create your new project here",
      style: {
        backgroundColor: "#000000",
      },
    },
  ];

  const isLoggedIn = useMemo(() => {
    return session.get("user");
  }, [session]);

  useEffect(() => {
    if (document.getElementById("txt-project-title"))
      document.getElementById("txt-project-title").style.display = "none";

    reload();
  }, []);

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const t = await userService.getPackage();
        if (t && t.data) {
          const pkg = getPackage(t.data.id);
          if (pkg) {
            setUserPackage(pkg);
          }
        }
      } else {
        // if not loggin
        // will hide Projects tab and active Search tab
        const searchTabButton = document.querySelector('a[href="#search"]');
        searchTabButton.click();
      }
    })();
  }, []);

  const reload = async () => {
    if (session.get("user")) {
      const keyword =
        (document.getElementById("txtSearchProjects") &&
          document.getElementById("txtSearchProjects").value) ||
        ``;
      await projectService.getAll(keyword).then((result) => {
        if (result.error) {
          Swal.close();
          swalError(result.error);
          return;
        }

        if (result.data) {
          setData(result.data);
          if (result.data.length === 4) {
            //If user has not created any projects
            if (
              result.data[0].title === "Brand" &&
              result.data[1].title === "Real Estate" &&
              result.data[2].title === "Athletics" &&
              result.data[3].title === "Product"
            ) {
              setIsTourOpen(true);
            }
          }
          Swal.close();
        }
      });

      await projectService.getSharedWithMe(keyword).then((result) => {
        if (result.error) {
          Swal.close();
          swalError(result.error);
          return;
        }

        if (result.data) {
          setSharedWithMe(result.data);
          Swal.close();
        }
      });
    }
  };

  const newProjectBox = () => (
    <div
      className="project-box new-project-box new-project-button-selector"
      onClick={handleCreate}
    >
      <div className="new-project-box-text">Create New Project</div>
    </div>
  );

  const renderProjects = () =>
    data.map((project) => (
      <ProjectBox reload={reload} key={project._id} project={project} />
    ));

  const renderSharedWithMeProjects = () =>
    sharedWithMe.map((project) => (
      <ProjectBox reload={reload} key={project._id} project={project} />
    ));

  const handleCreate = async (e) => {
    e.preventDefault();
    if (userPackage.projectsLimit) {
      if (data.length >= userPackage.projects) {
        swalPackageLimitReached(
          `Package limit reached`,
          `You have reached your projects limit.`
        );
        return;
      }
    }

    const obj = {
      title: ``,
      description: ``,
      date: moment().format(),
      userId: session.get("user").id,
    };

    await projectService.add(obj).then((result) => {
      if (result.data) {
        navigate(`/n/${result.data._id}`);
      }
    });
  };

  const handleSortByName = async (e) => {
    e.preventDefault();
    const keyword =
      (document.getElementById("txtSearchProjects") &&
        document.getElementById("txtSearchProjects").value) ||
      ``;
    await projectService.getAll(keyword).then((result) => {
      if (result.error) {
        Swal.close();
        swalError(result.error);
        return;
      }

      let sortedData = [];
      if (nameSorting === "" || nameSorting === "z-a") {
        setNameSorting("a-z");
        document.getElementById(
          "btn-sort-name"
        ).innerHTML = `Name &nbsp;<i class="fa fa-sort-alpha-down"></i>`;
        sortedData = result.data.sort((a, b) =>
          a.title.toLowerCase().localeCompare(b.title.toLowerCase())
        );
      } else if (nameSorting === "a-z") {
        setNameSorting("z-a");
        document.getElementById(
          "btn-sort-name"
        ).innerHTML = `Name &nbsp;<i class="fa fa-sort-alpha-up"></i>`;
        sortedData = result.data.sort((a, b) =>
          b.title.toLowerCase().localeCompare(a.title.toLowerCase())
        );
      }
      document.getElementById("btn-sort-date").innerHTML = "Date";
      setData(sortedData);
    });
  };

  const handleSortByDate = async (e) => {
    e.preventDefault();
    const keyword =
      (document.getElementById("txtSearchProjects") &&
        document.getElementById("txtSearchProjects").value) ||
      ``;
    await projectService.getAll(keyword).then((result) => {
      if (result.error) {
        Swal.close();
        swalError(result.error);
        return;
      }

      let sortedData = [];
      if (nameSorting === "" || nameSorting === "z-a") {
        setNameSorting("a-z");
        document.getElementById(
          "btn-sort-date"
        ).innerHTML = `Date &nbsp;<i class="fa fa-sort-amount-down"></i>`;
        sortedData = result.data.sort(
          (a, b) => moment(b.date) - moment(a.date)
        );
      } else if (nameSorting === "a-z") {
        setNameSorting("z-a");
        document.getElementById(
          "btn-sort-date"
        ).innerHTML = `Date  &nbsp;<i class="fa fa-sort-amount-up"></i>`;
        sortedData = result.data.sort(
          (a, b) => moment(a.date) - moment(b.date)
        );
      }
      document.getElementById("btn-sort-name").innerHTML = "Name";
      setData(sortedData);
    });
  };

  const onClickSearchTab = () => {
    document.getElementById("search").style.display = "grid";
    document.getElementById("profile").style.display = "none";
    document.getElementById("home").style.display = "none";
    setCurrentTab("search");
  };

  return (
    <div className="container container-wrapper">
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <div className="row mt-20 row-wrapper">
        <div className="col-12 col-sm-12 d-flex text-cente project-panel">
          <div className="tabbable-panel">
            <div className="tabbable-line">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link projects-selector"
                    id="home-tab"
                    data-toggle="tab"
                    href="#search"
                    role="tab"
                    style={{ paddingLeft: "0px" }}
                    aria-controls="search"
                    aria-selected="true"
                    onClick={onClickSearchTab}
                  >
                    Search
                  </a>
                </li>
                {isLoggedIn && (
                  <li className="nav-item">
                    <a
                      className="nav-link active projects-selector"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                      onClick={() => (
                        (document.getElementById("profile").style.display =
                          "none"),
                        (document.getElementById("search").style.display =
                          "none"),
                        (document.getElementById("home").style.display =
                          "grid"),
                        setCurrentTab("project"),
                        reload()
                      )}
                    >
                      Projects
                    </a>
                  </li>
                )}
                {session.get("user") && (
                  <li className="nav-item shared-projects-selector">
                    <a
                      className="nav-link"
                      id="profile-tab"
                      data-toggle="tab"
                      href="#profile"
                      role="tab"
                      aria-controls="profile"
                      aria-selected="false"
                      onClick={() => (
                        (document.getElementById("profile").style.display =
                          "grid"),
                        (document.getElementById("home").style.display =
                          "none"),
                        (document.getElementById("search").style.display =
                          "none"),
                        setCurrentTab("shared")
                      )}
                    >
                      Shared Projects
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
          {/*
                    <div className="col text-right">
                    {
                        session.get('user') &&
                        <button className="btn btn-styled mt-0 btn-pink share-button new-project-button-selector"
                                onClick={handleCreate}>New Project</button>
                    }
                </div>*/}
        </div>
      </div>

      <div className="row mt-20 mx-auto">
        <div className="col projectsPad">
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade"
              id="search"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              {currentTab === "search" && <SearchPage />}
            </div>

            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="col text-center mb-10">
                <input
                  type="text"
                  className="form-control form-control-3"
                  onChange={(e) => reload()}
                  placeholder="Search Projects..."
                  id="txtSearchProjects"
                />
              </div>
              <div className="text-left">
                <span style={{ marginRight: 15 }}>Sort By</span>
                <button
                  className="btn btn-dark m-1"
                  id="btn-sort-name"
                  onClick={handleSortByName}
                >
                  Name
                </button>
                <button
                  className="btn btn-dark m-1"
                  id="btn-sort-date"
                  onClick={handleSortByDate}
                >
                  Date
                </button>
              </div>
              <div className="text-center test mt-20">
                {session.get("user") && newProjectBox()}
                {data && renderProjects()}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <div className="col text-center mb-10 absolute top-0">
                <input
                  type="text"
                  className="form-control form-control-3"
                  onChange={(e) => reload()}
                  placeholder="Search Projects..."
                  id="txtSearchProjects"
                />
              </div>
              {(sharedWithMe.length > 0 && renderSharedWithMeProjects()) || (
                <div className="not-found">No projects found.</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
