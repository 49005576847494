import { Tabs } from "antd";
import { useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./DraggableTabs.css";

const type = "DraggableTabNode";
const DraggableTabNode = ({ index, children, moveNode }) => {
  const ref = useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
      };
    },
    drop: (item) => {
      moveNode(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  // Style
  const style = {
    marginRight: 24,
  };
  if (isOver) {
    style.transition = "all 0.3s";
  }
  return (
    <div ref={ref} style={style}>
      {children}
    </div>
  );
};

const DraggableTabs = (props) => {
  const {
    items = [],
    onClickTab,
    onDoubleClickTab,
    onDeleteTab,
    onUpdateTabOrder,
  } = props;
  const [order, setOrder] = useState([]);

  const moveTabNode = (dragKey, hoverKey) => {
    const newOrder = order.slice();
    items.forEach((item) => {
      if (item.key && newOrder.indexOf(item.key) === -1) {
        newOrder.push(item.key);
      }
    });
    const dragIndex = newOrder.indexOf(dragKey);
    const hoverIndex = newOrder.indexOf(hoverKey);
    newOrder.splice(dragIndex, 1);
    newOrder.splice(hoverIndex, 0, dragKey);
    setOrder(newOrder);

    onUpdateTabOrder(newOrder);
  };
  const renderTabBar = (tabBarProps, DefaultTabBar) => {
    return (
      <DefaultTabBar {...tabBarProps}>
        {(node) => {
          const findItem = items.find((item) => item.key === node.key);
          return (
            <div
              id="hello"
              onDoubleClick={() =>
                onDoubleClickTab(findItem.label, findItem.key)
              }
              className="btn-tab"
            >
              <DraggableTabNode
                key={node.key}
                index={node.key}
                moveNode={moveTabNode}
              >
                {node}
              </DraggableTabNode>
            </div>
          );
        }}
      </DefaultTabBar>
    );
  };
  const orderItems = [...items].sort((a, b) => {
    const orderA = order.indexOf(a.key);
    const orderB = order.indexOf(b.key);
    if (orderA !== -1 && orderB !== -1) {
      return orderA - orderB;
    }
    if (orderA !== -1) {
      return -1;
    }
    if (orderB !== -1) {
      return 1;
    }
    const ia = items.indexOf(a);
    const ib = items.indexOf(b);
    return ia - ib;
  });

  const onEditTab = (targetKey, action) => {
    if (action === "remove") {
      onDeleteTab(targetKey);
    }
  };

  return (
    <DndProvider backend={HTML5Backend} className="draggable-tabs">
      <Tabs
        hideAdd
        renderTabBar={renderTabBar}
        type="editable-card"
        {...props}
        onTabClick={(tabId) => onClickTab(tabId)}
        items={orderItems}
        onEdit={onEditTab}
      />
    </DndProvider>
  );
};

export default DraggableTabs;
