import React, { useState, useEffect } from "react";
import session from "../services/session";
import moment from "moment";
import {
  swalDeleteForm,
  swalError,
  swalSuccess,
  swalComment,
} from "../utils/swal";
import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import "./CommentBox.scss";

export default function CommentBox(props) {
  const handleEdit = (e) => {
    e.preventDefault();
    const obj = {
      id: props.comment._id,
      text: props.comment.text,
    };

    props.setCommentToUpdate(obj);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    swalDeleteForm(() => {
      props.deleteComment(props.comment._id);
    });
  };

  const replacePattern = (text) => {
    // Regular expression to find the pattern
    const pattern = /\[\[({.*?})\]\]/g;

    return text.replace(pattern, (match) => {
      // Remove the surrounding brackets and parse the JSON
      const jsonString = match.slice(2, -2);
      const data = JSON.parse(jsonString);

      const { title } = data;

      // Create the replacement HTML
      return `
        <span class="avatar-user">
          ${title?.match(/\b(\w)/g)?.join("")}
        </span>
        <span>${title}</span>
      `;
    });
  };

  const items = [
    {
      label: <div onClick={handleEdit}>Edit</div>,
      key: "0",
    },
    {
      label: <div onClick={handleDelete}>Delete</div>,
      key: "1",
    },
  ];

  return (
    <div className="comment-box">
      <div className="row">
        <div
          className="col-2 col-sm-2 col-md-2 text-center"
          style={{ marginRight: "8px" }}
        >
          {/* <img
            className="img-circle"
            src={props.comment.userId && props.comment.userId.picture}
          /> */}
          <div className="img-circle">
            {props.comment.userId?.fullname?.match(/\b(\w)/g)?.join("")}
          </div>
        </div>
        <div
          className="col-6 col-sm-6 col-md-6 text-left"
          style={{ maxWidth: "40%" }}
        >
          <p style={{ paddingLeft: "10px" }}>
            <span className="comment-username">
              {props.comment.userId && props.comment.userId.fullname}
            </span>
            <br />
            <span className="text-right comment-date">
              {moment(props.comment.date).fromNow()}
            </span>
          </p>
        </div>
        <div className="col-4 col-sm-4 col-md-4 comment-icons">
          {session.get("user")._id === props.comment.userId._id && (
            <Dropdown
              menu={{
                items,
              }}
              arrow
              getPopupContainer={(node) => node}
              placement="bottom"
              trigger={["click"]}
            >
              <EllipsisOutlined className="more-icon-comment" />
            </Dropdown>
          )}
        </div>
      </div>
      <div className="row">
        <div
          className="col-12 col-sm-12 col-md-12 text-left render-comment"
          dangerouslySetInnerHTML={{
            __html: replacePattern(props.comment.text),
          }}
        ></div>
      </div>
    </div>
  );
}
