import YouTube from "react-youtube";
import "./YoutubePlayer.css";

const youtubeConfig = {
  height: "390",
  width: "100%",
  key: "AIzaSyBQnWHiZfFRdcePNKnKgAHLhnPB92G3z7Y",
};
const YoutubePlayer = ({ url, config, style }) => {
  return (
    <YouTube
      videoId={url?.split("shorts/")[1]}
      className="youtube-player"
      opts={{ ...youtubeConfig, ...config }}
      style={style}
    />
  );
};

export default YoutubePlayer;
