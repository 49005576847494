import "../css/moodboard-masonry.css";
import { MenuItem } from "@material-ui/core";
import ReactPlayer from "react-player";
import ItemBar from "./ItemBar/ItemBar";
import { useRef } from "react";

function TileYoutube({ videoInfo, view, edit, ...props }) {
  const itemBarRef = useRef();

  let id = new URL(props.src).searchParams.get("v");
  let src = `https://www.youtube.com/embed/${id}`;
  return (
    <div className={`tile ${props.id}`}>
      <ItemBar
        ref={itemBarRef}
        currentFile={videoInfo}
        viewItem={() => view(videoInfo._id)}
        deleteItem={() => props.delete(videoInfo._id)}
      >
        <MenuItem
          onClick={(e) => {
            itemBarRef.current.closeMenu();
            edit(videoInfo);
          }}
        >
          Edit
        </MenuItem>
      </ItemBar>

      <ReactPlayer
        className="player-yt"
        url={src}
        // playing={true}
        controls={true}
        light={
          (!videoInfo?.thumbnailUrl?.includes("null") &&
            videoInfo.thumbnailUrl) ||
          false
        }
      />
    </div>
  );
}

export default TileYoutube;
