import React, {useState, useEffect} from 'react';
import ReactJWPlayer from 'react-jw-player';

export default function PexelVideo(props) {

    const [selected, setSelected] = useState(false);

    useEffect(() => {
        (selected === true) ? props.selectVideo(props.video) : props.deselectVideo(props.video);
    }, [selected]);

    const selectVideo = () => {
        setSelected(!selected)
    }

    return (
        <div className="pexel-video-block mt-20">
            <input type="checkbox" id={`pexel-video-${props.video.id}`} onChange={selectVideo} />
            <label htmlFor={`pexel-video-${props.video.id}`}>
                <div className="btn btn-sm btn-outline-light m-1" onChange={selectVideo}>
                    {!selected ? <div>Select Video</div>: <div>Deselect Video</div>}
                </div>
            </label>
            <ReactJWPlayer
                    className="video-pexel"
                    playerId={props.video.id.toString()}
                    playerScript='https://cdn.jwplayer.com/libraries/s7lNp89j.js'
                    file={props.video.video_files[0].link}
                    image={props.video.image.replace("h=630", "h=200").replace("w=1200", "w=280")}
                />
                {/* <video className="video-pexel" controls>
                    { props.video.video_files.map(x => <source key={x.id} src={x.link} type={x.file_type} autostart="false" />) }
                </video> */}
        </div>
    );
}