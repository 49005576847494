import axios from "axios";
import { useState } from "react";
import { PartitionOutlined } from "@ant-design/icons";
import { Button, Input, InputNumber, Modal, Row } from "antd";

import { uploadToS3 } from "components/N";

import "./AIGeneration.scss";

function getRandomInt(max = 999999999999999999999999999) {
  return Math.floor(Math.random() * max);
}

function base64ToFile(base64String, fileName, mimeType = "image/png") {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], {
    type: mimeType || "application/octet-stream",
  });

  return new File([blob], fileName + getRandomInt(), {
    type: mimeType || "application/octet-stream",
  });
}

const AIGeneration = ({ onAddImage, className }) => {
  const [prompt, setPrompt] = useState("");
  const [numberOfImg, setNumberOfImg] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const downloadImage = async (base64) => {
    const imageFile = base64ToFile(base64, prompt);
    try {
      const uploadedUrl = await uploadToS3([{ file: imageFile }]);
      await onAddImage([{ url: uploadedUrl }]);
    } catch (e) {
      console.log("Error");
    }
  };

  const generateImage = async () => {
    const apiKey = process.env.REACT_APP_OPENAI_KEY;
    const apiUrl = "https://api.openai.com/v1/images/generations";

    setLoading(true);
    try {
      const response = await axios.post(
        apiUrl,
        {
          prompt,
          n: 1,
          quality: "hd",
          model: "dall-e-3",
          response_format: "b64_json",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
          },
        }
      );

      await downloadImage(response.data.data[0].b64_json);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error making API request:", error);
    } finally {
      setLoading(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        wrapClassName="ai-modal"
        title={null}
        footer={null}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        destroyOnClose
      >
        <Input
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Input your prompt"
        />
        <Row className="number-img-btn">
          {/* Number of images: */}
          <Row className="row-ai">
            <InputNumber
              min={1}
              value={numberOfImg}
              onChange={(value) => setNumberOfImg(value)}
              className="input-number"
            />
            <Button
              onClick={generateImage}
              loading={loading}
              disabled={prompt.length === 0}
              className="generate-btn"
            >
              Generate
            </Button>
          </Row>
        </Row>
      </Modal>
      {className ? (
        <div
          className={className}
          style={{ alignItems: "center" }}
          onClick={showModal}
        >
          <PartitionOutlined
            style={{
              marginInline: "4px",
              fontSize: "28px",
            }}
          />
          Generate AI
        </div>
      ) : (
        <a
          className="dropdown-item"
          onClick={showModal}
          style={{ display: "flex", alignItems: "center" }}
        >
          <PartitionOutlined style={{ marginInline: "4px" }} />
          Generate AI
        </a>
      )}
    </>
  );
};

export default AIGeneration;
