import React, { useState, useEffect } from "react";
import collaborationService from "../services/collaboration";
import session from "../services/session";
import { getUrlParam } from "../utils/utils";
import { NavLink } from "react-router-dom";

export default function Activate(props) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(null);
  const token = getUrlParam(window.location.href, "token", "");

  useEffect(() => {
    if (!token || token.length !== 24) {
      setErrorMessage(
        `Invalid activation token. Please do not change the provided URL.`
      );
      return;
    }

    collaborationService.acceptInvite(token).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setData(null);
        return;
      }

      if (result.data) {
        setErrorMessage(null);
        setData(result.data);
      }
    });
  }, []);

  return (
    <div
      className="container-fluid text-center div-login"
      style={{ marginTop: "50px", width: "600px" }}
    >
      <div className="row">
        <div className="col-12">
          <h2 className="m-4">
            {data && data.status === true
              ? `Invitation Accepted`
              : `Couldn't complete your request`}
          </h2>
          {data && !session.get("user") && (
            <>
              <p style={{ fontSize: "20px" }}>
                Please login or signup to begin collaborating.
              </p>
              <NavLink to="/login" className="btn btn-pink pad40 mr-10">
                Login
              </NavLink>
              <NavLink
                to={`/signup?token=${token}`}
                className="btn btn-light pad40"
              >
                Signup
              </NavLink>
            </>
          )}
          {data && session.get("user") ? (
            <>
              <br />
              <NavLink
                to={`/n/${data.projectId}`}
                className="btn btn-pink pad40 mt-30"
              >
                Take me to project
              </NavLink>
            </>
          ) : (
            <></>
          )}
          {errorMessage && <h2 className="m-4">{errorMessage}</h2>}
        </div>
      </div>
    </div>
  );
}
