import React, { useState, useEffect } from "react";
import userService from "../services/user";
import collaborationService from "../services/collaboration";
import session from "../services/session";

import { Redirect } from "react-router-dom";
import {
  swalError,
  swalInfo,
  swalLoading,
  swalShare,
  swalSuccess,
} from "../utils/swal";
import { validateEmail } from "../utils/utils";
import Swal from "sweetalert2";
import Rodal from "rodal";
import "rodal/lib/rodal.css";
import Collaborators from "./Collaborators";

export default function Collaborate(props) {
  const [list, setList] = useState("");
  const [count, setCount] = useState(1);

  const styles = {
    background: "rgb(25, 26, 31)",
    width: "70%",
    height: "80%",
    maxHeight: "calc(100vh - 210px)",
    overflowY: "auto",
    textAlign: "left",
    padding: "40px",
    color: "white",
  };

  const handleShare = (e) =>
    swalShare(
      `${process.env.HTTPS ? "https" : "http"}://app.filmboard.io/n/${
        props.projectId
      }`
    );

  const handleDone = async (e) => {
    e.preventDefault();

    if (!props.projectId) return;

    const accessType = document.getElementById("ddlAccessType").value;
    const validEmails = [];
    list.split(",").forEach((email) => {
      email = email.trim();
      if (validateEmail(email)) validEmails.push(email);
    });

    if (validEmails.length === 0) {
      swalInfo(`No valid emails found.`);
      return;
    }

    swalLoading();
    await collaborationService
      .addMany(validEmails, accessType, props.projectId)
      .then((result) => {
        if (result.error) {
          swalError(result.error);
          Swal.close();
          return;
        }

        Swal.close();
        if (result.data.count == 1) {
          swalSuccess(`Added ${result.data.count} user`);
        } else {
          swalSuccess(`Added ${result.data.count} users`);
        }
        setCount(count + 1);
        setList(``);
      });
  };

  return (
    <Rodal
      visible={true}
      onClose={() => props.onClose()}
      closeOnEsc={true}
      closeMaskOnClick={true}
      customStyles={styles}
    >
      <div className="row">
        <div className="col">Invite Others</div>
        <div className="col">Share Link</div>
      </div>
      <div className="row mt-30">
        <div className="col-sm-6 text-left">
          <textarea
            className="form-control"
            rows={1}
            placeholder="hello@filmboard.io, support@filmboard.io"
            value={list}
            onChange={(e) => setList(e.target.value)}
          ></textarea>{" "}
          <br />
          <select className="form-control" id="ddlAccessType">
            <option value={1}>Can view</option>
            <option value={2}>Can edit</option>
          </select>{" "}
          <br />
          <button className="btn btn-pink w-100" onClick={handleDone}>
            Add User
          </button>
          {/* <br /><small>* Add multiple emails separated using comma ( , )</small> <br />*/}
          {/*<small>* Invalid emails will be skipped</small> <br />*/}
          {/*<small>* Emails already in collaboration will be skipped</small> <br />*/}
        </div>
        <div className="col-sm-6 text-right">
          <button className="btn btn-pink w-100" onClick={handleShare}>
            Copy URL
          </button>
        </div>
      </div>

      <div className="row mt-30">
        <div className="col-sm-12 text-left">
          <Collaborators
            reload={count}
            showDeleteButton={
              session.get("user") &&
              session.get("user")._id === props.project.userId._id
            }
          />
        </div>
      </div>
    </Rodal>
  );
}
