import React, {useState, useEffect} from 'react';
import collaborationService from '../services/collaboration';
import {swalError, swalSuccess} from "../utils/swal";
import Collaborator from './Collaborator';

export default function Collaborators(props) {

    const [collaborators, setCollaborators] = useState([]);

    useEffect(() => {
        reload();
    }, [props.reload]);

    const reload = async () => {
        const projectId = window.location.href.split('/').pop();
        if(projectId && projectId.length === 24) {
            await collaborationService.getByProject(projectId)
                .then(result => {
                    if (result.error) {
                        swalError(result.error);
                        return;
                    }

                    setCollaborators(result.data);
                });
        }
    }

    const renderCollaborators = () =>
        collaborators && collaborators.length > 0 ?
            collaborators.map(c =>
                <Collaborator
                    key={c._id}
                    collaborator={c}
                    deleteCollaborator={deleteCollaborator}
                    showDeleteButton={props.showDeleteButton}
                    reload={reload}/>)
                : <div className="not-found" style={{marginLeft: '0px'}}>No collaborators found.</div>

    const deleteCollaborator = async id => {
        await collaborationService.delete(id)
            .then(result => {
                if (result.error) {
                    swalError(result.error);
                    return;
                }

                swalSuccess('Removed user');
                reload();
            });
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 text-left">
                    <span className="h5" style={{marginLeft: '0px'}}>Users</span>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-12 text-left">
                    {renderCollaborators()}
                </div>
            </div>
        </div>
    );
}