import React, {useState, useEffect} from 'react';
import imgSrc from "../images/default-video.png";
import axios from "axios";

export default function ThumbnailVideo(props) {
    const [imgUrl, setImgUrl] = useState(imgSrc);

    useEffect(() => {
        if (props.item.fileUrl.includes('vimeo')) {
            const id = props.item.fileUrl.split('/').pop();
            (async () => {
                await axios.get(`https://vimeo.com/api/v2/video/${id}.json`)
                    .then(resp => {
                        if (resp.status === 200) {
                            const data = resp.data;
                            setImgUrl(data[0].thumbnail_large);
                        }
                    });
            })();
        }
    }, []);

    const handlePlay = e => {
        e.preventDefault();
        if (props.setPlaying) {
            props.setPlaying(props.item);
        }
    }

    return (
        <img
            key={props.item._id}
            src={props.item.thumbnailUrl}
            className={`video-thumbnail ${props.item._id}`}
            title={props.setPlaying && "Click to see this image."}
            onClick={handlePlay}
        />
    );
}