import { Menu, MenuItem } from "@material-ui/core";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { MoodboardTilesContext } from "../N";

import "./ItemBar.scss";
import MoveToModal from "../MoveToModal/MoveToModal";
import axios from "axios";

const ItemBar = forwardRef(
  ({ children, deleteItem, viewItem, currentFile }, ref) => {
    const modalRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isUrlCORS, setIsUrlCORS] = useState(false);

    const { onCopy } = useContext(MoodboardTilesContext);

    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    useImperativeHandle(ref, () => ({
      closeMenu: () => {
        handleClose();
      },
    }));

    useEffect(() => {
      // Check if image url is not CORS, then allow to edit
      const validateFileUrl = async () => {
        try {
          await axios.get(currentFile.fileUrl);
        } catch (e) {
          setIsUrlCORS(true);
        }
      };
      if (currentFile.type === "image") {
        validateFileUrl();
      }
    }, []);

    return (
      <div className="tile-options">
        <MoreHorizIcon onClick={handleClick} />

        <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
          {!isUrlCORS && children}
          {/* <MenuItem
            onClick={(e) => {
              setAnchorEl(null);
              modalRef.current.openModal();
            }}
          >
            Move to
          </MenuItem> */}
          <MenuItem
            onClick={(e) => {
              setAnchorEl(null);
              viewItem();
            }}
          >
            View Comments
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              setAnchorEl(null);
              deleteItem();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
        <MoveToModal
          ref={modalRef}
          onCopy={onCopy}
          listAssets={[currentFile]}
        />
      </div>
    );
  }
);

export default ItemBar;
