import React from 'react';
import { bytesToSize } from "../utils/utils";
import { swalDeleteForm } from "../utils/swal";
import '../css/uploaded-file.css';

export default function UploadedFile(props) {
    const handleDeleteFile = id => {
        swalDeleteForm(() => props.deleteFile(id));
    }


    return (
        <div className="uploaded-file row">
            <div class="column left">
                <a target="_blank" href={props.file.fileUrl}>{props.file.fileName}</a>
            </div>

            <div class="column middle">
                <span>{bytesToSize(props.file.fileSize)}</span>
                {
                    props.showDeleteButton === true
                }
            </div>
            <div class="column right">
                {
                    props.showDeleteButton === true &&
                    <a className="btn-delete-file" onClick={() => handleDeleteFile(props.file._id)}>Delete</a>
                }
            </div>
        </div>
    );
}