import React, { Component } from "react";
import memoize from "memoize-one";
import { ListGroup, ListGroupItem } from "reactstrap";
import collaborationService from "../services/collaboration";
import { swalError } from "../utils/swal";

const MAX_FILTERED_LIST_LENGTH = 5;

export default class CollaboratorsDropdown extends Component {
  constructor(props) {
    super(props);
    this.show = () => this.setState({ isHidden: false });
    this.hide = () => this.setState({ isHidden: true });
    this.handleClick = this.handleClick.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.filtered = [];
    this.state = {
      isHidden: false,
      option: 0,
      collaborators: [],
    };

    const projectId = window.location.href.split("/").pop();
    if (projectId && projectId.length === 24) {
      collaborationService
        .getCollaboratorsNamesByProject(projectId)
        .then((result) => {
          if (result.error) {
            swalError(result.error);
            return;
          }
          this.setState({ collaborators: result.data });
        });
    }
  }

  handleClick(e) {
    this.props.onChange(`${e.target.value} `);
  }

  handleKeyDown(e) {
    if (this.state.isHidden || this.filtered.length < 1) {
      return null;
    }
    const { key } = e;
    switch (key) {
      case "Escape":
        e.preventDefault();
        this.hide();
        break;
      case "Enter":
      case "Tab":
        e.preventDefault();
        const name = this.filtered[this.state.option];
        this.props.onChange(`~${name}~ `);
        break;
      case "ArrowUp":
        e.preventDefault();
        this.setState(({ option }) => {
          return {
            option: this.clamp(option - 1, 0, this.filtered.length - 1),
          };
        });
        break;
      case "ArrowDown":
        e.preventDefault();
        this.setState(({ option }) => {
          return {
            option: this.clamp(option + 1, 0, this.filtered.length - 1),
          };
        });
        break;
      default:
        break;
    }
  }

  componentDidMount() {
    window.addEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate(prevProps) {
    if (this.props.filterText !== prevProps.filterText && this.state.isHidden) {
      this.show();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  clamp(value, min, max) {
    return value < min ? min : value > max ? max : value;
  }

  filter(filterText = "") {
    let collaborators = this.state.collaborators;
    let filtered = [];
    for (let name of collaborators) {
      if (filtered.length >= MAX_FILTERED_LIST_LENGTH) break;
      if (name.startsWith(filterText)) filtered.push(name);
    }
    return filtered;
  }

  render() {
    this.filtered = this.filter(this.props.filterText);
    return (
      <ListGroup role="listbox" hidden={this.state.isHidden}>
        {this.filtered.map((name, i) => {
          const isActive = i === this.state.option;
          return (
            <ListGroupItem
              role="option"
              active={isActive}
              aria-selected={isActive}
              key={name}
              value={name}
              tag="button"
              action
              onClick={this.handleClick}
            >
              {name}
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  }
}
