import React, { useState, useEffect } from "react";
import userService from "../services/user";
import session from "../services/session";
import { swalLoading } from "../utils/swal";
import Swal from "sweetalert2";

export default function ForgotPassword(props) {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    session.clear();
  }, []);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!email || email.length === 0) {
      setErrorMessage(`Email is required.`);
      return;
    } else {
      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false
      ) {
        setErrorMessage(`Please enter a valid email address.`);
        return;
      }
    }

    swalLoading();
    await userService.forgotPassword(email).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }

      if (result.data) {
        setErrorMessage("");
        setSuccessMessage("Email sent successfully! Please check your email.");
        Swal.close();
      }
    });
  };

  const clearMessages = (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");
  };

  return (
    <div
      className="container-fluid text-center div-login"
      style={{ marginTop: "50px", width: "600px", color: "white" }}
    >
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 infoBox">
          <h2 className="m-4">Forgot Password</h2>
          <p className="m-4">
            Provide your email address to reset your password.
          </p>
          <form onSubmit={handleForgotPassword}>
            <div className="form-group">
              <input
                type="text"
                className="form-control width80"
                placeholder="Email"
                required="required"
                onBlur={clearMessages}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {errorMessage && (
              <div className="alert alert-danger">{errorMessage}</div>
            )}

            {successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}

            <button
              type="submit"
              className="btn btn-pink-1 pad40"
              onClick={handleForgotPassword}
            >
              Send Email
            </button>

            <a href="/login" className="btn btn-link">
              Login here
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}
