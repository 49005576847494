import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import "./PresentationContent.css";
import ReactJWPlayer from "react-jw-player";
import ContentComments from "../ContentComments";
import { IconButton } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import ReactPlayer from "react-player";
import YoutubePlayer from "../YoutubePlayer/YoutubePlayer";
import IspotPlayer from "../IspotPlayer/IspotPlayer";
import { InstagramEmbed } from "react-social-media-embed";

const PresentationContent = forwardRef(({ selectedIndex = 1, data }, ref) => {
  let t = selectedIndex - 1;
  const [index, setIndex] = useState(t);
  const [showComment, setShowComment] = useState(false);
  const [disablePrevious, setDisablePrevious] = useState(t > 1 ? false : true);
  const [disableNext, setDisableNext] = useState(
    t === data.length ? true : false
  );
  const [selectedMoodboard, setSelectedMoodboard] = useState(data[t]?._id);

  useEffect(() => {
    renderDisplaying();
    manageButtons();
    let t = data[index];
    if (t) setSelectedMoodboard(t._id);
  }, [index]);

  const onToggleComment = () => {
    setShowComment(!showComment);
  };

  useImperativeHandle(ref, () => ({
    onToggleComment: () => {
      onToggleComment();
    },
  }));

  useEffect(() => {
    function handlekeydownEvent(event) {
      const { key, keyCode } = event;
      if (keyCode == 37 && index > 0) {
        goPrevious();
      }
      if (keyCode == 39 && index < data.length - 1) {
        goNext();
      }
    }

    document.addEventListener("keyup", handlekeydownEvent);
    return () => {
      document.removeEventListener("keyup", handlekeydownEvent);
    };
  }, [index]);

  const renderDisplaying = () => {
    const item = data[index];
    const type = item?.type;
    const playingUrl = item?.fileUrl;
    if (type === "video") {
      if (playingUrl.includes("ispot.tv")) {
        return (
          <IspotPlayer
            url={playingUrl}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              objectFit: "cover",
            }}
            className="ispot-player-story-view full-screen"
          />
        );
      } else if (playingUrl.includes("youtube.com/shorts")) {
        return (
          <YoutubePlayer
            url={playingUrl}
            style={{ height: "100vh", width: "100vw" }}
          />
        );
      } else if (playingUrl.includes("youtube")) {
        let id = new URL(playingUrl).searchParams.get("v");
        let src = `https://www.youtube.com/embed/${id}`;
        return (
          <iframe
            id="player"
            className="full-screen"
            src={src}
            key={playingUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      } else if (item.source === "instagram") {
        return <InstagramEmbed key={playingUrl} url={playingUrl} width="50%" />;
      } else if (playingUrl.includes("vimeo")) {
        let id = playingUrl.split("/").pop();
        let src = `https://player.vimeo.com/video/${id}`;
        return (
          <iframe
            id="player"
            className="player"
            src={src}
            key={playingUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        );
      } else {
        return (
          <ReactPlayer
            className="full-screen"
            id={playingUrl.toString()}
            url={playingUrl}
            controls={true}
          />
        );
      }
    } else if (type === "docs") {
      return (
        <iframe
          id="player"
          className="full-screen"
          style={{ marginLeft: "25%" }}
          src={playingUrl}
          key={playingUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    } else if (type === "audio") {
      return (
        <div style={{ margin: "20px", marginTop: "100px" }} key={playingUrl}>
          <audio
            controls
            className="audio-player w-100"
            src={playingUrl}
          ></audio>
        </div>
      );
    } else if (type === "image") {
      return (
        <img
          className="image-player"
          style={{
            height: "100vh",
            width: "100vw",
            objectFit: "cover",
            maxHeight: "initial",
          }}
          key={playingUrl}
          src={playingUrl}
        />
      );
    } else if (type === "website") {
      return (
        <>
          <img
            className="image-player mb-30"
            key={playingUrl}
            src={playingUrl}
          />
          <br />
          Title: {item.title}
          <br />
          Website:{" "}
          <a href={item.websiteUrl} target="_blank" className="btn btn-link">
            {item.websiteUrl}
          </a>
          <br />
          Description: {item.description}
        </>
      );
    } else if (type === "pexels-video") {
      // return <video className="player" key={playingUrl} controls>
      //     <source src={playingUrl} type="video/mp4"/>
      // </video>
      return (
        <ReactJWPlayer
          className="player"
          style={{
            width: "100vw",
            height: "100vh",
          }}
          playerId={playingUrl.toString()}
          playerScript="https://cdn.jwplayer.com/libraries/s7lNp89j.js"
          file={playingUrl}
          image={item.thumbnailUrl}
        />
      );
    }
  };

  const goPrevious = (e) => {
    //e.preventDefault();
    if (index > 0) {
      setIndex(index - 1);
    }

    manageButtons();
  };

  const goNext = (e) => {
    //e.preventDefault();
    if (index < data.length) {
      setIndex(index + 1);
    }

    manageButtons();
  };

  const manageButtons = () => {
    setDisablePrevious(true);
    setDisableNext(true);
    if (index > 0) setDisablePrevious(false);
    if (index < data.length - 1) setDisableNext(false);
  };

  if (data?.length === 0) {
    return null;
  }

  return (
    <div className="container-fluid text-center">
      <div className="container-fluid content-details">
        <div className="row" style={{ position: "relative" }}>
          <IconButton
            className={`nav-icon`}
            style={{
              position: "absolute",
              top: "45%",
              left: "1%",
              marginTop: "0",
              zIndex: "9",
            }}
            disabled={disablePrevious}
            onClick={goPrevious}
          >
            <ChevronLeft />
          </IconButton>
          <div
            className="col-md-7 col-sm-12 text-left presentation-mode"
            style={{ flex: 1, maxWidth: "100%" }}
          >
            <div className="displaying-body" style={{ marginBlock: "0" }}>
              {data && renderDisplaying()}
            </div>
          </div>
          {showComment && (
            <div
              className="col-md-3 col-sm-12 text-left"
              style={{ paddingLeft: "20px", marginTop: "50px" }}
            >
              <ContentComments id={selectedMoodboard} />
            </div>
          )}
          <IconButton
            className={`nav-icon`}
            style={{
              position: "absolute",
              top: "45%",
              right: "1%",
              marginTop: "0",
              zIndex: "1",
            }}
            disabled={disableNext}
            onClick={goNext}
          >
            <ChevronRight />
          </IconButton>
        </div>
      </div>
    </div>
  );
});

export default PresentationContent;
