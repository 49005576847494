import React, { useRef } from "react";
import "../css/moodboard-masonry.css";
import { Image } from "antd";
import ImageEditorModal from "./ImageEditor/ImageEditor";
import ItemBar from "./ItemBar/ItemBar";

function TileImage({ currentFile, thumbnail, view, onSave, ...props }) {
  const itemBarRef = useRef();

  const onFinishEdit = async (uploadedUrl, onCloseEdit) => {
    try {
      await onSave({
        ...currentFile,
        thumbnailUrl: uploadedUrl,
        fileUrl: uploadedUrl,
      });
      onCloseEdit();
    } catch (e) {
      console.log("Update error", e);
    }
  };

  return (
    <div className={`tile ${currentFile._id}`}>
      <ItemBar
        ref={itemBarRef}
        currentFile={currentFile}
        viewItem={() => view(currentFile._id)}
        deleteItem={() => props.delete(currentFile._id)}
      >
        <ImageEditorModal
          imageSrc={currentFile.fileUrl}
          currentFile={currentFile}
          onClickEdit={() => itemBarRef.current.closeMenu()}
          onFinishEdit={onFinishEdit}
        />
      </ItemBar>
      {
        <Image
          className="img"
          src={currentFile.fileUrl}
          preview={false}
          decode={false}
          lazy={false}
          cache={false}
          alt="Filmboard Image"
          onClick={(e) => view(currentFile._id)}
        />
      }
    </div>
  );
}

export default TileImage;
