const config = require("../config.json");

const getUrlVars = (url) => {
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
};

export const getUrlParam = (url, parameter, defaultvalue) => {
  let urlparameter = defaultvalue;
  if (url.indexOf(parameter) > -1) {
    urlparameter = getUrlVars(url)[parameter];
  }
  return urlparameter;
};

export const getAllPackages = () => config.packages;
export const getPackage = (id) =>
  config.packages.find((pkg) => parseInt(pkg.id) === parseInt(id));

export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

export const isImageUrl = async (url) => {
  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Check the content type of the response
      const contentType = response.headers.get("content-type");
      return contentType && contentType.startsWith("image");
    })
    .catch((error) => {
      console.error("Error checking image URL:", error);
      return false; // URL is not an image or could not be fetched
    });
};

export const validateEmail = (email) =>
  /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/.test(email);

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 500);
  };
};

export const stripHtmlTags = (str) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, "text/html");
  return doc.body.textContent || "";
};
