import { useEffect } from "react";
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";
import { SendOutlined } from "@ant-design/icons";

import "./TextAreaMention.scss";

const TextAreaMention = ({ onSubmit, value, setComment, memberList }) => {
  useEffect(() => {
    if (memberList.length > 0) {
      const whitelist_1 = memberList.map((member) => ({
        title: member,
        value: member,
        text: member,
      }));

      var input = document.querySelector("[name=mix]"),
        tagify = new Tagify(input, {
          mode: "mix", // <--  Enable mixed-content
          pattern: /@/, // <--  Text starting with @ or # (if single, String can be used here)
          tagTextProp: "text", // <-- the default property (from whitelist item) for the text to be rendered in a tag element.
          // Array for initial interpolation, which allows only these tags to be used
          whitelist: whitelist_1,
          onChangeAfterBlur: false,
          editTags: false,
          enforceWhitelist: true,
          validate(data) {
            return !/[^a-zA-Z0-9 ]/.test(data.value);
          },
          dropdown: {
            enabled: 0,
            fuzzySearch: false,
            classname: "text-area-mention-dropdown",
            position: "text", // <-- render the suggestions list next to the typed text ("caret")
            mapValueTo: "text", // <-- similar to above "tagTextProp" setting, but for the dropdown items
            highlightFirst: true, // automatically highlights first sugegstion item in the dropdown
          },
        });

      tagify.on("input", function (e) {
        var prefix = e.detail.prefix;

        if (prefix) {
          if (prefix == "@") tagify.whitelist = whitelist_1;
        }
      });

      tagify.on("change", function (e) {
        setComment(e.detail.tagify.state.lastOriginalValueReported);
      });
    }
  }, [memberList]);

  return (
    <div className="textAreaMention">
      <textarea
        id="txt-comment"
        name="mix"
        placeholder="Share your thoughts"
        type="text"
        value={value}
        className="form-control form-control-2 "
      />
      <SendOutlined onClick={onSubmit} />
    </div>
  );
};

export default TextAreaMention;
