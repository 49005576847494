import React, {useState, useEffect} from 'react';
import Rodal from "rodal";
import 'rodal/lib/rodal.css';
import '../css/moodboard-masonry.css';
import '../css/pexels.css';

export default function VideoPlayer(props) {

    const styles = {
        background: '#000000',
        width: '80%',
        height: '80%',
        overflow: 'hidden',
        cursor: 'default',
    };

    return (
        <div>
        <Rodal visible={true}
               onClose={() => props.onClose()}
               closeOnEsc={true}
               showMask={true}
               animation={'fade'}
               closeMaskOnClick={true}
               customStyles={styles}>
             <iframe id="player" 
                    style={{width:'100%', height:'100%'}}
                    src="https://player.vimeo.com/video/521271907"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
        </Rodal></div>
    );
}