import React, { useRef } from "react";
import "../../css/moodboard-masonry.css";
import { MenuItem } from "@material-ui/core";
import YoutubePlayer from "./YoutubePlayer";
import ItemBar from "../ItemBar/ItemBar";

function TileYoutubeShort({ currentFile, view, ...props }) {
  const itemBarRef = useRef();

  return (
    <div className={`tile ${props.id}`}>
      <ItemBar
        ref={itemBarRef}
        currentFile={currentFile}
        viewItem={() => view(currentFile._id)}
        deleteItem={() => props.delete(currentFile._id)}
      >
        <MenuItem
          onClick={(e) => {
            itemBarRef.current.closeMenu();
            props.edit(currentFile);
          }}
        >
          Edit
        </MenuItem>
      </ItemBar>
      <YoutubePlayer url={props.src} style={{ height: "100%" }} />
    </div>
  );
}

export default TileYoutubeShort;
