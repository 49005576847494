import React, { useState, useEffect } from "react";
import session from "../services/session";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  swalDeleteForm,
  swalError,
  swalSuccess,
  swalInfo,
} from "../utils/swal";
import projectService from "../services/project";
import "../css/project-box.css";
import getThumb from "video-thumbnail-url";

export default function ProjectBox(props) {
  const navigate = useNavigate();
  const [isCollaborator, setIsCollaborator] = useState(false);
  const [accessType, setAccessType] = useState(1);
  const [defaultImage, setDefaultImage] = useState(null);

  useEffect(() => {
    if (session.get("user") && session.get("user")._id) {
      props.project.collaborations.map((collaboration) => {
        if (collaboration.email === session.get("user").email) {
          setIsCollaborator(true);
          setAccessType(collaboration.type);
        }
      });
    }
    getDefaultImage();
  }, []);

  const getDefaultImage = () => {
    if (props.project.moodboards.length === 0) return;
    // const defaultImageURL = props.project.moodboards[0].fileUrl;
    // const arrayOfVideos = ["youtube", "vimeo"];
    // const arrayOfImages = ["images.pexels", ".png", ".jpg", ".jpeg"];

    // const foundVideo = arrayOfVideos.find((v) => defaultImageURL.includes(v));
    // const foundImage = arrayOfImages.find((v) => defaultImageURL.includes(v));
    // if (foundVideo) {
    //   getThumb(defaultImageURL).then((thumb_url) => {
    //     // thumb_url is url or null
    //     setDefaultImage(thumb_url); // http://img.youtube.com/vi/dQw4w9WgXcQ/hqdefault.jpg
    //     return;
    //   });
    // } else if (foundImage) {
    //   setDefaultImage(defaultImageURL);
    // }
    setDefaultImage(props.project.defaultThumbnail);
  };

  const handleEdit = () => {
    if (props.project.userId !== session.get("user")._id) {
      if (accessType === 1) {
        swalInfo(`You don't have permission to edit the project.`);
        return;
      }
    }

    navigate(`/project/${props.project._id}`);
  };

  // const handleOpen = () => navigate(`/projects/${props.project._id}`);
  // const handleOpen = () => navigate(`/view/${props.project._id}`);
  const handleOpen = () => navigate(`/n/${props.project._id}`);

  const handleDelete = (e) => {
    e.preventDefault();

    if (props.project.userId !== session.get("user")._id) {
      swalInfo(`Only project owner can delete the project.`);
      return;
    }

    swalDeleteForm(async () => {
      await projectService.delete(props.project._id).then((result) => {
        if (result.error) {
          swalError(result.error);
          return;
        }

        swalSuccess("Project deleted successfully!");
        props.reload();
      });
    });
  };

  const parseTitle = (title) => {
    if (/^\s*$/.test(title)) {
      return "Untitled";
    }
    return title;
  };

  const defaultImageStyle = {
    backgroundImage:
      "linear-gradient(0deg, rgba(23, 33, 89, 0.3), rgba(255, 255, 255, 0.2)), url(" +
      defaultImage +
      ")",
    width: "340px",
    height: "150px",
    backgroundSize: "cover",
    borderTopLeftRadius: "5px", //Same as .projectBox
    borderTopRightRadius: "5px",
  };
  const emptyDefaultImageStyle = {
    backgroundColor: "#19191a",
    width: "340px",
    height: "150px",
    borderTopLeftRadius: "5px", //Same as .projectBox
    borderTopRightRadius: "5px",
  };

  return (
    <>
      <div className="project-box">
        {defaultImage != null ? (
          <div onClick={handleOpen} style={defaultImageStyle}></div>
        ) : (
          <div onClick={handleOpen} style={emptyDefaultImageStyle}></div>
        )}
        <div className="project-box-padding">
          <div
            className="row"
            onClick={handleOpen}
            title="Click to open project"
          >
            <div className="col-12 col-sm-12 text-left">
              <p className="title"> {parseTitle(props.project.title)}</p>
            </div>
          </div>
          <div
            className="row"
            onClick={handleOpen}
            title="Click to open project"
          >
            <div className="col-12 col-sm-12 text-left">
              <p className="counts">
                <span className="fw-300 ls-1">
                  <strong>{props.project.moodboards.length || 0}</strong> Assets
                </span>{" "}
                <br />
                {/*<span><strong>{props.project.storyboards.length || 0}</strong> Storyboard Cells</span> <br/>*/}
                {/*<span><strong>{props.project.vendoruploads.length || 0}</strong> Vendor Uploads</span> <br/>*/}
                <span className="fw-300 ls-1">
                  <strong>{props.project.collaborations.length || 0}</strong>{" "}
                  Collaborators
                </span>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-7 col-sm-7 text-left date">
              {moment(props.project.date).format("ddd DD MMM YYYY HH:mm A")}
            </div>
            <div className="col-5 col-sm-5 text-right">
              {session.get("user") &&
                session.get("user")._id &&
                (session.get("user")._id === props.project.userId ||
                  isCollaborator) && (
                  <span>
                    {/*props.project.collaborations.length > 0 && <i className="fa fa-users m-2" title="This project has collaborators"></i>}
                                    {/*<i className="fa fa-pencil-alt m-2" title="Edit this project" onClick={handleEdit}></i>*/}
                    <i
                      className="fas fa-trash m-2 trash-icon"
                      title="Delete this project"
                      onClick={handleDelete}
                    ></i>
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
