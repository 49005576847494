import React, {useState, useEffect} from 'react';
import {
    swalDeleteForm,
    swalError,
} from "../utils/swal";
import moodboardService from "../services/moodboard";
import $ from 'jquery';
import ThumbnailVimeo from './ThumbnailVimeo';
import ThumbnailYouTube from './ThumbnailYouTube';
import ThumbnailAudio from './ThumbnailAudio';

export default function MoodboardAudio(props) {

    const [redirectTo, setRedirectTo] = useState(null);
    const [url, setUrl] = useState(props.item.url);
    const [title, setTitle] = useState(props.item.title);
    const [description, setDescription] = useState(props.item.description);

    const removeItem = e => {
        e.preventDefault();
        $(e.target).closest('.item').remove();
        if (props.item._id) {
            swalDeleteForm(async () => {
                await moodboardService.delete(props.item._id)
                    .then(result => {
                        if (result.error) {
                            swalError(result.error);
                            return;
                        }
                    });
            });
        }
    }

    return (
        <div className="container item audio">
            <div className="row">
                <div className="col-6 col-sm-6 text-left">
                    <i className="fa fa-bars btn-fa handle" title="Move to change the order"></i>
                </div>
                <div className="col-6 col-sm-6 text-right">
                    <i className="fa fa-clone btn-fa" title="Duplicate this item to create new"
                        onClick={props.duplicateItem}></i>
                    <i className="fa fa-trash-alt btn-fa" onClick={removeItem} title="Delete this item"></i>
                </div>
            </div>
            <div className="row">
                <div className="col-2" style={{marginTop:'25px'}}>
                    <ThumbnailAudio key={props.item._id} item={props.item} setPlaying={null} />
                </div>
                <div className="col-10 text-center">
                    <div className="form-group">
                        <input type="hidden" className="item-id"
                               value={props.item._id || ``}/>
                        <input type="hidden" className="item-type"
                               value={props.item.type}/>
                    </div>
                    <div className="form-group">
                        <input type="text" className="form-control item-title"
                               placeholder="Enter audio title (required)"
                               value={title} onChange={e => setTitle(e.target.value)}/>
                    </div>
                    <div className="form-group">
                            <textarea type="text" className="form-control item-description"
                                      placeholder="Enter audio description (optional)" rows={1}
                                      value={description} onChange={e => setDescription(e.target.value)}>
                            </textarea>
                    </div>
                    <div className="form-group text-left">
                        <input type="file" className="form-control item-file" id="item-file"
                               accept="audio/wav, audio/mp3"/>
                        {props.item._id && <small>New uploaded file will replace the existing file.</small>}
                        {
                            props.item._id &&
                            <div>
                                Existing file: <a target="_blank"
                                                  href={props.item.fileUrl}>{props.item.fileName}</a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}