import React, {
    useEffect,
    useState,
    useRef,
    useMemo,
    useCallback
} from 'react';
import ReactDOM from 'react-dom';

import '../css/script.css';
import {
    createMaterialEditor,
    MaterialEditable,
    MaterialSlate,
    Toolbar,
    WordCounter,
    CharCounter,
    withCounter,
    ToolbarButton,
    ButtonSeparator,
    BoldButton,
    ItalicButton,
    CodeButton,
    UnderlinedButton,
    StrikethroughButton,
    BulletedListButton,
    NumberedListButton,
    defaultRenderElement,
    defaultRenderLeaf
} from "@unicef/material-slate";
import { makeStyles } from '@material-ui/core/styles';
import {Divider} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import TextFormatIcon from '@material-ui/icons/TextFormat';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import FormatBoldIcon from '@material-ui/icons/FormatBold';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TheatersIcon from '@material-ui/icons/Theaters';

const useStyles = makeStyles( theme => ({
    highlighted: {
        display: 'inline-block',
        backgroundColor: 'yellow',
        color: 'red'
    },
    bigger: {
        fontSize: '125%'
    },
    blockquote: {
        display: 'block',
        marginLeft: '5%',
        fontStyle: 'italic'
    },
    act: {
        textAlign: 'center',
        textDecoration: 'underline',
        textTransform: 'uppercase'
    },
    shot: {
        textTransform: 'uppercase'
    },
    text: {

    },
    transition: {
        textAlign: 'right',
        textTransform: 'uppercase'
    },
    sceneHeading: {
        textTransform: 'uppercase',
        backgroundColor: 'grey'
    },
    characterSelection: {
        textTransform: 'uppercase',
        textAlign: 'center',
    },
    dialogue: {
        marginLeft: '5%',
    },
    action: {
        textAlign: 'left',
    },
    customSlate: {
        backgroundColor: theme.palette.grey[800],
        color: 'white',
        borderWidth: 1,
        borderColor: 'transparent',
        '&:hover': {
            borderWidth: 1,
        }
    },
    focused: {
        borderWidth: 1,
        borderColor: theme.palette.grey[400],
        '&:hover': {
            borderColor: theme.palette.grey[400],
        }
    },
    customEditable: {
        fontFamily: 'Courier'
    }
}));

function EditorMaterialSlate(props) {

    const initialValue = [{
            type: 'paragraph',
            children: [
                {text: ''}
            ]
        }];
    const [value, setValue] = useState(initialValue);
    const editor = useMemo(() => withCounter(createMaterialEditor()), []);

    const onTextChanged = newContent => {
        setValue(newContent);
        props.setScript(JSON.stringify(newContent));
    }

    const classes = useStyles();

    const handleRenderElement = useCallback(({ element, children, attributes, ...rest }) => {
            switch (element.type) {
                case 'bigger':
                    return (<p className={classes.bigger} {...attributes}>{children}</p>);
                case 'blockquote':
                    return (<p className={classes.blockquote} {...attributes}>{children}</p>);
                case 'act':
                    return (<p className={classes.act} {...attributes}>{children}</p>);
                case 'shot':
                    return (<p className={classes.shot} {...attributes}>{children}</p>);
                case 'text':
                    return (<p className={classes.text} {...attributes}>{children}</p>);
                case 'transition':
                    return (<p className={classes.transition} {...attributes}>{children}</p>);
                case 'sceneHeading':
                    return (<p className={classes.sceneHeading} {...attributes}>{children}</p>);
                case 'characterSelection':
                    return (<p className={classes.characterSelection} {...attributes}>{children}</p>);
                case 'dialogue':
                    return (<p className={classes.dialogue} {...attributes}>{children}</p>);
                case 'action':
                    return (<p className={classes.action} {...attributes}>{children}</p>);
                default:
                    return defaultRenderElement({
                        element,
                        children,
                        attributes,
                        ...rest,
                    })
            }
        }, []);

    const handleRenderLeaf = ({leaf, attributes, children, text }) => {
        if (leaf.highlighted) {
            children = <span className={classes.highlighted}>{children}</span>
        }

        return defaultRenderLeaf({leaf, children, attributes, text})
    }

    return (
        <div className="script-container text-left">
            <MaterialSlate
                editor={editor}
                value={value}
                onChange={onTextChanged}>
                <Toolbar style={{borderRadius: '0px'}}>
                    <BoldButton />
                    <ItalicButton />
                    <UnderlinedButton />
                    <StrikethroughButton />
                    <CodeButton />
                    <ButtonSeparator />
                    <BulletedListButton />
                    <NumberedListButton />
                    <ToolbarButton icon={<FormatQuoteIcon />} type="block" format="blockquote" />
                    <ToolbarButton icon={<FormatColorFillIcon />} type="mark" format="highlighted" />
                    <ToolbarButton icon={<FormatSizeIcon />} type="block" format="bigger" />
                    <ButtonSeparator />
                    {/*<div className="dropdown" style={{display: 'inline'}}>*/}
                    {/*    <ToolbarButton className="dropdown-toggle" id="dropdownMenuButtonX"*/}
                    {/*                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" icon={<span><WhatshotIcon /> <span className="dropdown-btn-text">Shot</span></span>} type="block" format="shot" />*/}
                    {/*    <div className="dropdown-menu" aria-labelledby="dropdownMenuButtonX">*/}
                    {/*        <ToolbarButton icon={<span><LocalActivityIcon /> <span className="dropdown-btn-text">Act</span></span>} type="block" format="act" />*/}
                    {/*        <ToolbarButton icon={<span><TextFormatIcon /> <span className="dropdown-btn-text">Text</span></span>} type="block" format="text" />*/}
                    {/*        <ToolbarButton icon={<span><TransferWithinAStationIcon /> <span className="dropdown-btn-text">Transition</span></span>} type="block" format="transition" />*/}
                    {/*        <ToolbarButton icon={<span><FormatBoldIcon /> <span className="dropdown-btn-text">Heading</span></span>} type="block" format="sceneHeading" />*/}
                    {/*        <ToolbarButton icon={<span><SelectAllIcon /> <span className="dropdown-btn-text">Character</span></span>} type="block" format="characterSelection" />*/}
                    {/*        <ToolbarButton icon={<span><AssignmentIcon /> <span className="dropdown-btn-text">Dialogue</span></span>} type="block" format="dialogue" />*/}
                    {/*        <ToolbarButton icon={<span><TheatersIcon /> <span className="dropdown-btn-text">Action</span></span>} type="block" format="action" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="dropdown dropright" style={{display: 'inline'}}>
                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtonX"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Select type">
                            Text type
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButtonX">
                            <ToolbarButton className="dropdown-btn" icon={<span><LocalActivityIcon /> <span className="dropdown-btn-text">Act</span></span>} type="block" format="act" />
                            <ToolbarButton className="dropdown-btn" icon={<span><WhatshotIcon /> <span className="dropdown-btn-text">Shot</span></span>} type="block" format="shot" />
                            <ToolbarButton className="dropdown-btn" icon={<span><TextFormatIcon /> <span className="dropdown-btn-text">Text</span></span>} type="block" format="text" />
                            <ToolbarButton className="dropdown-btn" icon={<span><TransferWithinAStationIcon /> <span className="dropdown-btn-text">Transition</span></span>} type="block" format="transition" />
                            <ToolbarButton className="dropdown-btn" icon={<span><FormatBoldIcon /> <span className="dropdown-btn-text">Heading</span></span>} type="block" format="sceneHeading" />
                            <ToolbarButton className="dropdown-btn" icon={<span><SelectAllIcon /> <span className="dropdown-btn-text">Character</span></span>} type="block" format="characterSelection" />
                            <ToolbarButton className="dropdown-btn" icon={<span><AssignmentIcon /> <span className="dropdown-btn-text">Dialogue</span></span>} type="block" format="dialogue" />
                            <ToolbarButton className="dropdown-btn" icon={<span><TheatersIcon /> <span className="dropdown-btn-text">Action</span></span>} type="block" format="action" />
                        </div>
                    </div>
                </Toolbar>
                <MaterialEditable
                    placeholder="Start typing..."
                    style={{
                        backgroundColor: 'white',
                        color: 'black',
                        fontFamily: 'courier',
                        height: '1600px',
                        overflow: 'auto'
                    }}
                    renderElement={handleRenderElement}
                    renderLeaf={handleRenderLeaf}
                ></MaterialEditable>
                <Divider/>
                <Box display="flex" justifyContent="space-between" mr={1} style={{backgroundColor: '#eeeeee', marginRight: '0px'}}>
                    <WordCounter />
                    <CharCounter />
                </Box>
            </MaterialSlate>
        </div>
    );
}

export default EditorMaterialSlate;